<template>
  <v-dialog
    max-width="950"
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <v-card class="dialogCardUpload" :loading="loading">
      <div class="d-flex px-9 pt-5 pb-6">
        <v-card-title class="pa-0 pr-3 dialogTitle">Arquivos</v-card-title>
        <cloud-upload-icon width="40" height="40" />
        <v-spacer></v-spacer>
        <v-btn fab small icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-tabs v-model="choosenTab" align-with-title>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="choosenTab">
          <v-tab-item v-for="item in items" :key="item">
            <div class="tab-box">
              <div v-if="filePage[choosenTab].length == 0">
                <div
                  :class="['dropZone', dragging ? 'dropZone-over' : '']"
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
                >
                  <div class="dropZone-info" @drag="onChange">
                    <cloud-upload-icon-grey width="40" height="40" />
                    <div>
                      <span class="dropZone-title"
                        >Clique ou arraste para adicionar arquivos</span
                      >
                    </div>
                    <!-- <div class="dropZone-upload-limit-info">
          <div>extension support: txt</div>
          <div>maximum file size: 5 MB</div>
        </div> -->
                  </div>
                  <input type="file" multiple="multiple" @change="onChange" />
                </div>
              </div>
              <div v-else class="dropZone-uploaded">
                <div class="dropZone-uploaded-info">
                  <div v-for="(fi, i) in filePage[choosenTab]" :key="i">
                    <div class="file-description">
                      <span>
                        {{ fi.name }}
                      </span>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary removeFile"
                      @click="removeFile(i)"
                    >
                      Remover arquivo
                    </button>
                  </div>
                </div>
              </div>
              <v-col class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  class="rounded-lg"
                  color="#177DFF"
                  depressed
                  dark
                  @click="uploadConfirmed"
                  >Confirmar Upload
                </v-btn>
              </v-col>
              <div class="files-wrap">
                <div
                  v-for="file in computedPageFiles"
                  :key="file.id"
                  @click="downloadClick(file)"
                >
                  <div class="d-flex px-4">
                    <div class="file-text">{{ file.name }}</div>
                    <DownloadIcon class="mt-1" />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="tab-box" v-if="item == 'Medição'">aaaa</div>
            <div class="tab-box" v-if="item == 'Projeto'">eeee</div>
            <div class="tab-box" v-if="item == 'Orçamento'">ssss</div>
            <div class="tab-box" v-if="item == 'Apresentação'">ssss</div>
            <div class="tab-box" v-if="item == 'Conferência de medidas'">ssss</div>
            <div class="tab-box" v-if="item == 'Outros'">cccc</div> -->
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <confirmation-modal
      v-if="modalDelete"
      v-model="modalDelete"
      message="Você tem arquivos sem upload, deseja fechar?"
      @close="modalDelete = false"
      @confirmed="$emit('close')"
    />
  </v-dialog>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

import ConfirmationModal from '../../../components/utils/ConfirmationModal.vue';

import axios from 'axios';

import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('attendences');

export default {
  components: {
    datetime: Datetime,
    ConfirmationModal,
  },
  props: { value: Boolean, activeTab: Number, choosenAttendence: Number },
  data() {
    return {
      modalDelete: false,
      loading: false,
      tab: 2,
      choosenTab: 2,
      items: [
        'Medição',
        'Projeto',
        'Orçamento',
        'Apresentação',
        'Conferência de medidas',
        'Outros',
      ],
      filePage: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      },
      dragging: false,
    };
  },
  methods: {
    ...mapActions(['createAttendenceFile', 'downloadFile']),
    async downloadClick(file) {
      this.loading = true;
      const key =
        'attendences/' +
        file.attendenceId +
        '/page/' +
        file.pageId +
        '/' +
        file.name;
      const url = await this.downloadFile({ key });
      let link = document.createElement('a');
      link.href = url;
      link.download = key;
      link.click();
      this.loading = false;
    },
    async uploadConfirmed() {
      this.loading = true;
      this.filePage[this.choosenTab].forEach(async (file, index) => {
        let dataForm = new FormData();
        dataForm.append('file', file);
        dataForm.append('attendenceId', this.choosenAttendence);
        dataForm.append('pageId', this.choosenTab);
        dataForm.append('name', file.name);
        let res = await this.createAttendenceFile({ file: dataForm });
        dataForm = new FormData();
      });
      this.filePage[this.choosenTab] = [];
      this.loading = false;
    },
    closeDialog() {
      let hasChoosenFile = false
      Object.keys(this.filePage).forEach(el => {
        if (this.filePage[el].length != 0) {
          hasChoosenFile = true
        }
      });
      if (hasChoosenFile) {
        this.modalDelete = true
      }else{
        this.$emit('close')
      }
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files);
    },
    createFile(files) {
      // if (!file.type.match('text.*')) {
      //   alert('please select txt file');
      //   this.dragging = false;
      //   return;
      // }
      // for (var file in files) {
      //   if (file.size > 5000000) {
      //     alert('please check file size no over 5 MB.');
      //     this.dragging = false;
      //     return;
      //   }
      // }
      this.filePage[this.choosenTab] = Array.from(files);
      // this.file.forEach()
      this.dragging = false;
    },
    removeFile(i) {
      this.filePage[this.choosenTab].splice(i, 1);
    },
  },
  computed: {
    ...mapGetters(['getAttendence']),
    computedPageFiles() {
      const attendence = this.getAttendence(this.choosenAttendence);
      return attendence.files.filter((el) => el.pageId == this.choosenTab);
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    extension() {
      return this.filePage[this.choosenTab]
        ? this.filePage[this.choosenTab].name.split('.').pop()
        : '';
    },
  },
  created() {
    switch (this.activeTab) {
      case 2:
        this.choosenTab = 0;
        break;
      case 3:
        this.choosenTab = 1;
        break;
      case 4:
        this.choosenTab = 3;
        break;
      case 7:
        this.choosenTab = 4;
        break;
      default:
        this.choosenTab = 5;
        break;
    }
  },
};
</script>
<style>
.dialogCardUpload .files-wrap {
  display: flex;
  flex-wrap: wrap;
}

.dialogCardUpload .files-wrap > div {
  margin: 7px 0px;
  margin-right: 12px;
  background: #f7f8fc;
  border: 1px solid #e4e8f0;
  border-radius: 8px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dialogCardUpload .files-wrap .file-text {
  font-weight: 600;
  color: black;
  padding-right: 10px;
}

.dialogCardUpload .v-card__text {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 30px !important;
  padding-top: 0px;
}

.dialogCardUpload .tab-box {
  margin-left: 6px;
}

.dialogCardUpload .v-card__text .v-tabs {
  margin-left: -35px;
}

.dialogCardUpload .tab-box .dropZone {
  width: 100%;
  height: 100px;
  position: relative;
  border: 2px dashed #b7c3da;
  margin-top: 20px;
  border-radius: 12px;
}

.dialogCardUpload .tab-box .dropZone:hover {
  border: 2px solid #2e94c4;
}

.dialogCardUpload .tab-box .dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dialogCardUpload .tab-box .dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dialogCardUpload .tab-box .dropZone-title {
  color: #000000;
}

.dialogCardUpload .tab-box .dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dialogCardUpload .tab-box .dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dialogCardUpload .tab-box .dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dialogCardUpload .tab-box .dropZone-uploaded {
  width: 100%;
  height: 100px;
  border: 2px dashed #b7c3da;
  border-radius: 12px;
  margin-top: 20px;
}

.dialogCardUpload .tab-box .dropZone-uploaded-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #a8a8a8;
  height: 100%;
}

.dialogCardUpload .tab-box .dropZone-uploaded-info > div {
  padding-right: 30px;
  padding-left: 30px;
}

.dialogCardUpload .tab-box .file-description {
  display: flex;
  justify-content: center;
}

.dialogCardUpload .tab-box .removeFile {
  width: fit-content;
}

.mdi-checkbox-marked::before {
  font-weight: 900;
  content: url('https://api.iconify.design/mdi-checkbox-marked-circle.svg?color=%23007aff&height=24');
  padding-top: 3px;
}
.mdi-checkbox-blank-outline::before {
  font-weight: 900;
  padding-top: 3px;
  content: url('https://api.iconify.design/mdi-checkbox-blank-circle-outline.svg?color=rgba(0%2C0%2C0%2C0.6)&height=24');
}

.dialogCard .vdatetime {
  border: 1px solid #e8e8e8;
  height: 40px;
  border-radius: 6px;
}

.dialogCard .dialogTitle {
  font-size: 22px;
  font-weight: 600;
  color: #575962;
}

.v-dialog.v-dialog--active {
  border-radius: 22px !important;
}
.dialogCard {
  border-radius: 22px !important;
}

.dialogCard .v-text-field--outlined fieldset {
  color: #e8e8e8 !important;
}
.dialogCard > div > form > .row > .col {
  padding-bottom: 0px;
  padding-left: 0px;
}

.dialogCard > div > form > .row > .col > .row > .col:nth-child(2) {
  padding-left: 0px;
}

.dialogCard .vdatetime-input {
  height: 100%;
  width: 100%;
  padding: 5px;
}

.dialogCard .vdatetime-input:hover {
  cursor: pointer;
}

.dialogCard .saveButton {
  border-radius: 10px;
}
</style>