<template>
  <div>
    <div ref="content" class="pdf-container">
      <DIV id="page_1">
        <DIV id="id1_1">
          <P class="p0 ft0">CONTRATO DE AQUISIÇÃO DE MÓVEIS</P>
          <TABLE cellpadding="0" cellspacing="0" class="t0">
            <tbody>
              <TR>
                <TD colspan="2" class="tr0 td0"
                  ><P class="p1 ft2"
                    ><SPAN class="ft1">CONTRATADA</SPAN>: Nath Móveis / Celso
                    Nath Móveis</P
                  ></TD
                >
                <TD class="tr0 td1"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr0 td2"
                  ><P class="p3 ft1">Nº: <SPAN class="ft2">42</SPAN></P></TD
                >
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p2 ft4">&nbsp;</P></TD>
                <TD colspan="2" class="tr1 td4"
                  ><P class="p2 ft4">&nbsp;</P></TD
                >
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr2 td6"><P class="p2 ft3">&nbsp;</P></TD>
                <TD colspan="3" class="tr2 td7"
                  ><P class="p4 ft2"
                    >Rua Lucindo Lodi, 49 - Monte Verde
                    <NOBR>|95181-300|</NOBR> Farroupilha | RS</P
                  ></TD
                >
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr3 td8"
                  ><P class="p4 ft2"
                    ><NOBR>E-mail:</NOBR> contato@moveisnath.com.br</P
                  ></TD
                >
                <TD colspan="2" class="tr3 td9"
                  ><P class="p2 ft2"
                    >CNPJ: <NOBR>16.714.575.0001-06</NOBR></P
                  ></TD
                >
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD colspan="2" class="tr4 td12"
                  ><P class="p1 ft2"
                    ><SPAN class="ft1">CONTRATANTE</SPAN
                    ><SPAN class="ft5">: </SPAN
                    >{{ info.attendence.client.name }}</P
                  ></TD
                >
                <TD class="tr4 td13"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr4 td14"><P class="p2 ft3">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td15"><P class="p1 ft2">CPF/CNPJ</P></TD>
                <TD class="tr5 td8"
                  ><P class="p5 ft2"
                    ><NOBR>{{ info.attendence.client.CPF }}</NOBR></P
                  ></TD
                >
                <TD class="tr5 td16"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 td14"><P class="p5 ft2">RG/IE:</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td18"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td15"><P class="p1 ft2">Endereço</P></TD>
                <TD class="tr5 td8"
                  ><P
                    class="p5 ft2"
                    budget_content="info.attendence.client.clientAddress.street"
                    >Rua {{ info.attendence.client.clientAddress.street }},
                    {{ info.attendence.client.clientAddress.number }}</P
                  ></TD
                >
                <TD class="tr5 td16"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 td14"
                  ><P class="p5 ft2"
                    >CEP:
                    <NOBR>{{
                      info.attendence.client.clientAddress.CEP
                    }}</NOBR></P
                  ></TD
                >
              </TR>
              <TR>
                <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td18"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td15"><P class="p1 ft2">Bairro/Cidade/UF</P></TD>
                <TD class="tr5 td8"
                  ><P
                    class="p5 ft2"
                    v-if="info.attendence.client.clientAddress.neighbourhood"
                    >{{ info.attendence.client.clientAddress.neighbourhood }} -
                    {{ info.attendence.client.clientAddress.city }}/{{
                      info.attendence.client.clientAddress.state
                    }}</P
                  ></TD
                >
                <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td15"><P class="p1 ft2">Telefone</P></TD>
                <TD class="tr5 td8"
                  ><P class="p5 ft2">{{
                    info.attendence.client.contact
                  }}</P></TD
                >
                <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td15"
                  ><P class="p1 ft2"><NOBR>E-mail</NOBR></P></TD
                >
                <TD class="tr5 td8"
                  ><P class="p5 ft2">{{ info.attendence.client.email }}</P></TD
                >
                <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td15" style="border-bottom: black 1px solid"
                  ><P class="p1 ft2">Observação</P></TD
                >
                <TD class="tr5 td8" style="border-bottom: black 1px solid"
                  ><P class="p2 ft3">&nbsp;</P></TD
                >
                <TD class="tr5 td13" style="border-bottom: black 1px solid"
                  ><P class="p2 ft3">&nbsp;</P></TD
                >
                <TD class="tr5 td14" style="border-bottom: black 1px solid"
                  ><P class="p2 ft3">&nbsp;</P></TD
                >
              </TR>
              <TR>
                <TD class="tr5 td15"><P class="p1 ft2">Data de entrega</P></TD>
                <TD class="tr5 td8"
                  ><P class="p5 ft2">{{ formatDeliverDate }}</P></TD
                >
                <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td15"><P class="p1 ft2">VENDEDOR</P></TD>
                <TD class="tr5 td8"
                  ><P class="p5 ft2">{{ info.attendence.user.name }}</P></TD
                >
                <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
            </tbody>
          </TABLE>
          <P class="p6 ft6"
            >As partes acima nomeadas têm, entre si, justas e contratadas o
            presente Contrato que se regerá pelas cláusulas e condições
            seguintes:</P
          >
          <P class="p7 ft1">1 – OBJETO</P>
          <P class="p8 ft8"
            ><SPAN class="ft1">1.1</SPAN><SPAN class="ft7">- </SPAN>Pelo
            presente Contrato o CONTRATANTE adquire da CONTRATADA, móveis sob
            medida descritos na Cláusula 2. Não estão inclusos no projeto, itens
            que não estejam descritos na Cláusula 2, servindo apenas como
            ilustração, ou seja, toda parte decorativa do projeto.</P
          >
          <P class="p9 ft10"
            ><SPAN class="ft1">1.2</SPAN><SPAN class="ft9">- </SPAN>O
            CONTRATANTE, todas as imagens dos projetos, que são parte integrante
            deste, estando assim ciente do seu design, cor, tamanho e desta
            forma aprovando os mesmos.</P
          >
          <P class="p10 ft1">2 - ITENS ADQUIRIDOS</P>
          <P class="p11 ft6"
            ><SPAN class="ft1">2.1</SPAN><SPAN class="ft11">- </SPAN>Através
            deste contrato o CONTRATANTE adquire móveis sob medida e produtos de
            terceiros, ou seja, objetos que possuem outras marcas e
            fabricantes.</P
          >
          <P class="p12 ft8"
            ><SPAN class="ft1">2.2</SPAN><SPAN class="ft12">- </SPAN>Os móveis
            sob medida possuem garantia de 60 meses para defeitos de fabricação
            ou de matéria- prima. As ferragens e acessórios possuem garantia de
            3 meses.</P
          >
          <P class="p13 ft8"
            ><SPAN class="ft1">2.3</SPAN><SPAN class="ft13">- </SPAN>O prazo de
            entrega dos produtos no endereço do CONTRATANTE é de até 45
            (quarenta e cinco) dias úteis e de 60 (sessenta) dias úteis para
            produtos em laca após a aprovação do crédito, pagamento, conferência
            das medidas e revisão dos projetos.</P
          >
          <P class="p14 ft2"
            ><SPAN class="ft1">2.4</SPAN><SPAN class="ft14">- </SPAN>As
            conferências de medidas só poderão ser realizadas com a obra
            devidamente concluída.</P
          >
          <P class="p15 ft6"
            ><SPAN class="ft1">2.5</SPAN><SPAN class="ft11">- </SPAN>Por conta
            da Pandemia <NOBR>COVID-19,</NOBR> os prazo acima estabelecidos
            poderão sofrer atrasos, podendo ser prorrogados por até 15 (quinze)
            dias úteis após o prazo dterminado em contrato, tendo em vista que o
            mercado de móveis está com dificuldades de obtenção de
            <NOBR>matéria-prima</NOBR> e <NOBR>mão-de-obra.</NOBR></P
          >
          <P class="p16 ft8"
            ><SPAN class="ft1">2.6</SPAN><SPAN class="ft13">- </SPAN>Os produtos
            de terceiros estão sujeitos exclusivamente à garantia concedida por
            seu fabricante, não estando cobertos pela mesma garantia dos móveis
            sob medida.</P
          >
          <P class="p12 ft6"
            ><SPAN class="ft1">2.7</SPAN><SPAN class="ft11">- </SPAN>O prazo de
            entrega dos produtos de terceiros variam de acordo com o produto e o
            fabricante, podendo acrescentar até 30 dias úteis ao prazo de
            entrega dos móveis sob medida.</P
          >
          <TABLE
            cellpadding="0"
            cellspacing="0"
            class="t1"
            style="table-layout: fixed; width: 80%; break-inside: avoid; margin-top: 30px; margin-bottom: 30px"
          >
            <tbody>
              <TR>
                <TD class="tr6 td19"><P class="p17 ft15">AMBIENTE</P></TD>
                <TD class="tr6 td20"><P class="p18 ft15">DESCRIÇÃO</P></TD>
                <TD class="tr6 td21"><P class="p19 ft15">VALOR</P></TD>
              </TR>
              <TR v-for="(item, index) in info.items" :key="'item' + index">
                <TD
                  class="tr8 td25"
                  style="vertical-align: middle; text-align: center"
                  >{{ item.name }}</TD
                >
                <TD
                  class="tr8 td26"
                  style="vertical-align: middle; padding: 5px"
                  >{{ item.notes }}</TD
                >
                <TD
                  class="tr8 td27"
                  style="vertical-align: middle; text-align: center"
                  >{{ currencyBr(item.price) }}</TD
                >
              </TR>
              <TR>
                <TD class="tr5 td28"><P class="p2 ft3">&nbsp;</P></TD>
                <TD class="tr5 total"><P class="p21 ft1">TOTAL</P></TD>
                <TD class="tr5 total-price"
                  ><P class="p22 ft1">{{ currencyBr(totalItemsPrice) }}</P></TD
                >
              </TR>
              <TR>
                <TD class="tr1 td29"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td23"><P class="p2 ft4">&nbsp;</P></TD>
                <TD class="tr1 td24"><P class="p2 ft4">&nbsp;</P></TD>
              </TR>
            </tbody>
          </TABLE>
        </DIV>
        <DIV id="id1_2">
          <P class="p23 ft1">3 - PREÇO E FORMA DE PAGAMENTO PACTUADOS</P>
        </DIV>
      </DIV>
      <DIV id="page_2">
        <P class="p12 ft6"
          ><SPAN class="ft1">3.1</SPAN><SPAN class="ft17">- </SPAN>Fica pactuado
          que no preço já estão incluídos todos os encargos, transporte,
          montagem e eventuais descontos concedidos especificamente para os
          produtos detalhados neste Contrato.</P
        >
        <P class="p12 ft6"
          ><SPAN class="ft1">3.2</SPAN><SPAN class="ft11">- </SPAN>O preço será
          pago pelo CONTRATANTE para a CONTRATADA, com vencimento e forma de
          cobrança abaixo detalhados:</P
        >
        <TABLE cellpadding="0" cellspacing="0" class="t2" style="margin-top: 30px; margin-bottom: 30px">
          <tbody>
            <TR>
              <TD class="tr0 td30"><P class="p24 ft1">PC</P></TD>
              <TD class="tr0 td31"><P class="p25 ft1">VENCIMENTO</P></TD>
              <TD class="tr0 td32"
                ><P class="p26 ft1">FORMA DE PAGAMENTO</P></TD
              >
              <TD class="tr0 td33"><P class="p27 ft1">VALOR</P></TD>
            </TR>
            <TR>
              <TD class="tr1 td34"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td35"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td36"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td37"><P class="p2 ft4">&nbsp;</P></TD>
            </TR>
            <template v-for="(portion, i) in info.portions">
              <TR :key="'portion' + i">
                <TD class="tr8 td38" v-if="info.portions[0].isEntry">
                  <P class="p28 ft2" v-if="portion.isEntry">Entrada</P>
                  <P class="p28 ft2" v-else>{{ i }}</P>
                </TD>
                <TD class="tr8 td38" v-else>
                  <P class="p28 ft2">{{ i }}</P>
                </TD>
                <TD class="tr8 td39"
                  ><P class="p29 ft2">{{
                    datetimeBr(portion.expiringDate).split(' ')[0]
                  }}</P></TD
                >
                <TD class="tr8 td40"
                  ><P class="p20 ft2">{{ portion.paymentMethod }}</P></TD
                >
                <TD class="tr8 td41"
                  ><P class="p20 ft2">{{ currencyBr(portion.price) }}</P></TD
                >
              </TR>
              <TR :key="'number' + i">
                <TD class="tr7 td34"><P class="p2 ft16">&nbsp;</P></TD>
                <TD class="tr7 td35"><P class="p2 ft16">&nbsp;</P></TD>
                <TD class="tr7 td36"><P class="p2 ft16">&nbsp;</P></TD>
                <TD class="tr7 td37"><P class="p2 ft16">&nbsp;</P></TD>
              </TR>
            </template>
          </tbody>
        </TABLE>
        <P class="p30 ft19"
          ><SPAN class="ft1">3.3</SPAN><SPAN class="ft18">- </SPAN>O
          inadimplemento do CONTRATANTE, ainda que parcial, isenta a CONTRATADA
          da obrigação de entrega dos produtos no prazo estabelecido, até que
          ocorra a regularização dos pagamentos.</P
        >
        <P class="p31 ft1">4 - ALTERAÇÃO DO PROJETO</P>
        <P class="p30 ft19"
          ><SPAN class="ft1">4.1</SPAN><SPAN class="ft20">- </SPAN>Toda e
          qualquer alteração do projeto, após a assinatura do contrato, só
          poderá ser feita até o envio do pedido para a fábrica, o que ocorre
          até 03 dias corridos após a conferência final das medidas.</P
        >
        <P class="p32 ft19"
          ><SPAN class="ft1">4.2</SPAN><SPAN class="ft21">- </SPAN>O CONTRATANTE
          fica ciente de que tal procedimento poderá implicar em alterações de
          prazo de entrega e valor do contrato. Alterações no momento da
          execução da montagem somente com autorização por escrito do CONTRANTE
          e prévia consulta ao projetista responsável, podendo gerar custo
          adicional.</P
        >
        <P class="p33 ft1">5 – DESISTÊNCIA</P>
        <P class="p34 ft19"
          ><SPAN class="ft1">5.1</SPAN><SPAN class="ft20">- </SPAN>Por se tratar
          de produto sob encomenda, não será admitida desistência após o envio
          do pedido a fábrica. Caso o pedido ainda não tenha sido enviado, será
          cobrada multa de 30% do valor total do Contrato a título de
          ressarcimento das despesas incorridas pelo levantamento de medidas,
          confecção de projetos, conferência e despesas administrativas.</P
        >
        <P class="p35 ft1">6 – INSTALAÇÃO</P>
        <P class="p36 ft10"
          ><SPAN class="ft1">6.1</SPAN><SPAN class="ft9">- </SPAN>O CONTRATANTE
          fica desde já ciente, de que para instalação dos móveis deste
          contrato, a CONTRATADA necessitará de um prazo entre 1 (um) a
          4(quatro) dias por ambiente dependendo da complexidade da
          instalação.</P
        >
        <P class="p12 ft2"
          ><SPAN class="ft1">6.2</SPAN><SPAN class="ft22">- </SPAN>O CONTRATANTE
          deverá acompanhar a entrega e a montagem, ou designar responsável para
          tal tarefa.</P
        >
        <P class="p37 ft10"
          ><SPAN class="ft1">6.3</SPAN><SPAN class="ft23">- </SPAN>No ato da
          entrega e instalação, o CONTRATANTE deverá possuir em mãos a planta
          hidráulica e elétrica dos ambientes, assim como avisar sobre regras de
          condomínio, para entrega e instalação dos móveis. Caso contrário os
          instaladores não têm permissão para fazer perfurações nas paredes,
          exceto com autorização do CONTRATANTE, se responsabilizando por
          eventuais danos e despesas causados ao imóvel, móveis, acessórios ou
          de terceiros também atingidos, decorrentes da eventual perfuração dos
          dutos de água, energia, telefone ou qualquer outro, devendo a
          instalação ser suspensa até que o CONTRATANTE providencie os reparos
          que se fizerem necessários.</P
        >
        <P class="p38 ft25"
          ><SPAN class="ft1">6.4</SPAN><SPAN class="ft24">- </SPAN>Na instalação
          dos móveis poderão sobrar algumas peças, as quais serão recolhidas ao
          término da montagem pela CONTRATADA.</P
        >
      </DIV>
      <DIV id="page_3">
        <P class="p39 ft19"
          ><SPAN class="ft1">6.5</SPAN><SPAN class="ft21">- </SPAN>O CONTRATANTE
          se compromete a deixar os locais da instalação completamente livres de
          móveis ou qualquer outra instalação ou equipamentos que prejudiquem a
          instalação.</P
        >
        <P class="p40 ft19"
          ><SPAN class="ft1">6.6</SPAN><SPAN class="ft21">- </SPAN>O CONTRATANTE
          fica ciente que a instalação pode gerar barulho e poeira, motivo pelo
          qual é recomendado sejam retirados do ambiente crianças e adultos
          alérgicos a pó ou sensíveis.</P
        >
        <P class="p41 ft19"
          >E por estarem as partes,
          <SPAN class="ft26">CONTRATADA E CONTRATANTE</SPAN>, de pleno acordo
          com tudo quanto se encontra disposto neste instrumento particular,
          assinam em duas vias de igual teor e forma, na presença das duas
          testemunhas <NOBR>infra-assinadas</NOBR> e
          <NOBR>destinando-se</NOBR> uma via para cada uma das partes.</P
        >
        <P class="p42 ft2"
          >Farroupilha, {{ currentDayComputed }} de
          {{ currentMonthComputed }} de {{ currentYearComputed }}.</P
        >
        <TABLE cellpadding="0" cellspacing="0" class="t3">
          <tbody>
            <TR>
              <TD class="tr9 td42"
                ><P class="p2 ft27"
                  >________________________________________</P
                ></TD
              >
              <TD class="tr9 td43"
                ><P class="p43 ft27"
                  >_______________________________________</P
                ></TD
              >
            </TR>
            <TR>
              <TD class="tr10 td42"><P class="p2 ft2">CONTRATANTE</P></TD>
              <TD class="tr10 td43"><P class="p43 ft2">CONTRATADA</P></TD>
            </TR>
          </tbody>
        </TABLE>
        <P class="p44 ft2">Testemunhas</P>
        <TABLE cellpadding="0" cellspacing="0" class="t4">
          <tbody>
            <TR>
              <TD class="tr2 td44"
                ><P class="p2 ft28"
                  >..................................................................................……</P
                ></TD
              >
              <TD class="tr2 td45"
                ><P class="p2 ft28"
                  >..................................................................................…..</P
                ></TD
              >
            </TR>
            <TR>
              <TD class="tr0 td44"><P class="p2 ft2">NOME/CPF</P></TD>
              <TD class="tr0 td45"><P class="p2 ft2">NOME/CPF</P></TD>
            </TR>
          </tbody>
        </TABLE>
      </DIV>
    </div>
    <div ref="budget_content" class="pdf-container">
      <div id="page_1">
        <TABLE cellpadding="0" cellspacing="0" class="t0">
          <TR>
            <TD colspan="2" class="tr0 td0"
              ><P class="p1 ft2"
                ><SPAN class="ft1">CONTRATADA</SPAN>: Nath Móveis / Celso Nath
                Móveis</P
              ></TD
            >
            <TD class="tr0 td1"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr0 td2"
              ><P class="p3 ft1">Nº: <SPAN class="ft2">42</SPAN></P></TD
            >
          </TR>
          <TR>
            <TD class="tr1 td3"><P class="p2 ft4">&nbsp;</P></TD>
            <TD colspan="2" class="tr1 td4"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr2 td6"><P class="p2 ft3">&nbsp;</P></TD>
            <TD colspan="3" class="tr2 td7"
              ><P class="p4 ft2"
                >Rua Lucindo Lodi, 49 - Monte Verde
                <NOBR>|95181-300|</NOBR> Farroupilha | RS</P
              ></TD
            >
          </TR>
          <TR>
            <TD class="tr3 td6"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr3 td8"
              ><P class="p4 ft2"
                ><NOBR>E-mail:</NOBR> contato@moveisnath.com.br</P
              ></TD
            >
            <TD colspan="2" class="tr3 td9"
              ><P class="p2 ft2">CNPJ: <NOBR>16.714.575.0001-06</NOBR></P></TD
            >
          </TR>
          <TR>
            <TD class="tr1 td3"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD colspan="2" class="tr4 td12"
              ><P class="p1 ft2"
                ><SPAN class="ft1">CONTRATANTE</SPAN><SPAN class="ft5">: </SPAN
                >{{ info.attendence.client.name }}</P
              ></TD
            >
            <TD class="tr4 td13"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr4 td14"><P class="p2 ft3">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr1 td3"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr5 td15"><P class="p1 ft2">CPF/CNPJ</P></TD>
            <TD class="tr5 td8"
              ><P class="p5 ft2"
                ><NOBR>{{ info.attendence.client.CPF }}</NOBR></P
              ></TD
            >
            <TD class="tr5 td16"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr5 td14"><P class="p5 ft2">RG/IE:</P></TD>
          </TR>
          <TR>
            <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td18"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr5 td15"><P class="p1 ft2">Endereço</P></TD>
            <TD class="tr5 td8"
              ><P
                class="p5 ft2"
                v-if="info.attendence.client.clientAddress.street"
                >Rua {{ info.attendence.client.clientAddress.street }},
                {{ info.attendence.client.clientAddress.number }}</P
              ></TD
            >
            <TD class="tr5 td16"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr5 td14"
              ><P class="p5 ft2"
                >CEP:
                <NOBR>{{ info.attendence.client.clientAddress.CEP }}</NOBR></P
              ></TD
            >
          </TR>
          <TR>
            <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td18"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr5 td15"><P class="p1 ft2">Bairro/Cidade/UF</P></TD>
            <TD class="tr5 td8"
              ><P
                class="p5 ft2"
                v-if="info.attendence.client.clientAddress.neighbourhood"
                >{{ info.attendence.client.clientAddress.neighbourhood }} -
                {{ info.attendence.client.clientAddress.city }}/{{
                  info.attendence.client.clientAddress.state
                }}</P
              ></TD
            >
            <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr5 td15"><P class="p1 ft2">Telefone</P></TD>
            <TD class="tr5 td8"
              ><P class="p5 ft2">{{ info.attendence.client.contact }}</P></TD
            >
            <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr5 td15"
              ><P class="p1 ft2"><NOBR>E-mail</NOBR></P></TD
            >
            <TD class="tr5 td8"
              ><P class="p5 ft2">{{ info.attendence.client.email }}</P></TD
            >
            <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr5 td15" style="border-bottom: black 1px solid"
              ><P class="p1 ft2">Observação</P></TD
            >
            <TD class="tr5 td8" style="border-bottom: black 1px solid"
              ><P class="p2 ft3">&nbsp;</P></TD
            >
            <TD class="tr5 td13" style="border-bottom: black 1px solid"
              ><P class="p2 ft3">&nbsp;</P></TD
            >
            <TD class="tr5 td14" style="border-bottom: black 1px solid"
              ><P class="p2 ft3">&nbsp;</P></TD
            >
          </TR>
          <TR>
            <TD class="tr5 td15"><P class="p1 ft2">Data de entrega</P></TD>
            <TD class="tr5 td8"
              ><P class="p5 ft2">{{ formatDeliverDate }}</P></TD
            >
            <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr5 td15"><P class="p1 ft2">VENDEDOR</P></TD>
            <TD class="tr5 td8"
              ><P class="p5 ft2">{{ info.attendence.user.name }}</P></TD
            >
            <TD class="tr5 td13"><P class="p2 ft3">&nbsp;</P></TD>
            <TD class="tr5 td14"><P class="p2 ft3">&nbsp;</P></TD>
          </TR>
          <TR>
            <TD class="tr1 td17"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td10"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td11"><P class="p2 ft4">&nbsp;</P></TD>
            <TD class="tr1 td5"><P class="p2 ft4">&nbsp;</P></TD>
          </TR>
        </TABLE>
        <TABLE
          cellpadding="0"
          cellspacing="0"
          class="t1"
          style="table-layout: fixed; width: 80%; break-inside: avoid;"
        >
          <tbody>
            <TR>
              <TD class="tr6 td19"><P class="p17 ft15">AMBIENTE</P></TD>
              <TD class="tr6 td20"><P class="p18 ft15">DESCRIÇÃO</P></TD>
              <TD class="tr6 td21"><P class="p19 ft15">VALOR</P></TD>
            </TR>
            <TR v-for="(item, i) in info.items" :key="'item' + i">
              <TD
                class="tr8 td25"
                style="vertical-align: middle; text-align: center"
                >{{ itemStatusLabel(item) }}</TD
              >
              <TD
                class="tr8 td26"
                style="vertical-align: middle; text-align: center"
                >{{ item.notes }}
              </TD>
              <TD
                class="tr8 td27"
                style="vertical-align: middle; text-align: center"
                >{{ currencyBr(item.price) }}</TD
              >
            </TR>
            <TR>
              <TD class="tr5 td28"><P class="p2 ft3">&nbsp;</P></TD>
              <TD class="tr5 total"><P class="p21 ft1">TOTAL</P></TD>
              <TD class="tr5 total-price"
                ><P class="p22 ft1">{{ currencyBr(totalItemsPrice) }}</P></TD
              >
            </TR>
            <TR>
              <TD class="tr1 td29"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td23"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td24"><P class="p2 ft4">&nbsp;</P></TD>
            </TR>
          </tbody>
        </TABLE>

        <TABLE cellpadding="0" cellspacing="0" class="t2">
          <tbody>
            <TR>
              <TD class="tr0 td30"><P class="p24 ft1">PC</P></TD>
              <TD class="tr0 td31"><P class="p25 ft1">VENCIMENTO</P></TD>
              <TD class="tr0 td32"
                ><P class="p26 ft1">FORMA DE PAGAMENTO</P></TD
              >
              <TD class="tr0 td33"><P class="p27 ft1">VALOR</P></TD>
            </TR>
            <TR>
              <TD class="tr1 td34"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td35"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td36"><P class="p2 ft4">&nbsp;</P></TD>
              <TD class="tr1 td37"><P class="p2 ft4">&nbsp;</P></TD>
            </TR>
            <template v-for="(portion, i) in info.portions">
              <TR :key="'portion' + i">
                <TD class="tr8 td38" v-if="info.portions[0].isEntry">
                  <P class="p28 ft2" v-if="portion.isEntry">Entrada</P>
                  <P class="p28 ft2" v-else>{{ i }}</P>
                </TD>
                <TD class="tr8 td38" v-else>
                  <P class="p28 ft2">{{ i }}</P>
                </TD>
                <TD class="tr8 td39"
                  ><P class="p29 ft2">{{
                    datetimeBr(portion.expiringDate).split(' ')[0]
                  }}</P></TD
                >
                <TD class="tr8 td40"
                  ><P class="p20 ft2">{{ portion.paymentMethod }}</P></TD
                >
                <TD class="tr8 td41"
                  ><P class="p20 ft2">{{ currencyBr(portion.price) }}</P></TD
                >
              </TR>
              <TR :key="'number' + i">
                <TD class="tr7 td34"><P class="p2 ft16">&nbsp;</P></TD>
                <TD class="tr7 td35"><P class="p2 ft16">&nbsp;</P></TD>
                <TD class="tr7 td36"><P class="p2 ft16">&nbsp;</P></TD>
                <TD class="tr7 td37"><P class="p2 ft16">&nbsp;</P></TD>
              </TR>
            </template>
          </tbody>
        </TABLE>
      </div>
    </div>
  </div>
</template>

<script>
/* import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; */

import html2pdf from 'html2pdf.js';

export default {
  props: { info: Object, generate: String },
  methods: {
    /*itemStatus(item) {
      if (item.denyReason == '') {
        return true;
      } else {
        return false;
      }
    },*/
    itemStatusLabel(item) {
      //console.log(item)
      let tempLabel = item.name;
      if (item.status == 'A') {
        return (tempLabel += ' (Aprovado)');
      } else if (item.status == 'R') {
        return (tempLabel += ' (Reprovado)');
      } else {
        return (tempLabel += '');
      }
    },
    async downloadWithCSS() {
      if (this.info.items.length > 0) {
        const temp = { ...this.info };
        const type = this.generate;
        var quotes = this.$refs[this.generate];

        let name = type === 'content' ? 'Contrato' : 'Orçamento';
        name = name + ' - ' + temp.attendence.client.name;
        var opt = {
          margin: [15,0,15,0],
          filename: `${name}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, letterRendering: true },
          jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
          pagebreak: { mode: 'css'}
        };

        html2pdf().set(opt).from(quotes).save();

        /* html2canvas(quotes).then((canvas) => {
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;

          var doc = new jsPDF('p', 'mm');
          var position = 0;

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          } 
          let name = type === 'content' ? 'Contrato' : 'Orçamento';
          name = name + ' - ' + temp.attendence.client.name;
          doc.save(name);
        }); */
      }
      this.$emit('downloadFinished');
    },
  },
  computed: {
    currentDayComputed() {
      const d = new Date();
      return ('0' + d.getDate()).slice(-2);
    },
    currentMonthComputed() {
      const d = new Date();
      const monthsPT = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
      return monthsPT[d.getMonth()];
    },
    currentYearComputed() {
      const d = new Date();
      return d.getFullYear();
    },
    formatDeliverDate() {
      let d = new Date(this.info.attendence.deliverDate);
      d.setMonth(d.getMonth() + 1);
      d.setDate(d.getDate() + 1);
      let month = '' + d.getMonth();
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [day, month, year].join('/');
    },
    totalItemsPrice() {
      //console.log(this.info.items)
      let sum = 0;
      this.info.items.forEach((el) => {
        //console.log(el)
        if (el.status != 'R') {
          sum += this.getAttendenceItemPrice(el);
        }
      });
      return sum;
    },
  },
  mounted() {
    this.downloadWithCSS();
  },
};
</script>

<style scoped>
* {
  text-rendering: optimizeSpeed;
}
#page_1 {
  position: relative;
  overflow: hidden;
  margin: 90px 0px 23px 75px;
  padding: 0px;
  border: none;
  width: 719px;
}
#page_1 #id1_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 719px;
  overflow: hidden;
}
#page_1 #id1_2 {
  border: none;
  margin: 14px 0px 0px 1px;
  padding: 0px;
  border: none;
  width: 718px;
  overflow: hidden;
}

#page_2 {
  position: relative;
  overflow: hidden;
  margin: 0px 0px 0px 75px;
  padding: 0px;
  border: none;
  width: 719px;
}

#page_3 {
  position: relative;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 794px;
}

.ft0 {
  font: bold 24px 'Arial';
  line-height: 29px;
}
.ft1 {
  font: bold 13px 'Arial';
  line-height: 16px;
}
.ft2 {
  font: 13px 'Arial';
  line-height: 16px;
}
.ft3 {
  font: 1px 'Arial';
  line-height: 1px;
}
.ft4 {
  font: 1px 'Arial';
  line-height: 2px;
}
.ft5 {
  font: 16px 'Arial';
  line-height: 18px;
}
.ft6 {
  font: 13px 'Arial';
  line-height: 18px;
}
.ft7 {
  font: bold 13px 'Arial';
  margin-left: 4px;
  line-height: 17px;
}
.ft8 {
  font: 13px 'Arial';
  line-height: 17px;
}
.ft9 {
  font: bold 13px 'Arial';
  margin-left: 6px;
  line-height: 19px;
}
.ft10 {
  font: 13px 'Arial';
  line-height: 19px;
}
.ft11 {
  font: bold 13px 'Arial';
  margin-left: 4px;
  line-height: 18px;
}
.ft12 {
  font: bold 13px 'Arial';
  margin-left: 6px;
  line-height: 17px;
}
.ft13 {
  font: bold 13px 'Arial';
  margin-left: 5px;
  line-height: 17px;
}
.ft14 {
  font: bold 13px 'Arial';
  margin-left: 4px;
  line-height: 16px;
}
.ft15 {
  font: bold 16px 'Arial';
  line-height: 19px;
}
.ft16 {
  font: 1px 'Arial';
  line-height: 3px;
}
.ft17 {
  font: bold 13px 'Arial';
  margin-left: 5px;
  line-height: 18px;
}
.ft18 {
  font: bold 13px 'Arial';
  margin-left: 10px;
  line-height: 20px;
}
.ft19 {
  font: 13px 'Arial';
  line-height: 20px;
}
.ft20 {
  font: bold 13px 'Arial';
  margin-left: 6px;
  line-height: 20px;
}
.ft21 {
  font: bold 13px 'Arial';
  margin-left: 7px;
  line-height: 20px;
}
.ft22 {
  font: bold 13px 'Arial';
  margin-left: 6px;
  line-height: 16px;
}
.ft23 {
  font: bold 13px 'Arial';
  margin-left: 9px;
  line-height: 19px;
}
.ft24 {
  font: bold 13px 'Arial';
  margin-left: 7px;
  line-height: 22px;
}
.ft25 {
  font: 13px 'Arial';
  line-height: 22px;
}
.ft26 {
  font: bold 13px 'Arial';
  line-height: 20px;
}
.ft27 {
  font: 11px 'Arial';
  line-height: 14px;
}
.ft28 {
  font: 10px 'Arial';
  line-height: 13px;
}
p {
  break-inside: avoid;
}
.p0 {
  text-align: left;
  padding-left: 94px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p1 {
  text-align: left;
  padding-left: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p2 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p3 {
  text-align: left;
  padding-left: 75px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p4 {
  text-align: left;
  padding-left: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p5 {
  text-align: left;
  padding-left: 3px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p6 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p7 {
  text-align: left;
  padding-left: 1px;
  margin-top: 14px;
  margin-bottom: 0px;
}
.p8 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 23px;
  margin-bottom: 0px;
}
.p9 {
  text-align: left;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p10 {
  text-align: left;
  padding-left: 1px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p11 {
  text-align: left;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 22px;
  margin-bottom: 0px;
}
.p12 {
  text-align: left;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p13 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 76px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p14 {
  text-align: left;
  padding-left: 1px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p15 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p16 {
  text-align: left;
  padding-left: 1px;
  padding-right: 76px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p17 {
  text-align: left;
  padding-left: 23px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p18 {
  text-align: left;
  padding-left: 156px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p19 {
  text-align: left;
  padding-left: 22px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p20 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p21 {
  text-align: left;
  padding-left: 363px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p22 {
  text-align: center;
  padding-left: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p23 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p24 {
  text-align: left;
  padding-left: 41px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p25 {
  text-align: left;
  padding-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p26 {
  text-align: left;
  padding-left: 59px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p27 {
  text-align: left;
  padding-left: 39px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p28 {
  text-align: center;
  padding-left: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p29 {
  text-align: center;
  padding-right: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p30 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 14px;
  margin-bottom: 0px;
}
.p31 {
  text-align: left;
  padding-left: 1px;
  margin-top: 32px;
  margin-bottom: 0px;
}
.p32 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p33 {
  text-align: left;
  padding-left: 1px;
  margin-top: 30px;
  margin-bottom: 0px;
}
.p34 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 76px;
  margin-top: 19px;
  margin-bottom: 0px;
}
.p35 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p36 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 77px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p37 {
  text-align: justify;
  padding-left: 1px;
  padding-right: 76px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p38 {
  text-align: left;
  padding-left: 1px;
  padding-right: 76px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p39 {
  text-align: left;
  padding-left: 76px;
  padding-right: 77px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p40 {
  text-align: justify;
  padding-left: 76px;
  padding-right: 76px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p41 {
  text-align: justify;
  padding-left: 76px;
  padding-right: 76px;
  margin-top: 31px;
  margin-bottom: 0px;
}
.p42 {
  text-align: right;
  padding-right: 76px;
  margin-top: 32px;
  margin-bottom: 0px;
}
.p43 {
  text-align: left;
  padding-left: 73px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p44 {
  text-align: left;
  padding-left: 353px;
  margin-top: 110px;
  margin-bottom: 0px;
}

.td0 {
  border-left: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 353px;
  vertical-align: bottom;
}
.td1 {
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 131px;
  vertical-align: bottom;
}
.td2 {
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 158px;
  vertical-align: bottom;
}
.td3 {
  border-left: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 114px;
  vertical-align: bottom;
}
.td4 {
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 370px;
  vertical-align: bottom;
}
.td5 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 158px;
  vertical-align: bottom;
}
.td6 {
  border-left: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 114px;
  vertical-align: bottom;
}
.td7 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 528px;
  vertical-align: bottom;
}
.td8 {
  padding: 0px;
  margin: 0px;
  width: 239px;
  vertical-align: bottom;
}
.td9 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 289px;
  vertical-align: bottom;
}
.td10 {
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 239px;
  vertical-align: bottom;
}
.td11 {
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 131px;
  vertical-align: bottom;
}
.td12 {
  border-left: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 353px;
  vertical-align: bottom;
}
.td13 {
  padding: 0px;
  margin: 0px;
  width: 131px;
  vertical-align: bottom;
}
.td14 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 158px;
  vertical-align: bottom;
}
.td15 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 113px;
  vertical-align: bottom;
}
.td16 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 130px;
  vertical-align: bottom;
}
.td17 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 113px;
  vertical-align: bottom;
}
.td18 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 130px;
  vertical-align: bottom;
}
.td19 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 132px;
  vertical-align: bottom;
}
.td20 {
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  border-bottom: #000000 1px solid;

  padding: 0px;
  margin: 0px;
  width: 409px;
  vertical-align: bottom;
}
.td21 {
  border-bottom: #000000 1px solid;
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 99px;
  vertical-align: bottom;
}
.td22 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 132px;
  vertical-align: bottom;
}
.td23 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 409px;
  vertical-align: bottom;
}
.td24 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 99px;
  vertical-align: bottom;
}
.td25 {
  border-left: #000000 1px solid;
  border-bottom: #000000 1px solid;
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 132px;
  vertical-align: bottom;
}
.td26 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 409px;
  vertical-align: bottom;
}
.td27 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 99px;
  vertical-align: bottom;
}
.total {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 409px;
  vertical-align: bottom;
}
.total-price {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 99px;
  vertical-align: bottom;
}
.td28 {
  border-left: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 133px;
  vertical-align: bottom;
}
.td29 {
  border-left: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 133px;
  vertical-align: bottom;
}
.td30 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 98px;
  vertical-align: bottom;
}
.td31 {
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 139px;
  vertical-align: bottom;
}
.td32 {
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 278px;
  vertical-align: bottom;
}
.td33 {
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 124px;
  vertical-align: bottom;
}
.td34 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 98px;
  vertical-align: bottom;
}
.td35 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 139px;
  vertical-align: bottom;
}
.td36 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 278px;
  vertical-align: bottom;
}
.td37 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 124px;
  vertical-align: bottom;
}
.td38 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 98px;
  vertical-align: bottom;
}
.td39 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 139px;
  vertical-align: bottom;
}
.td40 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 278px;
  vertical-align: bottom;
}
.td41 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 124px;
  vertical-align: bottom;
}
.td42 {
  padding: 0px;
  margin: 0px;
  width: 267px;
  vertical-align: bottom;
}
.td43 {
  padding: 0px;
  margin: 0px;
  width: 333px;
  vertical-align: bottom;
}
.td44 {
  padding: 0px;
  margin: 0px;
  width: 340px;
  vertical-align: bottom;
}
.td45 {
  padding: 0px;
  margin: 0px;
  width: 258px;
  vertical-align: bottom;
}

.tr0 {
  height: 23px;
}
.tr1 {
  height: 2px;
}
.tr2 {
  height: 19px;
}
.tr3 {
  height: 17px;
}
.tr4 {
  height: 24px;
}
.tr5 {
  height: 21px;
}
.tr6 {
  height: 26px;
}
.tr7 {
  height: 3px;
}
.tr8 {
  height: 20px;
}
.tr9 {
  height: 25px;
}
.tr10 {
  height: 22px;
}

.t0 {
  width: 643px;
  margin-top: 7px;
  font: 13px 'Arial';
}
.t1 {
  width: 643px;
  margin-top: 14px;
  font: 13px 'Arial';
}
.t2 {
  width: 643px;
  margin-top: 16px;
  font: 13px 'Arial';
}
.t3 {
  width: 600px;
  margin-left: 79px;
  margin-top: 103px;
  font: 11px 'Arial';
}
.t4 {
  width: 598px;
  margin-left: 79px;
  margin-top: 73px;
  font: 10px 'Arial';
}

.pdf-container * {
  -webkit-font-smoothing: never !important;
}
</style>  