<template>
  <div class="pt-7 attendences-div">
    <div class="px-8 top-bar d-flex">
      <div class="titleText">Atendimentos</div>
      <v-spacer></v-spacer>
      <v-select class="rounded-lg hasBlackIcon mr-4" prepend-inner-icon="la-user" append-icon="la-angle-down"
        hide-details v-model="viewPeopleModel" :items="viewPeople" dense return-object multiple attach chips
        item-text="name" color="black" style="background-color: white; height: fit-content" depressed height="49px"
        outlined no-data-text="Nenhuma informação disponível" :disabled="!userHasPermission('view_others_attendences')">
        <template v-slot:[`selection`]="{ item }">
          <v-chip>
            <template v-if="item != 'Todas as pessoas'">
              {{ getAbbreviatedString(item.name, 2) }}
            </template>
            <template v-else>
              {{ item }}
            </template>
          </v-chip>
        </template>
      </v-select>
      <v-text-field outlined placeholder="Pesquisar em atendimentos" append-icon="mdi-magnify"
        v-model="filterAttendences"></v-text-field>
      <v-spacer></v-spacer>
      <div class="d-flex datePickerDiv">
        <v-menu v-model="startTimeMenu" :close-on-content-click="false" transition="slide-y-transition"
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startTimeFormatted" placeholder="Início" v-bind="attrs" v-on="on" outlined
              hide-details readonly dense></v-text-field>
          </template>
          <v-date-picker :max="endTime" @change="changeStartTime" no-title @input="startTimeMenu = false"
            locale="pt-br"></v-date-picker>
        </v-menu>
        <hr class="mx-3" style="
            width: 20px;
            border: 1px solid black;
            align-self: center;
            background: black;
          " />
        <v-menu v-model="endTimeMenu" :close-on-content-click="false" transition="slide-y-transition" left
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field placeholder="Final" v-model="endTimeFormatted" v-bind="attrs" v-on="on" outlined hide-details
              readonly dense></v-text-field>
          </template>
          <v-date-picker :min="startTime" @change="changeEndTime" no-title @input="endTimeMenu = false"
            locale="pt-br"></v-date-picker>
        </v-menu>
      </div>
      <v-btn v-if="userHasPermission('change_attendence')" class="rounded-lg" @click="modelDialogAttendence = true"
        color="#177DFF" depressed dark>Novo atendimento
      </v-btn>
      <v-btn v-else class="disabled-button rounded-lg" color="#177DFF" depressed dark>Novo atendimento</v-btn>
      <!-- <v-btn class="rounded-lg" @click="teste()" color="#177DFF" depressed dark>TESTE
      </v-btn> -->
    </div>
    <div v-dragscroll:nochilddrag class="items-div custom-scroll d-flex">
      <div data-dragscroll class="d-flex items-div-inner">
        <v-sheet class="sheet-container custom-scroll" v-for="status in allowedStatusesArray" :key="status.id">
          <div class="sheet-container-header" relative>
            <LineBorder class="sheet-line-header" :color="status.color" top />
          </div>
          <div :id="makeBoxId(status.id)" @drop="drop($event)" @dragover="allowDrop($event)">
            <div class="sheet-container-title">
              <span>
                {{ status.name }} ({{ columnArray(status.id).length }})
              </span>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="viewValue(status)" depressed fab small v-bind="attrs" v-on="on" style="
                      width: 15px;
                      height: 15px;
                      margin-left: 210px;
                      margin-top: -17px;
                    ">
                    <view-on-icon width="13px" height="13px" v-show="!status.showValue" />
                    <view-off-icon width="13px" height="13px" v-show="status.showValue" />
                  </v-btn>
                </template>
                <span v-if="!status.showValue">Revelar Valor</span>
                <span v-else>Esconder Valor</span>
              </v-tooltip>
              {{ space }}
              <span v-show="loggedUser.role.name === 'Administrador' && status.showValue
          ">
                {{ currencyBr(getAttendencesPriceSum(columnArray(status.id))) }}
              </span>
            </div>
            <div class="content-container custom-scroll">
              <div>
                <v-card v-for="(attendence, i) in columnArray(status.id)" :key="i" relative style="
                    box-shadow: 2px 4px 6px rgb(0, 0, 0, 0.3);
                    transition: 0.3s;
                    margin-bottom: 8px;
                    line-height: 16px;
                  " class="sheet-container-card" draggable="true" @dragstart="drag($event)"
                  @click="openInfoDialog(attendence)" :id="attendence.id">
                  <LineBorder v-if="getDaysUpdatedAt(attendence) >
          getAttendenceStatus(status.id).alertTime ||
          (!hasSchedule(attendence, status.id) &&
            status.showScheduleDate)
          " color="#FF866E" left class="card-side-line" />
                  <LineBorder v-else color="#E4E8F0" left class="card-side-line" />
                  <div class="sheet-container-content" style="display: grid; margin-right: 8px; margin-bottom: 8px">
                    <v-card-title class="sheet-card-title" style="
                        font-size: 12px;
                        padding-top: 0px;
                        line-height: 1.5rem;
                        overflow: hidden;
                        display: inline-block;
                        text-overflow: ellipsis;
                        max-width: 28ch;
                        white-space: nowrap;
                      ">{{ attendence.client.name }}</v-card-title>
                    <div class="sheet-card-subtitle" style="color: #b7c3da"
                      v-if="status.id == 1 && status.showScheduleDate">
                      Data de registro:
                      {{ datetimeBr(attendence.registerDate) }}
                    </div>
                    <div class="sheet-card-subtitle" style="color: #0cd5a6" v-if="status.id != 1 &&
          status.showScheduleDate &&
          hasSchedule(attendence, status.id)
          ">
                      {{ status.name.split(' ')[0] }}:
                      {{
          datetimeBr(
            hasSchedule(attendence, status.id).startTime
          ).split(' ')[0]
        }}
                    </div>
                    <div v-if="status.id != 1 &&
          status.showScheduleDate &&
          !hasSchedule(attendence, status.id)
          " class="sheet-card-subtitle" style="color: #ff866e">
                      Data pendente
                    </div>
                    <div class="responsible">
                      <v-select class="custom-scroll" hide-details dense outlined
                        @click.stop="getOldAttendence(attendence.user)" @change="selectNewAttendance(attendence)"
                        :items="users" return-object v-model="attendence.user.name" item-text="name"></v-select>
                    </div>
                    <div class="attendence-actions">
                      <div class="d-flex" style="width: 100%">
                        <v-tooltip v-if="attendence.chat && !attendence.chat?.unreadCount && loggedUser.statusWhats == true
          " bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :height="30" :width="30" depressed @click.stop="openChatDialog(attendence)" fab small
                              class="slim-border sheet-circle-button" v-on="on" v-bind="attrs">
                              <new-chat-icon width="17" height="17" />
                            </v-btn>
                          </template>
                          <span>Chat</span>
                        </v-tooltip>
                        <v-tooltip v-else-if="attendence.chat && attendence.chat?.unreadCount && loggedUser.statusWhats == true
          " bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :height="30" :width="30" depressed @click.stop="openChatDialog(attendence)" fab small
                              class="slim-border sheet-circle-button notify-color tw-border" v-on="on" v-bind="attrs">
                              <div class="notify-text">
                                {{ attendence.chat.unreadCount }}
                              </div>
                            </v-btn>
                          </template>
                          <span>{{ attendence.chat?.unreadCount }} {{ attendence.chat?.unreadCount > 1 ? 'mensagens' :
          'mensagem' }} não {{ attendence.chat?.unreadCount > 1 ? 'lidas' :
          'lida' }}</span>
                        </v-tooltip>

                        <v-btn v-if="!status.hasBudget &&
          !status.hasStatus &&
          !status.hasUpload &&
          !hasSchedule(attendence, status.id)
          " depressed class="d-flex sheet-pill-button slim-border" @click.stop="scheduleAppointment(attendence.id)"
                          style="width: 150px; height: 32px; margin-left: 3px">
                          <calendar-icon width="17" height="17" />

                          <div class="icon-button-text" style="font-weight: 400">
                            Agendar medição
                          </div>
                        </v-btn>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :height="30" :width="30" depressed @click.stop="scheduleAppointment(attendence.id)"
                              fab small class="slim-border sheet-circle-button" v-if="status.showScheduleButton &&
          !hasSchedule(attendence, status.id) &&
          !status.showScheduleDate &&
          status.id != 1
          " v-on="on" v-bind="attrs">
                              <calendar-icon width="17" height="17" />
                            </v-btn>
                          </template>
                          <span>Agendar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :height="30" :width="30" depressed @click.stop="scheduleAppointment(attendence.id)"
                              fab small class="slim-border sheet-circle-button" v-if="status.showScheduleDate &&
          !hasSchedule(attendence, status.id)
          " v-bind="attrs" v-on="on">
                              <v-badge color="error" dot bottom overlap :value="status.showScheduleDate &&
          !hasSchedule(attendence, status.id)
          ">
                                <calendar-icon width="17" height="17" />
                              </v-badge>
                            </v-btn>
                          </template>
                          <span>Agendar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon icon="md:gavel"></v-icon>
                            <v-btn v-if="status.hasBudget &&
          userHasPermission('view_portion')
          " @click.stop="openBudgetDialog(attendence)" depressed fab small class="slim-border sheet-circle-button"
                              v-on="on" v-bind="attrs">
                              <dolar-icon width="17" height="17" />
                            </v-btn>
                          </template>
                          <span>Orçamento</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="status.hasUpload && status.hasBudget" @click.stop="
          uploadClick(status.id, attendence.id)
          " depressed fab small class="slim-border sheet-circle-button" v-bind="attrs" v-on="on">
                              <cloud-upload-icon width="17" height="17" />
                            </v-btn>
                          </template>
                          <span>Arquivos</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="status.hasUpload && !status.hasBudget" @click.stop="
          uploadClick(status.id, attendence.id)
          " depressed fab small :height="30" class="slim-border sheet-circle-button" v-bind="attrs" v-on="on">
                              <cloud-upload-icon width="17" height="17" />
                            </v-btn>
                          </template>
                          <span>Arquivos</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="status.hasStatus &&
          !attendence.item.every((el) => el.status == 'A')
          " @click.stop="
          openStatusDialog(attendence.id, 'repproved')
          " depressed fab small :height="30" class="slim-border sheet-circle-button" v-on="on" v-bind="attrs">
                              <rule-icon width="17" height="17" />
                            </v-btn>
                          </template>
                          <span>Reprovar/Aprovar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="status.hasStatus && attendence.item.length === 0
          " @click.stop="openBudgetDialog(attendence)" depressed fab small :height="30"
                              class="slim-border sheet-circle-button" v-bind="attrs" v-on="on">
                              <v-badge color="amber darken-3" dot bottom overlap :value="attendence.item.length === 0">
                                <rule-icon width="17" height="17" />
                              </v-badge>
                            </v-btn>
                          </template>
                          <span>Adicionar item/pagamento</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="status.showFinishButton" @click.stop="
          openFinishConfirmationModal(attendence)
          " depressed fab small :height="30" class="slim-border sheet-circle-button" v-bind="attrs" v-on="on">
                              <done-icon width="17" height="17" iconSize="17" />
                            </v-btn>
                          </template>
                          <span>Finalizar</span>
                        </v-tooltip>

                        <template v-if="status.showAttendencePrice">
                          <v-spacer></v-spacer>
                          <div class="card-side-money" v-if="loggedUser.role.name === 'Administrador'" style="
                              margin-top: 16px;
                              margin-left: -200px;
                              padding: 7px;
                            ">
                            <span>{{
          currencyBr(getItemsSum(attendence))
        }}</span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-sheet>
      </div>
    </div>
    <CreateAttendence v-if="modelDialogAttendence" @close="closeDialog" v-model="modelDialogAttendence"
      :selectedClient="selectedClient" :selectedChat="null" @saveAttendence="saveAttendence" />
    <UploadFile v-if="modelDialogUpload" :activeTab="uploadSelected" :choosenAttendence="choosenAttendence"
      @close="closeDialog" v-model="modelDialogUpload"></UploadFile>
    <BudgetDialog v-if="modelDialogBudget" @close="closeDialog" v-model="modelDialogBudget"
      :attendence="choosenAttendence" @save="saveBudget" @saveStatus="saveStatus"></BudgetDialog>
    <ChatDialog v-if="modelDialogChat" @close="closeDialog" v-model="modelDialogChat" :attendence="choosenAttendence">
    </ChatDialog>
    <StatusAttendence :attendence="selectedObject" :status="statusSelected" v-if="modelDialogStatus"
      @close="closeDialog" @save="saveStatus" v-model="modelDialogStatus"></StatusAttendence>
    <AttendenceInfo v-if="modelDialogInfo" v-model="modelDialogInfo" @close="closeDialog"
      :choosenAttendence="choosenAttendence"></AttendenceInfo>
    <CreateEditEvent v-if="modalSchedule" v-model="modalSchedule" @close="closeDialog" @createEvent="editEventFunction"
      @editEvent="editEventFunction" :selectedSchedule="attendenceSelected"></CreateEditEvent>
    <ConfirmationModal v-if="modalConfirm" v-model="modalConfirm" message="Você quer finalizar este atendimento?"
      @close="modalConfirm = false" @confirmed="finishAttendence(attendenceToFinish)" />
  </div>
</template>

<script>
import LineBorder from '../../../components/utils/LineBorder.vue';
import CreateAttendence from '../modals/CreateAttendence';
import UploadFile from '../modals/UploadFiles';
import BudgetDialog from '../modals/BudgetDialog';
import StatusAttendence from '../modals/StatusAttendence';
import AttendenceInfo from '../modals/AttendenceInfo';
import ChatDialog from '../modals/ChatDialog.vue';

import { createNamespacedHelpers } from 'vuex';

// const {
//   mapState: mapStateAttendences,
//   mapGetters: mapGettersAttendences,
//   mapActions: mapActionsAttendences,
// } = createNamespacedHelpers('attendencesStatus');

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('attendences');

const { mapActions: mapActionsClients } = createNamespacedHelpers('clients');

const {
  mapState: mapStateConfig,
  mapGetters: mapGettersConfig,
  mapActions: mapActionsConfig,
} = createNamespacedHelpers('config');

const { mapState: mapStateChat } = createNamespacedHelpers('chat');

import CreateEditEvent from './../../calendar/modals/CreateEditEvent';

import { dragscroll } from 'vue-dragscroll';

import ConfirmationModal from '../../../components/utils/ConfirmationModal.vue';

export default {
  directives: {
    dragscroll,
  },
  components: {
    CreateEditEvent,
    LineBorder,
    CreateAttendence,
    UploadFile,
    BudgetDialog,
    StatusAttendence,
    ChatDialog,
    AttendenceInfo,
    ConfirmationModal,
  },
  data() {
    return {
      modalConfirm: false,
      attendenceSelected: null,
      modalSchedule: false,
      selectedClient: null,
      choosenAttendence: null,
      uploadSelected: 0,
      selectedObject: null,
      modelDialogInfo: null,
      modelDialogStatus: null,
      modelDialogAttendence: null,
      modelDialogUpload: null,
      modelDialogBudget: null,
      modelDialogChat: null,
      viewPeopleModel: ['Todas as pessoas'],
      startTime: null,
      startTimeMenu: false,
      endTime: null,
      endTimeMenu: false,
      showedArray: undefined,
      filteredStart: false,
      draggedCardId: null,
      statusSelected: null,
      filterAttendences: '',
      startTimeFormatted: null,
      endTimeFormatted: null,
      attendenceToFinish: null,
      userAttendant: null,
      OldUser: null,
      space: '',
    };
  },
  methods: {
    ...mapActionsClients(['createClient', 'createClientAddress']),
    ...mapActions([
      'createAttendence',
      'editAttendence',
      'editAttendenceStatus',
      'createAttendenceItem',
      'editAttendenceItem',
      'createAttendencePayment',
      'createAttendenceHistoric',
      'deleteAttendencePayments',
      'addAttendenceResponsible',
      'editAttendenceSchedule',
      'createAttendenceSchedule',
    ]),
    // teste() {
    //   console.log("entrou");
    //   this.$socket.client.emit('test', "aaa");
    // },
    openFinishConfirmationModal(attendence) {
      this.attendenceToFinish = attendence;
      this.modalConfirm = true;
    },
    filterChatsUnread() {
      const contactsUnread = this.contacts.filter(
        (item) => item.unreadCount > 0
      );
      return contactsUnread;
    },
    contactCheck(contact, client) {
      const formattedNumber = client?.contact.split('');
      formattedNumber.splice(3, 1);
      let num = formattedNumber.toString();
      num = num.replaceAll(',', '');
      num = '55' + num + '@c.us';
      if (num == contact._serialized && contact.unreadCount) {
        return true;
      } else {
        return false;
      }
    },
    viewValue(status) {
      this.space = ' ';
      status.showValue = !status.showValue;
      this.defSpace();
    },
    defSpace() {
      this.space = '';
    },
    async finishAttendence(e) {
      const finishedStatusId = this.getStatusIdByName('Finalizado');
      const attendence = { ...e, statusId: finishedStatusId };
      await this.editAttendence({ attendence });
      this.modalConfirm = false;
      this.attendenceToFinish = null;
    },
    async editEventFunction(event) {
      const scheduleArray = this.getAttendence(event.id).schedules;
      const existingSchedule = scheduleArray.find(
        (el) => el.statusId == event.statusId
      );
      let schedule = {};
      if (existingSchedule) {
        schedule = await this.editAttendenceSchedule({
          schedule: {
            id: existingSchedule.id,
            attendenceId: event.id,
            statusId: event.statusId,
            startTime: event.startTime,
            endTime: event.endTime,
            description: event.description,
            userId: event.user.id,
          },
        });
      } else {
        schedule = await this.createAttendenceSchedule({
          schedule: {
            attendenceId: event.id,
            statusId: event.statusId,
            startTime: event.startTime,
            endTime: event.endTime,
            description: event.description,
            userId: event.user.id,
          },
        });
      }
      const choosenAttendence = this.getAttendence(event.id);

      choosenAttendence.oldDescription = this.statuses.find(
        (el) =>
          el.id ==
          this.attendences.find((el) => el.id == choosenAttendence.id).statusId
      ).name;
      const newDescription = this.statuses.find(
        (el) => el.id == choosenAttendence.statusId
      ).name;
      const contactStatus = this.getStatusByName('Contato');
      if (
        choosenAttendence.statusId == contactStatus.id &&
        schedule.statusId == this.getStatusIdByName('Medição')
      ) {
        choosenAttendence.statusId = this.getStatusByPlace(
          contactStatus.place + 1
        ).id;
      } else if (
        choosenAttendence.statusId == this.getStatusIdByName('Aprovado') &&
        schedule.statusId == this.getStatusIdByName('Conferência de medidas')
      ) {
        choosenAttendence.statusId = this.getStatusByPlace(
          this.getStatusByName('Aprovado').place + 1
        ).id;
      }
      await this.editAttendence({ attendence: choosenAttendence });
      await this.createAttendenceHistoric({
        historic: {
          statusId: choosenAttendence.statusId,
          attendenceId: choosenAttendence.id,
          description: `Atendimento foi de ${choosenAttendence.oldDescription} para ${newDescription}`,
          isActive: true,
          userId: window.localStorage.getItem('userId'),
        },
        loggedUser: this.getUser(window.localStorage.getItem('userId')),
      });
      if (
        !choosenAttendence.responsibles.find((el) => el.id == event.user.id)
      ) {
        await this.addAttendenceResponsible({
          responsible: { attendenceId: event.id, userId: event.user.id },
        });
      }
      this.modalSchedule = false;
    },
    hasSchedule(attendence, statusId) {
      const schedule = attendence.schedules.find(
        (el) => el.statusId == statusId
      );
      return schedule;
    },
    makeBoxId(id) {
      return 'box' + id;
    },
    getDaysPastAmount(atte) {
      const ms = Math.abs(new Date() - new Date(atte.startTime));
      return Math.floor(ms / 86400000);
    },
    getDaysUpdatedAt(atte) {
      const ms = Math.abs(new Date() - new Date(atte.updatedAt));
      return Math.floor(ms / 86400000);
    },
    async saveStatus(event) {
      event.forEach(async (item) => {
        await this.editAttendenceItem({ item: item });
        if (item.status == 'R') {
          isAllApproved = false;
        } else if (item.status == 'A') {
          isAllRepproved = false;
        }
      });
      let isAllRepproved = event.every((el) => el.status === 'R');
      let isAllApproved = event.every((el) => el.status === 'A');
      const attendenceId = event[0].attendenceId;
      const attendence = this.getAttendence(attendenceId);
      if (
        isAllApproved &&
        (attendence.statusId == this.getStatusIdByName('Projeto') ||
          attendence.statusId == this.getStatusIdByName('Apresentação') ||
          attendence.statusId == this.getStatusIdByName('Aguardando cliente'))
      ) {
        attendence.startTime = null;
        attendence.endTime = null;
        attendence.statusId = this.getStatusIdByName('Aprovado');
        const atten = await this.editAttendence({ attendence });
      } else if (
        isAllRepproved &&
        (attendence.statusId == this.getStatusIdByName('Apresentação') ||
          attendence.statusId == this.getStatusIdByName('Aguardando cliente'))
      ) {
        attendence.startTime = null;
        attendence.endTime = null;
        attendence.statusId = this.getStatusIdByName('Finalizado');
        const atten = await this.editAttendence({ attendence });
      }
      this.modelDialogStatus = false;
    },
    async saveBudget(content) {
      const portions = content.portionsArray;
      portions.forEach(async (el) => {
        delete el.id;
        await this.createAttendencePayment({ payment: el });
      });

      if (content.responsible.id != this.choosenAttendence.user.id) {
        this.choosenAttendence.userId = content.responsible.id;
        await this.editAttendence({ attendence: this.choosenAttendence });

        if (
          !this.choosenAttendence.responsibles.find(
            (el) => el.id == content.responsible.id
          )
        ) {
          await this.addAttendenceResponsible({
            responsible: {
              attendenceId: this.choosenAttendence.id,
              userId: content.responsible.id,
            },
          });
        }
      }
    },
    async changeManagerToCard(newId, service) {
      if (newId != service.user.id) {
        service.userId = newId;
        await this.editAttendence({ attendence: service });
      }
    },
    scheduleAppointment(id) {
      this.attendenceSelected = this.getAttendence(id);
      this.modalSchedule = true;
    },
    formatDDMMYY(date) {
      date = new Date(date);
      if (date) {
        let d = ('0' + date.getDate()).slice(-2);
        let m = ('0' + date.getMonth()).slice(-2);
        let y = date.getFullYear();
        if (m + 1 == 0) {
          m = 1;
        } else {
          m++;
        }
        let f = ('0' + m).slice(-2);
        return d + '/' + f + '/' + y;
      }
    },
    getItemsSum(attendence) {
      let sum = 0;
      attendence.item.forEach((it) => {
        sum += this.getAttendenceItemPrice(it);
      });
      return sum;
    },
    getAttendencesPriceSum(attendences) {
      let sum = 0;
      attendences.forEach((el) => {
        if (el.item) {
          el.item.forEach((it) => {
            sum += this.getAttendenceItemPrice(it);
          });
        }
      });
      return sum;
    },
    async saveAttendence(array) {
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const d = new Date();
      d.setMonth(d.getMonth() + 2);
      if (array.attendenceInfo.clientId) {
        const userId = window.localStorage.getItem('userId');
        let atteObj = {};
        if (array.clientInfo.hasWhats) {
          let chat = this.contacts.find((el) => el._serialized == array?.chat._serialized);
          if (!chat) {
            this.$socket.client.emit('createNewChat', array.chat._serialized);
            await sleep(2000);
            chat = this.filterContacts.find((el) => el._serialized == array.chat._serialized);
          }
          atteObj = await this.createAttendence({
            attendence: { ...array.attendenceInfo, deliverDate: d, chatId: chat.id },
            user: array.user,
            client: array.clientInfo,
            chat
          });
        } else {
          atteObj = await this.createAttendence({
            attendence: { ...array.attendenceInfo, deliverDate: d, chatId: null },
            user: array.user,
            client: array.clientInfo,
          });
        }
        this.createAttendenceHistoric({
          historic: {
            statusId: 1,
            attendenceId: atteObj.id,
            description: 'Atendimento foi registrado',
            userId,
          },
          loggedUser: this.getUser(userId),
        });
      } else {
        const attendenceObj = array.attendenceInfo;
        const clientInfo = array.clientInfo;
        const clientAddress = clientInfo.clientAddress;
        delete clientInfo.clientAddress;

        try {
          let atteObj = {};
          const userId = window.localStorage.getItem('userId');
          const clientObj = await this.createClient({ client: clientInfo });
          clientAddress['clientId'] = clientObj.id;
          const addressObj = await this.createClientAddress({
            clientAddress: clientAddress,
          });
          attendenceObj['clientId'] = clientObj.id;
          clientObj.clientAddress = addressObj;
          if (array.clientInfo.hasWhats) {
            let chat = this.contacts.find((el) => el._serialized == array.chat._serialized);
            if (!chat) {
              this.$socket.client.emit('createNewChat', array.chat._serialized);
              await sleep(2000);
              chat = this.filterContacts.find((el) => el._serialized == array.chat._serialized);
            }
            atteObj = await this.createAttendence({
              attendence: { ...array.attendenceInfo, chatId: chat.id },
              user: array.user,
              client: clientObj,
              chat
            });
          } else {
            atteObj = await this.createAttendence({
              attendence: { ...array.attendenceInfo },
              user: array.user,
              client: clientObj,
            });
          }
          this.createAttendenceHistoric({
            historic: {
              statusId: 1,
              attendenceId: atteObj.id,
              description: 'Atendimento foi registrado',
              userId,
            },
            loggedUser: this.getUser(userId),
          });
        } catch (error) {
          // console.log(error);
        }
      }
      this.isLoading = false
      this.modelDialogAttendence = false;
    },
    openInfoDialog(item) {
      this.choosenAttendence = this.getAttendence(item.id);
      this.modelDialogInfo = true;
    },
    openBudgetDialog(obj) {
      this.choosenAttendence = obj;
      this.modelDialogBudget = true;
    },
    openChatDialog(obj) {
      this.$socket.client.emit('resetUnreadCount', obj.chat.id);
      this.attendences.map((item) => {
        if (item.id == obj.id) {
          item.chat.unreadCount = 0;
        }
      });
      this.choosenAttendence = obj;
      this.modelDialogChat = true;
    },
    openStatusDialog(i, status) {
      this.selectedObject = this.attendences.find((el) => el.id == i);
      if (this.selectedObject.payment.length > 0) {
        this.statusSelected = status;
        this.modelDialogStatus = true;
      } else {
        this.selectedObject = null;
        this.$notify({
          group: 'error',
          title: 'O pagamento deve ser gerado para acessar "Status"',
          type: 'warn',
          // text: 'Hello user! This is a notification!',
        });
      }
    },
    uploadClick(statusId, attendenceId) {
      this.modelDialogUpload = true;
      this.uploadSelected = statusId;
      this.choosenAttendence = attendenceId;
    },
    closeDialog() {
      this.modalSchedule = false;
      this.selectedClient = null;
      this.modelDialogAttendence = false;
      this.modelDialogUpload = false;
      this.modelDialogBudget = false;
      this.modelDialogStatus = false;
      this.modelDialogInfo = false;
      this.modelDialogChat = false;
    },
    changeEndTime(evt) {
      this.endTime = evt;
    },
    changeStartTime(evt) {
      this.startTime = evt;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    allowDrop(ev) {
      ev.preventDefault();
    },

    drag(ev) {
      if (this.userHasPermission('change_attendence_status')) {
        this.draggedCardId = ev.target.id;
        ev.dataTransfer.setData('text', ev.target.id);
      } else {
        ev.preventDefault();
      }
    },

    drop(ev) {
      if (this.userHasPermission('change_attendence_status')) {
        ev.preventDefault();
        var objId = ev.dataTransfer.getData('text');
        const attendenceObj = this.attendences.find((el) => el.id == objId);
        const oldDescription = this.statuses.find(
          (el) => el.id == attendenceObj.statusId
        ).name;
        let newStatusId = ev.currentTarget.id.replace(/\D/g, '');
        if (!(attendenceObj.statusId == newStatusId)) {
          attendenceObj.startTime = null;
          attendenceObj.endTime = null;
          attendenceObj.statusId = newStatusId;
          this.editAttendenceStatus({ attendence: attendenceObj });
          const newDescription = this.statuses.find(
            (el) => el.id == newStatusId
          ).name;
          const historic = {
            attendenceId: attendenceObj.id,
            statusId: newStatusId,
            description: `Atendimento foi de ${oldDescription} para ${newDescription}`,
            userId: window.localStorage.getItem('userId'),
          };
          this.createAttendenceHistoric({
            historic: historic,
            loggedUser: this.getUser(window.localStorage.getItem('userId')),
          });
          //console.log(this.attendences);
        }
      }
    },
    columnArray(id) {
      let array = this.attendences.filter((el) => el.statusId == id);
      if (this.startTime) {
        array = array.filter((item) => {
          const d1 = new Date(item.updatedAt);
          const d2 = new Date(`${this.startTime}T00:00:00`);
          d1.setHours(1);
          return +d1.getTime() <= +d2.getTime() ? false : true;
        });
      }
      if (this.endTime) {
        array = array.filter((item) => {
          const d1 = new Date(item.updatedAt);
          const d2 = new Date(`${this.endTime}T23:59:59`);
          return d1.getTime() <= d2.getTime() ? true : false;
        });
      }
      if (this.viewPeopleModel) {
        if (!this.viewPeopleModel.includes('Todas as pessoas')) {
          array = array.filter((el) =>
            this.viewPeopleModel.find((i) => i.id == el.user.id)
          );
        }
      }
      return array.filter((el) =>
        el.client.name
          .toLowerCase()
          .includes(this.filterAttendences.toLowerCase())
      );
    },
    getOldAttendence(serviceUser) {
      this.oldUser = serviceUser.name;
    },
    selectNewAttendance(service) {
      let newUserId = service.user.name.id;
      service.user.name = this.oldUser;
      service.userId = this.oldUser.id;
      this.changeManagerToCard(newUserId, service);
    },
  },
  computed: {
    ...mapGetters([
      'getAttendence',
      'getAttendenceItem',
      'getAttendenceStatus',
      'getStatusIdByName',
      'getStatusByName',
      'getStatusByPlace',
    ]),
    ...mapState(['attendences', 'statuses']),
    ...mapStateChat(['selectedContact', 'messages', 'contacts', 'filterContacts']),
    ...mapStateConfig(['userRoles', 'users', 'loggedUser']),
    ...mapGettersConfig(['getUser']),
    allowedStatusesArray() {
      return this.statuses.filter((el) => {
        const permission = 'view_' + el.name;
        return this.userHasPermission(permission) && el.name !== 'Finalizado';
      });
    },
    viewPeople() {
      return ['Todas as pessoas', ...this.users];
    },
  },
  watch: {
    '$route.params.openClient'(newVal, oldVal) {
      if (this.$route.name == 'atendimentos' && this.$route.params.openClient) {
        this.modelDialogAttendence = true;
        this.selectedClient = this.$route.params.openClient;
        this.$route.params.openClient = null;
      }
    },
    startTime() {
      this.startTimeFormatted = this.formatDate(this.startTime);
      if (this.startTime) {
        this.showedArray = this.attendences.filter((item) => {
          const d1 = new Date(item.created_at);
          const d2 = new Date(`${this.startTime}T00:00:00`);
          d1.setHours(1);
          return +d1.getTime() <= +d2.getTime() ? false : true;
        });
        this.filteredStart = true;
      }
    },
    endTime() {
      this.endTimeFormatted = this.formatDate(this.endTime);
      if (this.endTime) {
        this.showedArray = this.attendences.filter((item) => {
          const d1 = new Date(item.created_at);
          const d2 = new Date(`${this.endTime}T23:59:59`);
          return d1.getTime() <= d2.getTime() ? true : false;
        });
      }
    },
  },
  created() {
    this.showedArray = JSON.parse(JSON.stringify(this.attendences));

    if (this.$route.name == 'atendimentos' && this.$route.params.openClient) {
      this.modelDialogAttendence = true;
      this.selectedClient = this.$route.params.openClient;
      this.$route.params.openClient = null;
    }
  },
};
</script>

<style>
.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected>span>span,
.vdatetime-calendar__month__day--selected:hover>span>span {
  background: #177dff;
}

.vdatetime-month-picker__item--selected,
.vdatetime-year-picker__item--selected,
.vdatetime-time-picker__item--selected {
  color: #177dff !important;
}

.vdatetime-popup__actions__button.vdatetime-popup__actions__button {
  color: #177dff !important;
}

.attendences-div .v-select__selections {
  flex-wrap: initial;
}

.sheet-container .calendar-size-upload {
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 6px;
}

.sheet-container .upload-button {
  border-radius: 20px;
  width: 50%;
  justify-self: center;
  margin-left: 8px;
  flex: 1;
}

.sheet-container .calendarIcon {
  transform: scale(0.6);
}

.sheet-container .content-container>div {
  padding: 0px 8px;
}

.sheet-container>div:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}

.sheet-container .content-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
}

.top-bar .v-icon {
  transform: none !important;
}

.sheet-container .double-button-card {
  background-color: #ebeef7;
  border: 1px solid #e4e8f0;
  justify-content: center;
  width: max-content;
  justify-self: center;
  margin-top: 10px;
  background: whitesmoke;
  margin-left: 16px;
  border-radius: 8px;
}

.sheet-container .double-button-card .verticalLine {
  border-left: 1px solid #b7c3da;
  height: 60%;
  left: 50%;
  align-self: center;
}

.sheet-container .double-button-card .single-button {
  border-radius: 0px;
  border: none;
}

.sheet-container .double-button-card .single-button:nth-child(1)>span {
  color: #ffa290;
}

.sheet-container .double-button-card .single-button:nth-child(3)>span {
  color: #5addbe;
}

.sheet-container .double-button-card .single-button>span {
  font-size: 13px;
}

.sheet-container .slim-border {
  border: 1px solid #e4e8f0;
}

.sheet-container .sheet-circle-button {
  margin-left: 4px;
  width: 30px;
  height: 30px;
}

.sheet-container .sheet-circle-button .mdi-currency-usd {
  color: #5addbe;
}

.sheet-container .sheet-circle-button .v-icon {
  font-size: 18px;
}

.sheet-container .card-side-money {
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
}

.sheet-container .sheet-circle-button:first-child {
  margin-left: 16px;
}

.sheet-container .sheet-card-title {
  padding-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.sheet-container .sheet-card-subtitle {
  padding-left: 16px;
  font-size: 12px;
  font-weight: 400;
}

.attendences-div .sheet-container .sheet-pill-button {
  border-radius: 20px;
  max-height: 40px;
  width: 80%;
  justify-self: center;
  align-self: center;
}

.sheet-container .sheet-pill-button .icon-button-text {
  padding-left: 4px;
  font-size: 12px;
}

.sheet-container .sheet-container-content {
  display: grid;
  margin-right: 14px;
  margin-bottom: 16px;
}

.sheet-container .sheet-container-title {
  padding: 0px 8px;
  padding-bottom: 10px;
  padding-top: 14px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.sheet-container .sheet-container-title>span {
  width: fit-content;
  align-self: center;
}

.attendences-div .sheet-container .card-side-line>div {
  height: 85% !important;
  width: 6px !important;
}

.attendences-div .sheet-container .card-side-line {
  width: inherit;
  display: flex;
  align-items: center;
  left: 0px;
}

.attendences-div .sheet-container-header .sheet-line-header {
  width: inherit;
  display: flex;
  justify-content: center;
}

.attendences-div .sheet-container-header .sheet-line-header>div {
  height: 6px !important;
  width: 90% !important;
  top: 0 !important;
}

.attendences-div .sheet-container-header {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 0px;
}

.attendences-div .items-div .sheet-container .sheet-container-card {
  border: 1px solid #e8e8e8;
  box-shadow: none;
  display: grid;
  grid-template-columns: 6px 1fr;
  border-radius: 8px;
  min-width: 100%;
  /* let full size */
  width: max-content;
  margin-bottom: 10px;
}

.attendences-div .sheet-container .attendence-actions {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-top: 6px;
  justify-content: center;
}

.attendences-div .items-div .sheet-container {
  min-width: 250px;
  /* remove to free expansion */
  max-width: 250px;
  min-height: 150px;
  width: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
  height: fit-content;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
}

.sheet-container-title,
.sheet-container-title>div,
.attendences-div .items-div .sheet-container,
.attendences-div .items-div .sheet-container * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.attendences-div .items-div .sheet-container:not(:first-child) {
  margin-left: 10px;
}

.attendences-div {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
}

.attendences-div .top-bar {
  flex: 0 1 auto;
  flex-wrap: wrap;
}

.attendences-div .top-bar .v-input__slot {
  background: white;
  border-radius: 8px;
  height: 40px !important;
}

.attendences-div .top-bar .v-input__slot .v-text-field__slot {
  width: 215px;
}

.attendences-div .items-div {
  flex: 1 1 auto;
  width: max-content;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;
  padding-top: 20px;
  cursor: pointer;
}

.attendences-div .items-div .items-div-inner {
  width: max-content;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
}

.attendences-div .top-bar .datePickerDiv .v-input__control,
.attendences-div .top-bar .datePickerDiv .v-input__slot {
  height: 100%;
  max-width: 150px;
  padding: 0px !important;
  width: 120px;
}

.attendences-div .datePickerDiv input {
  max-height: none;
  height: 100%;
  width: 100%;
  text-align: center;
}

.items-div::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
  height: 12px;
}

.custom-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: white;
  margin-bottom: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;

  border-radius: 30px;
}

.content-container .custom-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.attendences-div .v-input {
  height: 40px;
}

.attendences-div .v-input__slot {
  min-height: 10px !important;
  margin: 0;
  height: 49px;
}

.attendences-div .hasBlackIcon {
  max-width: 250px;
}

.attendences-div .hasBlackIcon .v-icon {
  color: black !important;
}

.attendences-div .titleText {
  font-weight: 500;
  font-size: 26px;
}

.attendences-div .v-input__slot {
  align-items: center;
}

.attendences-div .v-input__prepend-inner,
.attendences-div .v-input__append-inner {
  margin: 0 !important;
  padding: 0 !important;
  align-self: center;
}

.attendences-div .v-text-field--outlined fieldset {
  color: #e8e8e8 !important;
  transition: none !important;
}

/* Shadow Control */
.attendences-div .menuable__content__active {
  border: solid 1px #c9c9c9 !important;
  box-shadow: none !important;
}

.attendences-div .vdatetime {
  border: 1px solid #e8e8e8;
  height: 49px;
  border-radius: 6px;
  width: 150px;
  display: flex;
  align-items: center;
}

.attendences-div .vdatetime-input {
  height: 100%;
  width: 100%;
  padding: 5px;
}

.attendences-div .vdatetime-input:hover {
  cursor: pointer;
}

.attendences-div .top-bar .v-btn {
  height: 40px !important;
  margin-left: 10px !important;
}

.already-approved>span {
  color: #5addbe !important;
}

.responsible .v-input__slot {
  max-width: 150px;
  font-size: 12px;
  height: 20px;
  padding: 0px 10px !important;
}

.responsible .v-input__slot fieldset {
  border: none;
  padding: none;
  height: 10px;
}

.responsible input {
  display: none;
}

.responsible .v-select__selection--comma {
  max-width: 20ch;
  color: #9b9b9b;
  margin: 0px;
}

.responsible {
  margin-left: 7px;
  margin-bottom: 8px;
}

.v-menu__content::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.responsible .v-select__selections {
  max-width: 200px;
  flex: none;
}

.responsible .v-input {
  height: 10px;
}

.v-select.v-input--dense .v-select__selection--comma {
  margin: 5px 0px 3px 0px;
}

.notify-color {
  background-color: #25d366 !important;
  /* box-shadow: 0px 0px 4px #25d366 !important; */
}

.notify-text {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-family: Arial;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
}
</style>
