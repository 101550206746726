<template>
  <v-dialog
    max-width="1000"
    class="mainModal"
    v-model="show"
    @click:outside="closeDialog"
    @keydown.esc.prevent="closeDialog"
    scrollable
  >
    <v-card class="rounded-xl" :loading="loading">
      <v-card-title>
        <v-row>
          <v-col>
            <span>{{ titleDialog }}</span>
          </v-col>
        </v-row>
        <v-btn fab small icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <ValidationObserver ref="observer">
          <v-row>
            <v-col cols="7"
              ><v-card-subtitle class="pl-0 pb-2"
                >Tipo de agendamento</v-card-subtitle
              ><v-select
                outlined
                :disabled="selectedEvent != null"
                :style="{ background: isDisabledStyle }"
                dense
                hide-details
                placeholder="Selecione o tipo de agendamento"
                class="py-0 my-0"
                :append-icon="iconArrow"
                :value="computedAttendenceType"
                @change="changeStatusId"
                :items="statusesFiltered"
                return-object
                item-text="name"
                no-data-text="Nenhuma informação disponível"
              ></v-select>
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  v-model="helperArray.statusId"
                  class="form-control disabled"
                  style="display: none"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col cols="6">

                  <v-card-subtitle class="pl-0 pb-2"
                    >Data e hora inicial</v-card-subtitle
                  >
                  <datetime
                    :minute-step="10"
                    type="datetime"
                    v-model="helperArray.startTime"
                    @input="setEndTimeDefault"
                    :max-datetime="helperArray.endTime"
                    :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                    :week-start="7"
                    auto
                  >
                  </datetime>
                  <ValidationProvider rules="isNotBlank" v-slot="v">
                    <v-text-field
                      v-model="helperArray.startTime"
                      class="form-control disabled"
                      style="display: none"
                    ></v-text-field>
                    <span class="form-error">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">

                  <v-card-subtitle class="pl-0 pb-2"
                    >Data e hora final</v-card-subtitle
                  >
                  <datetime
                    :minute-step="10"
                    type="datetime"
                    v-model="helperArray.endTime"
                    :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                    :week-start="7"
                    auto
                    :min-datetime="helperArray.startTime"
                  >
                  </datetime>
                  <ValidationProvider rules="isNotBlank" v-slot="v">
                    <v-text-field
                      v-model="helperArray.endTime"
                      class="form-control disabled"
                      style="display: none"
                    ></v-text-field>
                    <span class="form-error">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7"

              ><v-card-subtitle class="pl-0 pt-0 mt-0 pb-2"
                >Atendimento</v-card-subtitle
              ><v-autocomplete
                :disabled="selectedEvent != null"
                hide-details
                placeholder="Selecione um atendimento"
                :append-icon="iconAttendence"
                outlined
                dense
                :style="{ background: isDisabledStyle }"
                :filter="filterAttendencesByName"
                no-data-text="Nenhuma informação disponível"
                :items="attendenceArray"
                v-model="helperArray"
                return-object
                item-text="id"
              >
                <template v-slot:[`item`]="{ item }">
                  {{ item.client.name }} -
                  {{ statuses.find((el) => el.id == item.statusId).name }}
                </template>
                <template v-slot:[`selection`]="{ item }">
                  {{ item.client.name }} -
                  {{ statuses.find((el) => el.id == item.statusId).name }}
                </template>
              </v-autocomplete>
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  v-model="helperArray.id"
                  class="form-control disabled"
                  style="display: none"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
            <v-col cols="5">
              <v-card-subtitle
                v-if="userTypeToSelect"
                class="pl-0 pt-0 mt-0 pb-2"
              >
                {{ userTypeToSelect }}*
              </v-card-subtitle>
              <v-card-subtitle v-else class="pl-0 pt-0 mt-0 pb-2"
                >Responsável</v-card-subtitle
              >

              <v-select
                hide-details
                placeholder="Selecione um responsável"
                class="py-0 my-0"
                outlined
                no-data-text="Nenhuma informação disponível"
                dense
                :items="userArray"
                v-model="helperArray.user"
                return-object
                item-text="name"
                :disabled="!userHasPermission('create_attendence_to_others')"
              >
              </v-select>
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  v-model="helperArray.user.name"
                  class="form-control disabled"
                  style="display: none"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-card-subtitle class="pl-0 pt-0 mt-0 pb-2">
                Descrição
              </v-card-subtitle>
              <v-textarea
                rows="4"
                hide-details
                auto-grow
                outlined
                v-model="helperArray.description"
              ></v-textarea>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          class="pl-0"
          @click="openDeleteDialog"
          v-if="selectedEvent && userHasPermission('delete_schedule')"
        >
          <v-icon>la-trash-alt</v-icon>
          <span class="pl-1">Excluir compromisso</span>
        </v-btn>
        <ConfirmationModal
          v-if="modalDelete"
          v-model="modalDelete"
          message="Você quer mesmo deletar este agendamento?"
          @close="modalDelete = false"
          @confirmed="deleteConfirmed"
        />
        <v-spacer></v-spacer>
        <v-btn text color="blue" @click="closeDialog">Cancelar</v-btn>
        <v-btn dark depressed color="blue" @click="createEvent">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import DeleteEventConfirm from './DeleteEventConfirm';
import ConfirmationModal from '../../../components/utils/ConfirmationModal.vue';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { createNamespacedHelpers } from 'vuex';
const { mapState: mapStateAttendence, mapGetters: mapGettersAttendence } =
  createNamespacedHelpers('attendences');
const { mapState: mapStateConfig, mapGetters: mapGettersConfig } =
  createNamespacedHelpers('config');

export default {
  components: {
    //DeleteEventConfirm,
    ConfirmationModal,
    datetime: Datetime,
  },
  props: {
    userTypeToSelect: String,
    newEvent: Object,
    selectedEvent: Object,
    selectedSchedule: Object,
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      hasStartTime: null,
      userArray: [],
      attendenceArray: [],
      modalDelete: false,
      selectedAttendence: '',
      user: null,
      attendenceType: null,
      descricao: '',
      startDate:
        this.selectedEvent != null
          ? JSON.parse(JSON.stringify(this.selectedEvent)).start
          : this.newEvent
          ? JSON.parse(JSON.stringify(this.newEvent)).start
          : '',
      endDate:
        this.selectedEvent != null
          ? JSON.parse(JSON.stringify(this.selectedEvent)).end
          : this.newEvent
          ? JSON.parse(JSON.stringify(this.newEvent)).end
          : '',
      oldDescription: null,
      helperArray: {
        statusId: null,
        startTime: '',
        endTime: '',
        user: {},
        client: {},
      },
    };
  },
  methods: {
    setEndTimeDefault(e) {
      if (e) {
        let date = new Date(e);
        date =
          this.userTypeToSelect && this.userTypeToSelect === 'Montador'
            ? date.setHours(date.getHours() + 2)
            : date.setHours(date.getHours() + 1);
        date = new Date(date);
        this.helperArray.endTime = date.toISOString();
      }
    },
    filterAttendencesByName(item, queryText, itemText) {
      return (
        item.client.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    changeStatusId(evt) {
      this.helperArray.statusId = evt.id;
    },
    attendenceChanged(evt) {
      this.helperArray = JSON.parse(JSON.stringify(evt));
    },
    convertDate(evt) {
      const d = new Date(evt);
      return (
        d.getHours().toString().padStart(2, '0') +
        ':' +
        d.getMinutes().toString().padStart(2, '0')
      );
    },
    async createEvent() {
      this.loading = true;
      const isValid = await this.$refs.observer.validate();
      if (this.$refs.observer._data.flags.valid) {
        if (this.selectedEvent || this.selectedSchedule) {
          this.helperArray.oldDescription = this.oldDescription;
          this.$emit('editEvent', { ...this.helperArray });
        } else {
          this.$emit('createEvent', { ...this.helperArray });
        }
        if (this.$route.params.openAttendence) {
          this.$router.push('/atendimentos');
        }
      } else {
        this.loading = false;
      }
    },
    closeDialog() {
      this.$emit('close', false);
    },
    deleteConfirmed() {
      this.loading = true;
      this.modalDelete = false;
      this.$emit('deleteEventEmit', this.selectedEvent);
    },
    openDeleteDialog() {
      this.modalDelete = true;
    },
    removeFinished(eventsArray) {
      for (let index = eventsArray.length - 1; index >= 0; index--) {
        if (eventsArray[index].statusId == 11) {
          eventsArray.splice(index, 1);
        }
      }
    },
  },
  computed: {
    ...mapStateAttendence(['attendences', 'statuses']),
    ...mapStateConfig(['users']),
    ...mapGettersConfig(['getUser']),
    ...mapGettersAttendence([
      'getAttendence',
      'getAttendenceStatus',
      'getStatusIdByName',
    ]),
    statusesFiltered() {
      return this.statuses.filter(
        (el) =>
          el.name != 'Projeto' &&
          el.name != 'Aprovado' &&
          el.name != 'Aguardando cliente' &&
          el.name != 'Finalizado'
      );
    },
    isDisabledStyle() {
      return this.selectedEvent ? '#f6f6f6' : '#ffffff';
    },
    computedAttendenceType() {
      if (this.statuses && this.helperArray.statusId) {
        if (this.hasStartTime) {
          return this.getAttendenceStatus(this.helperArray.statusId).name;
        } else {
          const i = this.helperArray.statusId;
          return this.getAttendenceStatus(i).name;
        }
      }
      // if (this.selectedSchedule) {
      //   return this.idAttendences[this.selectedSchedule.statusId + 1]
      // }else{
      //   return 1
      // }
    },
    titleDialog() {
      return this.selectedEvent ? 'Editar Compromisso' : 'Novo compromisso';
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    iconAttendence() {
      return !this.selectedEvent ? 'mdi-magnify' : '';
    },
    iconArrow() {
      return !this.selectedEvent ? 'mdi-menu-down' : '';
    },
  },
  mounted() {
    this.helperArray = {
      statusId: null,
      startTime: '',
      endTime: '',
      user: {},
      client: {},
    };
    if (this.selectedSchedule) {

      const selected = { ...this.selectedSchedule };
      const schedule = selected.schedules.find(
        (el) => el.statusId === selected.statusId
      );
      if (this.getStatusIdByName('Contato') === selected.statusId) {
        selected.statusId = this.getStatusIdByName('Medição');
      } else if (this.userTypeToSelect == 'Montador') {
        selected.statusId = this.getStatusIdByName('Montagem');
      }
      
      if (schedule) {
        this.helperArray = {
          ...JSON.parse(JSON.stringify(selected)),
          startTime: schedule.startTime,
          endTime: schedule.endTime,
        };
        
      } else {

        this.helperArray = JSON.parse(JSON.stringify(selected));

      }
    }
    if (this.selectedEvent) {
      const result = this.getAttendence(this.selectedEvent.id);
      delete result.user;
      this.helperArray = {
        ...result,
        user: this.getUser(this.selectedEvent.userId),
        description: this.selectedEvent.description,
        startTime: this.selectedEvent.start,
        endTime: this.selectedEvent.end,
        statusId: this.selectedEvent.statusId,
      };
    } else if (this.newEvent) {
      let d = { ...this.newEvent };
      d.minute = Math.ceil(d.minute / 5) * 5;
      d = new Date(d.year, d.month, d.day, d.hour, d.minute).toISOString();
      this.helperArray.startTime = d;
      this.helperArray.user = !this.userHasPermission(
        'create_attendence_to_others' 
      )
        ? this.getUser(window.localStorage.getItem('userId'))
        : {};
    } else {
      this.helperArray.user = !this.userHasPermission(
        'create_attendence_to_others'
      )
        ? this.getUser(window.localStorage.getItem('userId'))
        : {};
    }
    this.hasStartTime = this.helperArray.startTime;
    this.attendenceArray = JSON.parse(JSON.stringify(this.attendences));

    this.removeFinished(this.attendenceArray);

    this.userArray = this.userTypeToSelect
      ? this.users.filter((el) => el.role.name === this.userTypeToSelect)
      : [...this.users];
  },
};
</script>

<style>
.vdatetime {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}

.vdatetime-input {
  max-width: 100%;
  height: 100%;
  padding-left: 10px;
}

.vdatetime-input:hover {
  cursor: pointer;
}

.v-dialog {
  border-radius: 20px !important;
}
</style>