<template>
  <v-dialog width="90%" persistent v-model="show" @click:outside="closeDialog" scrollable> <!--persistent-->
    <div class="box-message-view">
      <div class="tw-rounded-full">
        <div class="message-section">
          <div class="tw-w-full">
            <div class="header tw-fixed tw-z-10 tw-flex tw-justify-between tw-items-center tw-px-2 tw-py-2">
              <div v-if="isMenuSearchOpened" class="search-menu tw-bg-white tw-fixed tw-border-l-2">
                <div class="header tw-flex tw-items-center tw-gap-4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" elevation="0" class="tw-rounded-full close-btn" color="#f0f0f0"
                        @click="openMenuSearch">
                        <CloseIcon fillColor="#515151" />
                      </v-btn>
                    </template>
                    <span>Fechar Pesquisar</span>
                  </v-tooltip>

                  <div class="search-input tw-px-1 tw-flex tw-items-center tw-rounded-md tw-w-full">
                    <MagnifyIcon fillColor="#179788" :size="18" class="tw-ml-2 tw-w-2" />
                    <input
                      class="tw-mx-5 tw-mr-4 tw-my-1 tw-w-full tw-py-1.5 tw-text-gray-700 tw-leading-tight tw-bg-white"
                      id="newChat" autocomplete="off" v-model="findMessage" type="text"
                      placeholder="Pesquisar na conversa" />
                  </div>
                </div>
                <div class="found-messages tw-overflow-y-scroll">
                  <div v-for="(message) in filterMessages()">
                    <div v-if="findMessage" class="tw-p-6 tw-border-b-2">
                      <div class="tw-font-light tw-text-sm tw-italic">
                        {{ defineHour(message.timestamp) }}
                      </div>
                      <div class="message tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-max-w-[32ch]">
                        {{ message.body }}
                      </div>
                      <!-- <div class="tw-float-right tw-p-0 tw--mt-5">
                        <done-icon v-if="message.status == 'sent'" iconSize="25" iconColor="#5e5e5e" />
                        <double-done-icon v-if="message.status == 'received'" iconSize="25" iconColor="#5e5e5e" />
                        <double-done-icon v-if="message.status == 'viewed'" iconSize="25" iconColor="#53bdeb" />
                      </div> -->
                    </div>
                  </div>
                  <div v-if="!filterMessages().length"
                    class="tw-text-center tw-mt-8 tw-text-gray-400 tw-text-lg tw-font-light">
                    Nenhuma mensagem encontrada
                  </div>
                  <div v-if="!findMessage" class="tw-text-center tw-mt-8 tw-text-gray-400 tw-text-base tw-font-light">
                    Procure uma mensagem na conversa
                  </div>
                </div>
                <div v-if="!findMessage" class="tw-text-center tw-mt-36 tw-text-lg tw-font-light">
                  Pesquise uma mensagem
                </div>
              </div>
              <div class="tw-flex tw-items-center">
                <div v-if="selectedContact?.profilePic">
                  <img class="tw-rounded-full tw-mx-1 tw-w-12" :src="selectedContact?.profilePic" />
                </div>
                <div v-else-if="!selectedContact?.profilePic
                  " class="tw-rounded-full tw-p-2 tw-bg-gray-400 tw-mr-2">
                  <contact-icon iconSize="32" iconColor="#000000" />
                </div>
                <div class="tw-text-gray-900 tw-ml-1 tw-font-semibold tw-mr-2">
                  <div class="name" v-if="selectedContact?.name">
                    {{ selectedContact.name }}
                  </div>
                  <div class="name" v-else-if="selectedContact?.pushname">{{ selectedContact?.pushname }}</div>
                  <div class="name" v-else>{{ formattedNumber(selectedContact._serialized) }}</div>
                </div>
                <div class="tw-flex tw-justify-center tw-items-center">
                  <div v-for="tag in tags">
                    <v-tooltip bottom v-if="tag.id == selectedContact.tagId">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="tag.id == selectedContact.tagId" v-on="on" v-bind="attrs">
                          <label-icon iconSize="30" :iconColor="tag.color" />
                        </div>
                      </template>
                      <span>{{ tag.name }}</span>
                    </v-tooltip>
                  </div>
                  <div v-for="dep in departments">
                    <v-tooltip bottom v-if="dep.id == selectedContact.currentDepartmentId">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="dep.id == selectedContact.currentDepartmentId" v-on="on" v-bind="attrs"
                          class="tw-bg-gray-200 tw-p-1 tw-rounded-full tw-ml-2">
                          <departament-icon iconColor="#25d366" iconSize="22px" />
                        </div>
                      </template>
                      <span>{{ dep.name }}</span>
                    </v-tooltip>
                  </div>
                </div>

              </div>
              <div class="tw-flex tw-gap-2 tw-mr-4 tw-items-center tw-ml-10">
                <!-- <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="#f0f0f0" elevation="0" v-on="on" v-bind="attrs">
                        <print-icon />
                      </v-btn>
                    </template>
                    <span>Imprimir Conversa</span>
                  </v-tooltip>
                </div> -->
                <div v-if="!isMenuSearchOpened">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="#f0f0f0" elevation="0" v-on="on" v-bind="attrs" @click="openMenuSearch">
                        <MagnifyIcon fillColor="#179788" />
                      </v-btn>
                    </template>
                    <span v-if="!isMenuSearchOpened">
                      Pesquisar na Conversa
                    </span>
                  </v-tooltip>
                </div>

                <div v-if="!isMenuSearchOpened">
                  <v-menu origin="center center" transition="scale-transition" :close-on-content-click="false"
                    :offset-y="offset" v-model="isMenuTranferOpened">
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn color="#f0f0f0" elevation="0" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                            <tranfer-arrows-icon fillColor="#515151" />
                          </v-btn>
                        </template>
                        <span>Transferir Conversa</span>
                      </v-tooltip>
                    </template>
                    <v-list class="transfer-menu">
                      <v-list-item class="tw-block tw-bg-white">
                        <div class="tw-w-full">
                          <div class="text-green tw-font-semibold tw-text-center">
                            Transferir para departamento
                          </div>
                        </div>

                        <div class="tw-border-b-2 tw-py-4 tw-px-2">
                          <input id="areaTextTransfer"
                            class="search-input tw-w-full tw-px-4 tw-py-2  tw-text-gray-700 tw-leading-tight tw-rounded-full"
                            autocomplete="off" type="text" v-model="findDepartment"
                            placeholder="Pesquisar departamento" />
                        </div>

                        <v-list v-if="filterDepartments().length != 0" class="custom-scroll">
                          <div v-for="department in filterDepartments()" v-if="department.status"
                            @click="tranferToDepartment(department)"
                            class="tw-text-sm hoverOption tw-p-3 tw-cursor-pointer">
                            {{ department.name }}
                          </div>
                        </v-list>
                        <div v-else class="tw-text-center tw-text-gray-400 tw-text-lg tw-font-light">
                          Nenhum departamento encontrado
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <div v-if="!isMenuSearchOpened">
                  <v-menu origin="center center" transition="slide-y-transition" :close-on-content-click="false"
                    :offset-y="offset" v-model="isMenuTagsOpened">
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn color="#f0f0f0" elevation="0" v-on="{ ...tooltip, ...menu }" v-bind="attrs">
                            <tag-icon iconColor="#179788" iconSize="28px" />
                          </v-btn>
                        </template>
                        <span>Tags</span>
                      </v-tooltip>
                    </template>
                    <v-list>
                      <v-list-item>
                        <div class="tw-py-4 tw-cursor-pointer">
                          <input id="areaTextTransfer"
                            class="search-input tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-leading-tight"
                            autocomplete="off" type="text" v-model="findTag" placeholder="Pesquisar etiqueta" />
                          <div class="text-green tw-font-semibold tw-items-center tw-py-4 tw-border-b-2 tw-text-center">
                            Vincular etiqueta(s)
                          </div>
                          <div v-if="tag.status" @click="changeTag(tag)" v-for="tag in filterTags()">
                            <div class="tw-py-2 tw-px-2 tw-flex tw-items-center tagsList">
                              {{ tag.name }}
                              <label-icon :iconColor="tag.color" iconSize="18px" class="tag-position" />
                            </div>
                          </div>
                          <div v-if="selectedContact.tagId && !findTag" @click="changeTag(null)"
                            class="tw-py-2 tw-px-2 tw-flex tw-items-center tagsList tw-border-t tw-mt-2 tw--mb-3">
                            Remover Etiqueta
                            <closeIcon fillColor="#515151" class="tag-position" />
                          </div>
                          <div v-if="!filterTags().length"
                            class="tw-text-center tw-mt-8 tw-text-gray-400 tw-text-base tw-font-light">
                            Nenhuma etiqueta encontrada
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <div v-if="!isMenuSearchOpened">
                  <v-menu transition="slide-y-transition" :close-on-content-click="false" :offset-y="offset"
                    v-model="isMenuFinishedOpened">
                    <div></div>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <div v-on="{ ...tooltip }" v-bind="attrs">
                            <v-btn :disabled="selectedContact.isInService == false" color="#f0f0f0" elevation="0"
                              v-on="{ ...menu }">
                              <check-circle-icon iconColor="#179788" :iconSize="30" />
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ selectedContact?.isInService == true ? 'Finalizar Conversa' : 'Não está em atendimento'
                        }}</span>
                      </v-tooltip>
                    </template>
                    <div class="finished-menu tw-bg-white tw-py-2 tw-px-4">
                      <div class="tw-font-semibold tw-block tw-pt-2 tw-text-center text-green">
                        Motivo de finalizar o atendimento
                      </div>
                      <v-list v-if="finishedChatReasons.length != 0">
                        <v-list-item v-for="motive in finishedChatReasons" v-if="motive.status"
                          @click="finishedChat(selectedContact, motive)" class="tw-text-sm hoverOption">
                          {{ motive.name }}
                        </v-list-item>
                      </v-list>
                      <div v-else class="tw-text-center tw-text-gray-400 tw-text-base tw-font-light">
                        Nenhuma motivo de finalização encontrado
                      </div>
                    </div>
                  </v-menu>
                </div>

                <div v-if="btnCloseModal" class="tw-pl-4 tw-border-l-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="closeDialog" elevation="0" color="#f0f0f0" v-bind="attrs" v-on="on"
                        class="close-btn tw-rounded-full">
                        <CloseIcon fillColor="#515151" />
                      </v-btn>
                    </template>
                    <span>Fechar Diálogo</span>
                  </v-tooltip>
                </div>
                <div v-if="isMenuSearchOpened" class="search-menu-opened tw-h-full"></div>
              </div>
            </div>
          </div>
          <div id="MessagesSection" class="tw-pt-20 tw-pb-4 tw-z-0 tw-overflow-auto tw-fixed custom-scroll">
            <div class="tw-px-20 tw-text-sm" v-for="(message, index) in currentMessages">

              <!--MENSAGE TIPO DATA-->
              <div class="tw-w-full tw-flex tw-justify-center tw-items-center" v-if="message.type == 'msgDate'">
                <div class="msgDate-class tw-my-2 tw-p-2 tw-bg-white tw-rounded-lg">
                  {{ message.body }}
                </div>
              </div>
              <div v-else>
                <!--mensagem RECEBIDA-->
                <div class="tw-flex tw-items-center">
                  <div @mouseover="activeQuotedMsg(index, true)" @mouseleave="activeQuotedMsg(-1, true)"
                    v-if="hoverMsgIndex == index && !message.fromMe" class="tw-p-2 tw-cursor-pointer">
                    <div @click="defineQuotadeMsg(message)" class="replyIcon-class">
                      <arrow-reply-msg-icon iconColor="#ffffff" iconSize="18px" />
                    </div>
                  </div>
                  <div v-if="!message.fromMe" @mouseover="activeQuotedMsg(index)" @mouseleave="activeQuotedMsg(-1, true)"
                    class="message-size">
                    <div v-if="message.type != 'image' && message.type != 'sticker' && message.type != 'document'"
                      class="tw-inline-block tw-bg-white tw-p-2 tw-rounded-md tw-my-1">
                      <div class="tw-flex">
                        <div class="tw-pr-3">
                          <div v-if="message.type == 'chat'">
                            <div class="bgQuotedMsgEnv quotedMsgNotFromMe tw-p-2 tw-w-full tw-rounded-md"
                              v-if="message.hasQuotedMsg && !message.theseQuotedMsg?.fromMe">
                              <div class="tw-font-bold">
                                <div class="quotedName-class" v-if="selectedContact.name">
                                  {{ selectedContact.name }}:
                                </div>
                                <div class="quotedName-class" v-else-if="selectedContact.pushname">{{
                                  selectedContact.pushname
                                }}:</div>
                                <div class="quotedName-class" v-else>{{ formattedNumber(selectedContact._serialized) }}:
                                </div>
                              </div>
                              <div class="bgQuotedMsgSize tw-text-gray-800">
                                {{ message.theseQuotedMsg?.body }}
                              </div>
                            </div>
                            <div class="bgQuotedMsgEnv quotedMsgFromMe tw-p-2 tw-w-full tw-rounded-md"
                              v-if="message.hasQuotedMsg && message.theseQuotedMsg?.fromMe">
                              <div class="tw-font-bold">
                                Você:
                              </div>
                              <div v-if="message.theseQuotedMsg?.type == 'chat'" class="tw-text-gray-800 bgQuotedMsgSize">
                                {{ message.theseQuotedMsg?.body }}
                              </div>
                              <div v-if="message.theseQuotedMsg?.type == 'image'"
                                class="tw-flex tw-justify-center tw-items-center">
                                <div>
                                  <image-icon iconSize="24px" iconColor="#1c5cff" />
                                </div>
                                <div class="quotedImage-class">Imagem</div>
                              </div>
                              <div v-if="message.theseQuotedMsg?.type == 'audio' || message.theseQuotedMsg?.type == 'ptt'"
                                class="tw-flex tw-justify-center tw-items-center">
                                <div>
                                  <mic-icon iconSize="22px" iconColor="#105e55" />
                                </div>
                                <div class="quotedAudio-class">Áudio</div>
                              </div>
                              <div v-if="message.theseQuotedMsg?.type == 'sticker'"
                                class="tw-flex tw-justify-center tw-items-center">
                                <div>
                                  <sticker-icon iconSize="24px" iconColor="#4fc3f7" />
                                </div>
                                <div class="quotedSticker-class">Figurinha</div>
                              </div>
                              <div v-if="message.theseQuotedMsg?.type == 'document'"
                                class="tw-flex tw-justify-center tw-items-center">
                                <div>
                                  <document-icon iconSize="24px" iconColor="#be5ee0" />
                                </div>
                                <div class="quotedDocument-class">Documento</div>
                              </div>
                            </div>
                            <div v-if="isLink(message.body)" class="text-link msg-size">
                              <a target="_blank" :href="message.body">{{ message.body }}</a>
                            </div>
                            <div class="msg-size" v-else>
                              {{ message.body }}
                            </div>
                          </div>
                          <div v-else-if="message.type == 'ptt' || message.type == 'audio'">
                            <audio controls :src="base64PrefixAudio + message.dataMedia">
                            </audio>
                          </div>
                        </div>
                        <div id="hourMessage" class="tw-flex tw-items-end tw--mb-1">
                          <div class="tw-text-xs tw-font-light tw-pr-1">
                            {{ defineHour(message.timestamp) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="message.type == 'sticker'">
                      <div class="sticker-class tw-flex tw-flex-col tw-mt-2 tw-mb-4">
                        <div class="tw-pr-3">
                          <div>
                            <img class="tw-w-full tw-h-full tw-pb-1" :src="base64PrefixJpeg + message.dataMedia"
                              alt="Image">
                          </div>
                        </div>
                        <div class="tw-flex tw-ml-auto">
                          <div class="sticker-hour-class tw-text-xs tw-font-light">
                            {{ defineHour(message.timestamp) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tw-flex" v-else-if="message.type == 'image'">
                      <div class="imageComponent">
                        <div @click="openImageDialog(base64PrefixJpeg + message.dataMedia)">
                          <img class="image-class" :src="base64PrefixJpeg + message.dataMedia" alt="Image">
                          <div v-if="message.body" class="tw-bg-white tw-py-1">
                            {{ message.body }}
                          </div>
                        </div>
                        <div class="image-hour-class tw-w-8 tw-mt-auto tw--ml-8">
                          {{ defineHour(message.timestamp) }}
                        </div>
                      </div>
                      <a :href="checkTypeBase64Img(message)" download class="arrowDownloadImg">
                        <div class="downloadImage-class">
                          <file-download-icon iconSize="30px" iconColor="#ffffff" />
                        </div>
                      </a>
                    </div>
                    <div v-else-if="message.type == 'document'" class="tw-rounded-md tw-mt-1 tw-w-64">
                      <div
                        class="tw-flex tw-justify-center tw-items-center tw-bg-white tw-rounded-md tw-w-64 tw-px-2 tw-h-24">
                        <a :href="prefixBase64(message)" download
                          class="tw-cursor-pointer tw-flex tw-items-center tw--mt-4 tw-px-4 tw-justify-between tw-rounded-t-md tw-bg-gray-100 tw-w-60 tw-h-16">
                          <div class="tw-flex tw-items-center tw-gap-2 tw-justify-center">
                            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
                              <div v-if="message.mediaType == 'pdf'">
                                <document-icon iconColor="#ff0000" iconSize="36px" />
                                <div class="type-default pdf-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else-if="message.mediaType == 'docx'">
                                <document-icon iconColor="#0000ff" iconSize="36px" />
                                <div class="type-default docx-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else-if="message.mediaType == 'xlsx'">
                                <document-icon iconColor="#008500" iconSize="36px" />
                                <div class="type-default xlsx-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else-if="message.mediaType == 'rar'">
                                <document-icon iconColor="#9e00ce" iconSize="36px" />
                                <div class="type-default rar-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else>
                                <document-icon iconColor="#606060" iconSize="36px" />
                                <div class="type-default">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                            </div>
                            <div class="tw-flex tw-flex-col tw-justify-center">
                              <div class="document-name">
                                {{ message.body }}
                              </div>
                              <div class="document-size">
                                {{ message.mediaSize }}
                              </div>
                            </div>
                          </div>
                          <div>
                            <file-download-icon iconColor="#909090" iconSize="28px" />
                          </div>
                        </a>
                      </div>
                      <div class="tw-flex tw-w-9 tw-ml-auto tw--mt-6">
                        <div class="sticker-hour-class tw-text-xs tw-font-light">
                          {{ defineHour(message.timestamp) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <!--fim mensagem RECEBIDA-->

                <!--MENSAGEM ENVIADA-->
                <div class="message-size tw-justify-end tw-ml-36 tw-flex tw-items-center">
                  <div v-if="message.fromMe" @mouseover="activeQuotedMsg(index)" @mouseleave="activeQuotedMsg(-1, true)"
                    class=" tw-flex">
                    <div v-if="message.type != 'image' && message.type != 'sticker' && message.type != 'document'"
                      class="tw-bg-green-200 tw-p-2 tw-rounded-md tw-my-1 tw-flex">
                      <div class="tw-pr-3">

                        <div v-if="message.type == 'chat'">
                          <div class="bgQuotedMsgEnvFromMe quotedMsgNotFromMe tw-p-2 tw-w-full tw-rounded-md"
                            v-if="message.hasQuotedMsg && !message.theseQuotedMsg?.fromMe">
                            <div class="tw-font-bold">
                              <div class="quotedName-class" v-if="selectedContact.name">
                                {{ selectedContact.name }}:
                              </div>
                              <div class="quotedName-class" v-else-if="selectedContact.pushname">{{
                                selectedContact.pushname
                              }}:</div>
                              <div class="quotedName-class" v-else>{{ formattedNumber(selectedContact._serialized) }}:
                              </div>
                            </div>
                            <div v-if="message.theseQuotedMsg?.type == 'chat'" class="tw-text-gray-800 bgQuotedMsgSize">
                              {{ message.theseQuotedMsg?.body }}
                            </div>
                            <div v-else-if="message.theseQuotedMsg?.type == 'image'"
                              class="tw-flex tw-justify-start tw-items-center">
                              <div>
                                <image-icon iconSize="24px" iconColor="#1c5cff" />
                              </div>
                              <div class="quotedImage-class">Imagem</div>
                            </div>
                            <div
                              v-else-if="message.theseQuotedMsg?.type == 'audio' || message.theseQuotedMsg?.type == 'ptt'"
                              class="tw-flex tw-justify-start tw-p-1 tw-items-center">
                              <div>
                                <mic-icon iconSize="22px" iconColor="#105e55" />
                              </div>
                              <div class="quotedAudio-class">Áudio</div>
                            </div>
                            <div v-else-if="message.theseQuotedMsg?.type == 'sticker'"
                              class="tw-flex tw-justify-start tw-p-1 tw-items-center">
                              <div>
                                <sticker-icon iconSize="24px" iconColor="#4fc3f7" />
                              </div>
                              <div class="quotedSticker-class">Figurinha</div>
                            </div>
                            <div v-else-if="message.theseQuotedMsg?.type == 'document'"
                              class="tw-flex tw-justify-start tw-p-1 tw-items-center">
                              <div>
                                <document-icon iconSize="24px" iconColor="#be5ee0" />
                              </div>
                              <div class="quotedDocument-class">Documento</div>
                            </div>
                          </div>
                          <div class="bgQuotedMsgEnvFromMe quotedMsgFromMe tw-p-2 tw-w-full tw-rounded-md"
                            v-if="message.hasQuotedMsg && message.theseQuotedMsg?.fromMe">
                            <div class="tw-font-bold">
                              Você:
                            </div>
                            <div v-if="message.theseQuotedMsg?.type == 'chat'" class="tw-text-gray-800 bgQuotedMsgSize">
                              {{ message.theseQuotedMsg?.body }}
                            </div>
                            <div v-else-if="message.theseQuotedMsg?.type == 'image'"
                              class="tw-flex tw-justify-start tw-p-1 tw-items-center">
                              <div>
                                <image-icon iconSize="24px" iconColor="#1c5cff" />
                              </div>
                              <div class="quotedImage-class">Imagem</div>
                            </div>
                            <div
                              v-else-if="message.theseQuotedMsg?.type == 'audio' || message.theseQuotedMsg?.type == 'ptt'"
                              class="tw-flex tw-justify-start tw-p-1 tw-items-center">
                              <div>
                                <mic-icon iconSize="22px" iconColor="#105e55" />
                              </div>
                              <div class="quotedAudio-class">Áudio</div>
                            </div>
                            <div v-else-if="message.theseQuotedMsg?.type == 'sticker'"
                              class="tw-flex tw-justify-start tw-p-1 tw-items-center">
                              <div>
                                <sticker-icon iconSize="24px" iconColor="#4fc3f7" />
                              </div>
                              <div class="quotedSticker-class">Figurinha</div>
                            </div>
                            <div v-else-if="message.theseQuotedMsg?.type == 'document'"
                              class="tw-flex tw-justify-start tw-p-1 tw-items-center">
                              <div>
                                <document-icon iconSize="24px" iconColor="#be5ee0" />
                              </div>
                              <div class="quotedDocument-class">Documento</div>
                            </div>
                          </div>
                          <div v-if="isLink(message.body)" class="text-link msg-size">
                            <a target="_blank" :href="message.body">{{ message.body }}</a>
                          </div>
                          <div class="msg-size" v-else>
                            {{ message.body }}
                          </div>
                        </div>

                      </div>
                      <div id="hourMessage" class="tw-flex tw-items-end tw--mb-1">
                        <div class="tw-text-xs tw-font-light tw-pr-1">
                          {{ defineHour(message.timestamp) }}
                        </div>
                      </div>
                    </div>
                    <div v-else-if="message.type == 'image' && message.fromMe"
                      class="tw-flex tw-justify-center tw-items-center">
                      <a :href="checkTypeBase64Img(message)" download class="arrowDownloadImg">
                        <div class="downloadImage-class">
                          <file-download-icon iconSize="30px" iconColor="#ffffff" />
                        </div>
                      </a>
                      <div class="imageComponent tw-border-green-200">
                        <div>
                          <a @click="openImageDialog(base64PrefixJpeg + message.dataMedia)"
                            :href="base64PrefixJpeg + message.dataMedia">
                            <img v-if="message.mediaType == 'png'" class="image-class tw-bg-white"
                              :src="base64PrefixJpeg + message.dataMedia" alt="Image">
                            <img v-else class="image-class" :src="base64PrefixJpeg + message.dataMedia" alt="Image">
                          </a>
                          <div class="image-hour-class tw-w-8">
                            {{ defineHour(message.timestamp) }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="message.type == 'sticker'">
                      <div class="sticker-class tw-flex tw-flex-col tw-mt-2 tw-mb-4">
                        <div class="tw-pr-3">
                          <div>
                            <img class="tw-w-full tw-h-full tw-pb-1" :src="base64PrefixJpeg + message.dataMedia"
                              alt="Image">
                          </div>
                        </div>
                        <div class="tw-flex tw-ml-auto">
                          <div class="sticker-hour-class tw-bg-green-200 tw-text-xs tw-font-light">
                            {{ defineHour(message.timestamp) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="message.type == 'document'" class=" tw-bg-green-200 tw-rounded-md tw-mt-1">
                      <div
                        class="tw-flex tw-justify-center tw-items-center tw-bg-green-200 tw-rounded-md tw-w-64 tw-px-2 tw-h-20">
                        <a :href="prefixBase64(message)" download
                          class="tw-cursor-pointer tw-flex tw-items-center tw-px-4 tw-justify-between tw-rounded-t-md tw-bg-green-100 tw-w-60 tw-h-16">
                          <div class="tw-flex tw-items-center tw-gap-2 tw-justify-center">
                            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
                              <div v-if="message.mediaType == 'pdf'">
                                <document-icon iconColor="#ff0000" iconSize="36px" />
                                <div class="type-default pdf-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else-if="message.mediaType == 'docx'">
                                <document-icon iconColor="#0000ff" iconSize="36px" />
                                <div class="type-default docx-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else-if="message.mediaType == 'xlsx'">
                                <document-icon iconColor="#008500" iconSize="36px" />
                                <div class="type-default xlsx-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else-if="message.mediaType == 'rar'">
                                <document-icon iconColor="#9e00ce" iconSize="36px" />
                                <div class="type-default rar-type">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                              <div v-else>
                                <document-icon iconColor="#606060" iconSize="36px" />
                                <div class="type-default">
                                  {{ message.mediaType }}
                                </div>
                              </div>
                            </div>
                            <div class="tw-flex tw-flex-col tw-justify-center">
                              <div class="document-name">
                                {{ message.body }}
                              </div>
                              <div class="document-size">
                                {{ message.mediaSize }}
                              </div>
                            </div>
                          </div>
                          <div>
                            <file-download-icon iconColor="#909090" iconSize="28px" />
                          </div>
                        </a>
                      </div>
                      <div class="tw-flex tw-w-10 tw-ml-auto tw--mt-2">
                        <div class="sticker-hour-class tw-bg-green-200 tw-text-xs tw-font-light">
                          {{ defineHour(message.timestamp) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @mouseover="activeQuotedMsg(index, true)" @mouseleave="activeQuotedMsg(-1, true)"
                    v-if="hoverMsgIndex == index && message.fromMe" class="tw-p-2 tw-cursor-pointer">
                    <div @click="defineQuotadeMsg(message)" class="replyIcon-class">
                      <arrow-reply-msg-icon iconColor="#ffffff" iconSize="18px" />
                    </div>
                  </div>
                </div>
                <!--fim mensagem ENVIADA-->
              </div>

            </div>
          </div>


          <div class="inputMessage tw-z-10 tw-fixed tw-bottom-0">
            <div v-if="quotedMsg" class="tw-py-3 tw-px-12 tw-flex  tw-justify-center tw-gap-4 tw-items-center">
              <div v-if="!quotedMsg.fromMe"
                class="quotedBorder-class tw-bg-gray-300 tw-gap-2 tw-w-full tw-h-full tw-py-2 tw-px-6 tw-rounded-md tw-flex tw-flex-col tw-justify-center">
                <div>
                  <div class="quotedName-class" v-if="selectedContact.name">
                    {{ selectedContact.name }}:
                  </div>
                  <div class="quotedName-class" v-else-if="selectedContact.pushname">{{ selectedContact.pushname }}:</div>
                  <div class="quotedName-class" v-else>{{ formattedNumber(selectedContact._serialized) }}:</div>
                </div>
                <div v-if="quotedMsg.type == 'chat'" class="quotedMessage-class">
                  {{ quotedMsg.body }}
                </div>
                <div v-if="quotedMsg.type == 'ptt' || quotedMsg.type == 'audio'"
                  class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <mic-icon iconSize="22px" iconColor="#105e55" /> <span class="quotedAudio-class">Áudio</span>
                </div>
                <div v-else-if="quotedMsg.type == 'image'" class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <image-icon iconSize="24px" iconColor="#1c5cff" /> <span class="quotedImage-class">Imagem</span>
                </div>
                <div v-else-if="quotedMsg.type == 'document'"
                  class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <document-icon iconSize="24px" iconColor="#be5ee0" /><span class="quotedDocument-class">Documento</span>
                </div>
                <div v-else-if="quotedMsg.type == 'sticker'" class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <sticker-icon iconSize="24px" iconColor="#4fc3f7" /> <span class="quotedSticker-class">Figurinha</span>
                </div>
              </div>

              <div v-else
                class="quotedBorder-class quotedYouBorder-class tw-bg-gray-300 tw-gap-2 tw-w-full tw-h-full tw-py-2 tw-px-6 tw-rounded-md tw-flex tw-flex-col tw-justify-center">
                <div>
                  <div class="quotedName-class quotedYou-class">Você:</div>
                </div>
                <div v-if="quotedMsg.type == 'chat'" class="quotedMessage-class">
                  {{ quotedMsg.body }}
                </div>
                <div v-if="quotedMsg.type == 'ptt' || quotedMsg.type == 'audio'"
                  class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <mic-icon iconSize="22px" iconColor="#105e55" /> <span class="quotedAudio-class">Áudio</span>
                </div>
                <div v-else-if="quotedMsg.type == 'image'" class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <image-icon iconSize="24px" iconColor="#1c5cff" /> <span class="quotedImage-class">Imagem</span>
                </div>
                <div v-else-if="quotedMsg.type == 'document'"
                  class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <document-icon iconSize="24px" iconColor="#be5ee0" /><span class="quotedDocument-class">Documento</span>
                </div>
                <div v-else-if="quotedMsg.type == 'sticker'" class="quotedMessage-class tw-flex tw-items-center tw-gap-2">
                  <sticker-icon iconSize="24px" iconColor="#4fc3f7" /> <span class="quotedSticker-class">Figurinha</span>
                </div>
              </div>

              <v-btn @click="quotedMsg = null" elevation="0" class="tw-rounded-full close-btn" color="#f0f0f0">
                <CloseIcon fillColor="#515151" />
              </v-btn>
            </div>
            <template v-if="isActive">
              <VEmojiPicker emojiSize="28" emojisByRow="10" emojiWithBorder="false" :showSearch="false"
                :exceptCategories="exceptCategoriesArr" @select="selectEmoji" i18n="i18n"
                class="inputMenuEmoji tw-w-full tw-m-0" />
            </template>
            <div class="tw-flex tw-items-center tw-justify-center tw-py-2">
              <v-tooltip top v-if="!sendImage">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1" elevation="0" color="#f0f0f0" icon v-bind="attrs" v-on="on" small
                    @click="activeEmoji">
                    <EmoticonExcitedOutlineIcon id="icon-openEmojiMenu" :size="27" fillColor="#179788"
                      class="tw-mx-1.5" />
                    <CloseIcon :size="27" fillColor="#515151" class="tw-mx-1.5 tw--ml-7 tw-hidden emojiClose"
                      v-if="isActive" />
                  </v-btn>
                </template>
                <span v-if="!isActive">Emojis</span>
                <span v-if="isActive">Fechar Emojis</span>
              </v-tooltip>

              <v-menu v-if="!sendImage" top v-model="isMenuAnexOpened" transition="slide-y-transition" :offset-y="offset">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-btn id="btn-openAnexMenu" icon elevation="0" color="#f0f0f0" v-on="{ ...menu }" v-bind="attrs">
                    <add-icon iconColor="#179788" iconSize="28px" />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(option, index) in options" :key="index" class="tw-items-center anexMenu">

                    <label v-if="option.id == 0" class="tw-flex tw-items-center tw-py-4 tw-w-40" for="fileInputDocuments">
                      <input @change="sendImagesDocuments" type="file" id="fileInputDocuments" class="fileInput" />
                      <image-icon class="tw-mr-4" iconSize="24px" iconColor="#1c5cff" />
                      {{ option.name }}
                    </label>

                    <div v-if="option.id == 1" class="tw-flex tw-items-center tw-py-4 tw-w-40" @click="openChatDialog">
                      <contact-share-icon class="tw-mr-4" af iconSize="24px" />
                      {{ option.name }}
                    </div>

                    <label v-if="option.id == 2" class="tw-flex tw-items-center tw-py-4 tw-w-40" for="fileInputDocuments">
                      <input @change="sendImagesDocuments" type="file" id="fileInputDocuments" class="fileInput" />
                      <document-icon class="tw-mr-4" iconSize="24px" iconColor="#be5ee0" />
                      {{ option.name }}
                    </label>
                  </v-list-item>
                </v-list>
              </v-menu>

              <input v-if="!sendImage" v-model="sentMessage"
                class="search-input tw-mr-1 tw-shadow tw-rounded-full tw-w-full tw-py-3 tw-px-4 tw-text-gray-700 tw-leading-tight"
                id="areaMessage" autocomplete="off" type="text" placeholder="Digite sua mensagem..." />
              <div v-else class="sendImage-class tw-w-full tw-text-center tw--mb-4 tw-ml-2">
                <v-file-input dense :clearable="false" flat solo class="tw-mt-2" prepend-icon="mdi-image"
                  v-model="sentMessage" color="#179788" label="Enviar imagens"></v-file-input>
              </div>
              <v-btn v-if="sendImage" class="tw-mx-2 tw-p-2 tw-w-12 tw-flex tw-items-center tw-justify-center"
                elevation="0" color="#f0f0f0" @click="cancelSendImage()">
                <closeIcon fillColor="#515151" />
              </v-btn>
              <v-btn class="tw-mx-2 tw-p-2 tw-w-12 tw-flex tw-items-center tw-justify-center" elevation="0"
                color="#f0f0f0" @click="sendNewMessage()">
                <SendIcon fillColor="#179788" />
              </v-btn>
            </div>
          </div>

          <shareContactDialog v-if="modelDialogShareContact" @close="closeOthersDialogs"
            v-model="modelDialogShareContact">
          </shareContactDialog>
          <ConfimFinishedChatting v-if="modelDialogFinishedChatting" :selectedFinishedReason="selectedFinishedReason"
            :selectedChat="selectedContact" @close="closeOthersDialogs" v-model="modelDialogFinishedChatting">
          </ConfimFinishedChatting>
          <ConfirmTrasnferToDepartment v-if="modelDialogTranferDepartment" @close="closeOthersDialogs"
            v-model="modelDialogTranferDepartment" :selectedChat="selectedContact"
            :selectedDepartment="selectedDepartmentToTranfer">
          </ConfirmTrasnferToDepartment>
          <OpenImageModal v-if="modelDialogOpenImage" v-model="modelDialogOpenImage" @close="closeOthersDialogs"
            :selectedImage="selectedImage64"></OpenImageModal>
          <NotificationFailSentMsgQuoted v-if="!this.quotedMsgSent" v-model="!this.quotedMsgSent"
            @close="closeOthersDialogs">
          </NotificationFailSentMsgQuoted>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import shareContactDialog from './../../Chat/modals/shareContatcDialog.vue';
import ConfimFinishedChatting from './../../Chat/modals/ConfirmFinishedChatting.vue';
import ConfirmTrasnferToDepartment from './../../Chat/modals/ConfirmTrasnferToDepartment.vue';
import OpenImageModal from './../../Chat/modals/OpenImageModal.vue';
import NotificationFailSentMsgQuoted from './../../Chat/modals/NotificationFailSentMsgQuoted.vue'

import { VEmojiPicker } from 'v-emoji-picker';
import { createNamespacedHelpers } from 'vuex';
const { mapState: mapStateConfig, } = createNamespacedHelpers('config');
const { mapState: mapStateChat } = createNamespacedHelpers('chat');
const { mapActions: mapActionsChat } = createNamespacedHelpers('chat');
const { mapActions: mapActionsAttendences } =
  createNamespacedHelpers('attendences');
import moment from 'moment';

const i18n = {
  search: 'Pesquisar...',
  categories: {
    Activity: 'Atividades',
    Foods: 'Comida',
    Frequently: 'Frequentes',
    Objects: 'Objetos',
    Nature: 'Natureza',
    Peoples: 'Pessoas',
    Symbols: 'Símbolos',
    Places: 'Locais',
  },
};

export default {
  name: 'Demo',
  components: {
    shareContactDialog,
    ConfimFinishedChatting,
    VEmojiPicker,
    ConfirmTrasnferToDepartment,
    OpenImageModal,
    NotificationFailSentMsgQuoted,
  },
  props: { value: Boolean, attendence: Object },
  computed: {
    ...mapStateConfig(['users', 'loggedUser', 'tags', 'finishedChatReasons', 'departments']),
    ...mapStateChat(['messages', 'contacts', 'selectedContact', 'quotedMsgSent', 'filterContacts']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  data() {
    return {
      sendImage: false,
      exceptCategoriesArr: ['Flags'],
      //BASE 64 PREFIXES
      base64PrefixJpeg: 'data:image/jpeg;base64,',
      base64PrefixPng: 'data:image/png;base64,',
      base64PrefixDocx: 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,',
      base64PrefixAudio: 'data:audio/wav;base64,',
      base64PrefixPdf: 'data:application/pdf;base64,',
      base64PrefixTxt: 'data:text/plain;base64,',
      base64PrefixZip: 'data:application/zip;base64,',
      base64PrefixHtml: 'data:@file/html;base64,',
      base64PrefixXlsx: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',

      currentMessages: [],
      quotedMsg: null,
      hoverMsgIndex: -1,
      testInputFile: null,
      modelDialogShareContact: null,
      modelDialogFinishedChatting: null,
      modelDialogTranferDepartment: null,
      modelDialogOpenImage: null,
      selectedDepartmentToTranfer: null,
      isActive: null,
      selectedImage64: '',
      btnCloseModal: true,
      sentMessage: '',
      selectedFinishedReason: null,
      newMessage: {},
      isMenuAnexOpened: false,
      isMenuFinishedOpened: false,
      isMenuSearchOpened: false,
      isMenuTranferOpened: false,
      isMenuTagsOpened: false,
      selectedTag: null,
      findDepartment: '',
      findMessage: '',
      findTag: '',
      options: [
        {
          id: 0,
          name: 'Imagens',
        },
        // {
        //   id: 1,
        //   name: 'Contatos',
        // },
        {
          id: 2,
          name: 'Documentos',
        },
      ],
      motives: ['Dúvida', 'Engano', 'Pendente', 'Rejeitado', 'Resolvido'],
      menuAct: null,
      offset: true,
    };
  },
  created() {
    document.addEventListener('keyup', this.onEnter);
  },
  mounted() {
    let checkFilterContact = this.filterContacts.find((item) => item.id == this.attendence?.chatId);
    let checkContact = this.contacts.find((item) => item.id == this.attendence?.chatId);
    if(checkContact) {
      this.selectContact(checkContact);
    }
    if(checkFilterContact) {
      this.selectContact(checkFilterContact);
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onEnter);
  },
  methods: {
    ...mapActionsAttendences(['resetUnreadCountOfChat']),
    ...mapActionsChat(['selectContact', 'sendMessage']),
    closeDialog() {
      this.resetUnreadCountOfChat(this.selectedContact);
      this.selectContact(null);
      this.$emit('close', false);
    },
    openChatDialog() {
      this.modelDialogShareContact = true;
    },
    closeOthersDialogs() {
      this.modelDialogShareContact = false;
      this.modelDialogFinishedChatting = false;
      this.modelDialogTranferDepartment = false;
      this.modelDialogOpenImage = false;
      this.selectedImage64 = null;
      this.selectedDepartmentToTranfer = null
      this.selectedFinishedReason = null
      this.quotedMsgSent = false;
    },
    sendImagesDocuments(e) {
      this.sendImage = true;
      this.sentMessage = e.target.files[0];
    },
    defineHour(timestamp) {
      var dateFormat = new Date(timestamp * 1000);
      let hour =
        String(dateFormat.getHours()).padStart(2, '0') +
        ':' +
        String(dateFormat.getMinutes()).padStart(2, '0');
      return hour;
    },
    defineDate(timestamp) {
      var dateFormat = moment(timestamp * 1000).format('DD/MM/YYYY')
      return dateFormat;
    },
    defineDay(timestamp) {
      return moment(timestamp * 1000).format('DD');
    },
    defineQuotadeMsg(msg) {
      this.quotedMsg = msg;
    },
    activeQuotedMsg(id, conditional) {
      if (conditional) {
        setTimeout(() => {
          this.hoverMsgIndex = id;
        }, "20");
      } else {
        this.hoverMsgIndex = id;
      }
    },
    changeTag(tag) {
      if (tag) {
        this.$socket.client.emit('selectTag', tag.id, this.selectedContact.id);
      } else {
        this.$socket.client.emit('selectTag', null, this.selectedContact.id);
      }
      this.isMenuTagsOpened = !this.isMenuTagsOpened;
    },
    isLink(message) {
      const validator = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      if (message.match(validator)) {
        return true
      } else {
        return false
      }
    },
    selectEmoji(emoji) {
      this.message = this.message + emoji.data;
    },
    activeEmoji() {
      this.isActive = !this.isActive;
    },
    async getBase64Image(msg) {
      let img64;
      if (msg) {
        var read = new FileReader();
        read.readAsDataURL(msg);
        read.onload = function (fileLoader) {
          img64 = fileLoader.target.result.split(',')[1];
        }
      }
      setTimeout(() => {
        let image = {};
        image.base64 = img64;
        image.type = msg.type;
        image.name = msg.name;
        image.size = msg.size;
        this.$socket.client.emit("sendMessageImage", image, this.selectedContact._serialized);
        this.sendImage = !this.sendImage;
        this.sentMessage = "";
        this.quotedMsg = null;
      }, "10");
    },
    async sendNewMessage() {
      if (this.sendImage) {
        let image = {};
        image.type = this.sentMessage.type;
        image.base64 = await this.getBase64Image(this.sentMessage);
      }
      else if (this.sentMessage && this.sentMessage.trim().length != 0) {
        if (this.isActive) {
          this.activeEmoji();
        }
        this.newMessage.body = `*${this.loggedUser.name}*\n ${this.sentMessage}`;
        this.newMessage.chatId = this.selectedContact.id;
        this.newMessage.fromMe = true;
        this.newMessage.timestamp = new Date().getTime();
        this.newMessage.toNumber = this.selectedContact._serialized;
        this.newMessage.quotedMsg = this.quotedMsg;
        this.$socket.client.emit('sendMessage', this.newMessage);
        this.sentMessage = '';
        this.quotedMsg = null;
      }
    },
    currentTime() {
      var data = new Date();
      let currentData = '';
      var min = String(data.getMinutes()).padStart(2, '0');
      var hour = String(data.getHours()).padStart(2, '0');
      var day = String(data.getDate()).padStart(2, '0');
      var mouth = String(data.getMonth() + 1).padStart(2, '0');
      var year = data.getFullYear();
      currentData = day + '/' + mouth + '/' + year + ' - ' + hour + ':' + min;
      return currentData;
    },
    currenteHour() {
      var data = new Date();
      let time = '';
      var min = String(data.getMinutes()).padStart(2, '0');
      var hour = String(data.getHours()).padStart(2, '0');
      time = hour + ':' + min;
      return time;
    },
    filterMessages() {
      let array = [];
      array = this.messages.filter((item) => item.chatId == this.selectedContact.id);
      array = array.filter((el) => {
        if (el.body) {
          return el?.body.toLowerCase().includes(this.findMessage.toLowerCase())
        }
      }
      );
      return array;
    },
    filterDepartments() {
      let array = [];
      array = this.departments;
      array = array.filter((el) =>
        el.name.toLowerCase().includes(this.findDepartment.toLowerCase())
      );
      return array;
    },
    filterTags() {
      let array = [];
      array = this.tags;
      array = array.filter((el) =>
        el.name.toLowerCase().includes(this.findTag.toLowerCase())
      );
      return array;
    },
    onEnter(e) {
      if (!e) e = window.event;
      let keyCode = e.keyCode || e.which;
      if (keyCode == '13') {
        this.sendNewMessage();
      }
    },
    openMenuSearch() {
      this.btnCloseModal = !this.btnCloseModal;
      this.isMenuSearchOpened = !this.isMenuSearchOpened;
    },
    finishedChat(chat, finishedReason) {
      this.selectedFinishedReason = finishedReason;
      this.modelDialogFinishedChatting = true;
      this.isMenuFinishedOpened = false;
    },
    tranferToDepartment(department) {
      this.selectedDepartmentToTranfer = department;
      this.modelDialogTranferDepartment = true;
    },
    openImageDialog(image) {
      this.selectedImage64 = image;
      this.modelDialogOpenImage = true;
    },
    formattedNumber(seri) {
      let arrString = seri.split('');
      arrString.unshift("+");
      arrString.splice(3, 0, " ");
      arrString.splice(4, 0, "(");
      arrString.splice(7, 0, ")");
      arrString.splice(8, 0, " ");
      arrString.splice(13, 0, "-");
      arrString.splice(18, arrString.length);
      arrString = arrString.join("");
      return arrString;
    },
    cancelSendImage() {
      this.sendImage = false;
      this.sentMessage = '';
    },
    teste(e) {
      this.sendImage = true;
      this.sentMessage = e.target.files[0];
    },
    checkTypeBase64Img(msg) {
      if (msg.mediaType == 'jpg') {
        return this.base64PrefixJpeg + msg.dataMedia;
      } else if (msg.mediaType == 'png') {
        return this.base64PrefixPng + msg.dataMedia;
      } else {
        return this.base64PrefixJpeg + msg.dataMedia;
      }
    },
    prefixBase64(msg) {
      if (msg.mediaType == 'pdf') {
        return this.base64PrefixPdf + msg.dataMedia;
      } else if (msg.mediaType == 'docx') {
        return this.base64PrefixDocx + msg.dataMedia;
      } else if (msg.mediaType == 'txt') {
        return this.base64PrefixTxt + msg.dataMedia;
      } else if (msg.mediaType == 'xlsx') {
        return this.base64PrefixXlsx + msg.dataMedia;
      } else if (msg.mediaType == 'rar') {
        return this.base64PrefixZip + msg.dataMedia;
      } else if (msg.mediaType == 'html') {
        return this.base64PrefixHtml + msg.dataMedia;
      }
    },
    closeMenuTranfer() {
      this.isMenuTranferOpened = false;
    },
  },
  watch: {
    quotedMsg: (newVal, oldVal) => {
      const div = document.querySelector('#MessagesSection');
      if (newVal != null) {
        div.style.height = "calc(100% - 210px)"
      } else {
        div.style.height = "calc(100% - 120px)"
      }
    },
    isMenuAnexOpened: (newVal, oldVal) => {
      const elemnt = document.getElementById("btn-openAnexMenu");
      if (newVal == true) {
        elemnt.classList.add("anexMenuOpened");
      } else {
        elemnt.classList.remove("anexMenuOpened");
      }
    },
    isActive: (newVal, oldVal) => {
      const elemnt = document.getElementById("icon-openEmojiMenu");
      if (newVal == true) {
        elemnt.classList.add("emojiOpen");
      } else {
        elemnt.classList.remove("emojiOpen");
      }
    },

    selectedContact: function (newVal) {
      this.currentMessages = this.messages.filter((el) => el.chatId == newVal.id);
      let arrMsgs = [];
      this.currentMessages.forEach((el) => {
        if (el.hasQuotedMsg) {
          if (el.fromMe) {
            let foundQuotedMsg = this.currentMessages.find((item) => item.id == el.quotedMsgId);
            el.theseQuotedMsg = { ...foundQuotedMsg, theseQuotedMsg: null }
          } else {
            let foundQuotedMsg = this.currentMessages.find((item) => item?._serialized.includes(el.quotedMsgId));
            el.theseQuotedMsg = { ...foundQuotedMsg, theseQuotedMsg: null }
          }

        }
        arrMsgs.push(el);
      })
      this.currentMessages = arrMsgs;
      this.sentMessage = '';
      this.quotedMsg = null;

      let oldEl = {};
      this.currentMessages.forEach((el, index) => {
        if (index == 0) {
          let msgDate = {};
          msgDate.type = 'msgDate';
          msgDate.body = this.defineDate(el.timestamp);
          this.currentMessages.splice(index, 0, msgDate);
          oldEl = el;
        } else {
          if (this.defineDay(el.timestamp) != this.defineDay(oldEl.timestamp)) {
            let msgDate = {};
            msgDate.type = 'msgDate';
            msgDate.body = this.defineDate(el.timestamp);
            this.currentMessages.splice(index, 0, msgDate);
            oldEl = el;
          }
        }
      })

      setTimeout(() => {
        let elmnt = document.getElementById("MessagesSection");
        elmnt ? elmnt.scrollTo({ top: elmnt.scrollHeight, left: 0, behavior: 'smooth' }) : null;
      }, 1);
    },
    messages: function (newVal) {
      let lastMsg = newVal[newVal.length - 1];
      if (lastMsg.chatId == this.selectedContact.id) {
        this.currentMessages = this.messages.filter((el) => el.chatId == lastMsg.chatId);
        if (lastMsg.hasQuotedMsg) {
          if (lastMsg.fromMe) {
            let foundQuotedMsg = this.currentMessages.find((el) => el.id == lastMsg.quotedMsgId);
            lastMsg.theseQuotedMsg = { ...foundQuotedMsg, theseQuotedMsg: null }
          } else {
            let foundQuotedMsg = this.currentMessages.find((item) => item?._serialized.includes(lastMsg.quotedMsgId));
            lastMsg.theseQuotedMsg = { ...foundQuotedMsg, theseQuotedMsg: null }
          }
        }
        setTimeout(() => {
          let elmnt = document.getElementById("MessagesSection");
          elmnt ? elmnt.scrollTo({ top: elmnt.scrollHeight, left: 0, behavior: 'smooth' }) : null;
        }, 1);
      }
    }
  },
};
</script>
<script setup>
import EmoticonExcitedOutlineIcon from 'vue-material-design-icons/EmoticonExcitedOutline.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
</script>
<style>
.inputMessage {
  margin-bottom: 27px !important;
  border-radius: 0px 0px 20px 20px;
  width: calc(100vw - 420px);
  background-color: #f0f0f0;
}

.box-message-view {
  position: absolute;
  top: 30px;
  right: 12%;
  border-radius: 32px !important;
}

input {
  outline: none;
}

#areaMessage {
  outline: none;
  background-color: #ffffff;
  appearance: unset;
}

#MessagesSection {
  margin-top: 15px;
  height: calc(100% - 120px);
  width: calc(100vw - 420px);
  background: url(./../../../../public/message-bg.png);
  border-radius: 20px 20px 0px 0px;
}

.v-application .border {
  background-color: #f0f0f0 !important;
}

.emoji[data-v-5a35c3ac] {
  padding: 13.5px !important;
}

#Categories[data-v-6d975e7c] {
  overflow-x: unset !important;
}

#app .tw-p-2\.5 {
  padding: 0px;
}

.motive {
  font-size: 15px;
  margin-top: -25px;
}

.anexMenu:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
}

#areaTextTransfer:focus {
  outline: none;
}

#areaTextTransfer::placeholder {
  font-size: small;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.listUsers:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
}

.tagsList:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
}

.fileInput {
  display: none;
}

body {
  margin-top: -7px !important;
}

.message-section {
  margin-left: 419px;
}

.message-section .header {
  background-color: #f0f0f0;
  min-width: calc(100% - 420px);
  max-width: 859px;
  border-radius: 20px 20px 0px 0px;
  padding: 30px 16px 30px 16px;
}

.search-menu {
  right: 12.08%;
  top: 34px;
  height: 80%;
  width: 400px;
  background-color: #ffffff;
  border-radius: 20px;
}

.search-menu .header {
  background-color: #f0f0f0;
  height: 52px;
}

.search-input {
  outline: none;
  background-color: #ffffff;
  border-radius: 32px !important;
  border: 1px solid #179788;
}

.found-messages {
  height: 86%;
}

.found-messages .message {
  max-width: 32ch;
}

.transfer-menu {
  width: 350px;
  height: 100%;
  max-height: 650px;
}

.close-btn {
  min-width: 36px !important;
  width: 36px;
  height: 36px;
}

.text-green {
  color: #48beb0;
}

.tag-position {
  right: 20px;
  position: absolute;
}

.finished-menu {
  max-width: 250px;
  /* max-height: 390px; */
}

.message-size {
  max-width: calc(100% - 150px);
}


.sendImage-class .v-text-field {
  margin-top: 0px;
}

.text-link a {
  color: #027eb5 !important;
}

.text-link a:hover {
  text-decoration: underline !important;
}

.msg-size {
  white-space: pre-line;
  max-width: 85ch;
  word-wrap: break-word;
}

.image-hour-class {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  margin-top: -20px;
  margin-left: auto;
  text-shadow: 0px 0px 4px #b6b6b6;
}

.sticker-class {
  max-width: 150px;
  max-height: 150px;
}

.sticker-hour-class {
  background-color: #ffffff;
  padding: 4px;
  border-radius: 8px;
}


.imageComponent {
  border-radius: 12px;
  border: 6px solid #ffffff;
  display: flex;
  width: min-content;
  margin-top: 8px;
}

.image-class {
  min-width: 300px;
  max-height: 400px;
  max-width: 330px;
  border-radius: 4px;
}

.search-menu-opened {
  width: 375px;
}

.anexMenuOpened {
  transition: 0.5s;
  transform: rotate(135deg) scale(1.3);
}

.emojiOpen {
  transition: 0.3s;
  transform: rotate(360deg) scale(0);
}

.emojiClose {
  animation: increase-size 0.2s linear normal 0s;
  display: block !important;
}

.document-class {
  /* width: 300px;
  height: 50px; */
  background-color: rgba(167, 243, 208);
}

.msg-size {
  white-space: pre-line;
  max-width: 70ch;
  word-wrap: break-word;
}

.document-name {
  text-overflow: ellipsis;
  max-width: 12ch;
  overflow: hidden;
  white-space: nowrap;
}

.xlsx-type {
  -webkit-text-stroke-color: #008500 !important;
}

.rar-type {
  -webkit-text-stroke-color: #9e00ce !important;
}

.docx-type {
  -webkit-text-stroke-color: #0000ff !important;
}

.pdf-type {
  -webkit-text-stroke-color: #ff0000 !important;
}

.type-default {
  font-weight: 800;
  font-size: 20px;
  color: #ffffff;
  -webkit-text-stroke-color: #2a2a2a;
  -webkit-text-stroke-width: 1px;
  margin-top: -12px;
  /* border-bottom: 2px solid #ffffff; */
}

.arrowDownloadImg {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.replyIcon-class {
  cursor: pointer;
  padding: 4px;
  border-radius: 100%;
  background-color: rgba(110, 110, 110, 0.649);
}

.quotedName-class {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #4dafda;
}

.quotedYou-class {
  color: #179788 !important;
}

.quotedMessage-class {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80ch;
  overflow: hidden;
}

.quotedAudio-class {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #105e55;
}

.quotedDocument-class {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #be5ee0;
}

.quotedSticker-class {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #4fc3f7;
}

.quotedImage-class {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #1c5cff;
}

.bgQuotedMsg {
  background-color: #85e2b7;
}

.bgQuotedMsgEnv {
  background-color: #f5f5f5;
}

.hoverOption:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.bgQuotedMsgSize {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70ch;
}

.quotedMsgFromMe {
  border-left: 4px solid #179788;
  color: #179788;
}

.quotedMsgNotFromMe {
  border-left: 4px solid #4dafda;
  color: #4dafda;
}

.bgQuotedMsgEnvFromMe {
  background-color: #c5f2db;
}

.quotedBorder-class {
  border-left: 4px solid #4dafda;
}

.quotedYouBorder-class {
  border-left: 4px solid #179788;
}

.downloadImage-class {
  padding: 6px;
  background-color: rgba(110, 110, 110, 0.649);
  border-radius: 100%;
}

/* .inputMenuEmoji {
  animation: increase-size 1s linear normal 0s;
} */



@keyframes increase-size {
  0% {
    transform: scale(0) rotate(270deg);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes translete-Yposition {
  0% {
    margin-bottom: -400px;
    /* transform: translateY(400px); */
  }

  100% {
    margin-bottom: 0px;
    /* transform: translateY(0px); */
  }
}
</style>
