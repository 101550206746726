<template>
  <v-dialog max-width="800" v-model="show" scrollable>
    <v-card class="create-item" :loading="loading">
      <v-card-title>
        <div>Novo item</div>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('closeNotes')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-7">
        <ValidationObserver ref="observer">
          <v-row>
            <v-col cols="6">
              <v-card-subtitle class="pl-0 pb-2 pt-0">Nome</v-card-subtitle>
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="attendenceItem.name"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <v-card-subtitle class="pl-0 pb-2 pt-0"
                >Observação</v-card-subtitle
              >
              <ValidationProvider rules="" v-slot="v">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="attendenceItem.notes"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
          </v-row>

          <!-- NÃO REMOVER -->

          <!-- <v-row>
            <v-col cols="9">
              <v-card-subtitle class="pl-0 pb-2 pt-0"
                >Quantidade</v-card-subtitle
              >
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  dense
                  type="number"
                  outlined
                  hide-details="auto"
                  v-model.number="attendenceItem.quantity"
                ></v-text-field>

                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <v-card-subtitle class="pl-0 pb-2 pt-0"
                >Preço unitário</v-card-subtitle
              >
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  dense
                  type="number"
                  outlined
                  hide-details="auto"
                  v-model.number="attendenceItem.price"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="9">
              <v-card-subtitle class="pl-0 pb-2 pt-0"
                >Desconto(%)</v-card-subtitle
              >
              <ValidationProvider rules="" v-slot="v">
              <v-text-field
                dense
                type="number"
                outlined
                hide-details="auto"
                v-model.number="attendenceItem.discount"
              ></v-text-field>
                
              <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <v-card-subtitle class="pl-0 pb-2 pt-0">IPI(%)</v-card-subtitle>
              <ValidationProvider rules="" v-slot="v">
                
              <v-text-field
                dense
                type="number"
                outlined
                hide-details="auto"
                v-model.number="attendenceItem.IPI"
              ></v-text-field>
              <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6">
              <v-card-subtitle
                class="pl-0 pb-2 pt-0"
                ><v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">Ambiente <span style="font-size: 12px">(?)</span></span>
                </template>
                <span>Ambiente com margem definida nas configurações</span>
              </v-tooltip></v-card-subtitle
              >
              <v-select
                outlined
                dense
                hide-details
                :items="environments"
                item-text="name"
                item-value="id"
                v-model="attendenceItem.environmentId"
              >
              </v-select>
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  v-model="attendenceItem.environmentId"
                  class="form-control disabled"
                  style="display: none"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>

            <!-- <v-col cols="6">
              <v-card-subtitle class="pl-0 pb-2 pt-0"
                >Arquivo .xml</v-card-subtitle
              >
              <v-file-input
                dense
                hide-details
                @change="handleFileSelection"
                accept=".xml"
                label="Selecione o .xml"
              ></v-file-input>
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  v-model="notParsed"
                  class="form-control disabled"
                  style="display: none"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col> -->
            <v-col cols="6">
              

              <v-card-subtitle
                class="pl-0 pb-2 pt-0"
                ><v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">Custo unitário <span style="font-size: 12px">(?)</span></span>
                </template>
                <span>Calcula o preço unitário total baseado na margem do ambiente</span>
              </v-tooltip></v-card-subtitle
              >
              <ValidationProvider rules="isNotBlank" v-slot="v">
                <v-text-field
                  dense
                  type="number"
                  outlined
                  hide-details="auto"
                  v-model.number="attendenceItem.price"
                  :disabled="file.length > 0 || !!attendenceItem.awsKey"
                ></v-text-field>
                <span class="form-error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card-subtitle class="pl-0 pb-2 pt-0"
                >Arquivo .xml</v-card-subtitle
              >
              <div v-if="file.length == 0 && !attendenceItem.awsKey">
                <div
                  :class="['dropZone', dragging ? 'dropZone-over' : '']"
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
                >
                  <div class="dropZone-info" @drag="onChange">
                    <cloud-upload-icon-grey width="40" height="40" />
                    <div>
                      <span class="dropZone-title"
                        >Clique ou arraste para adicionar a foto</span
                      >
                    </div>
                    <!-- <div class="dropZone-upload-limit-info">
          <div>extension support: txt</div>
          <div>maximum file size: 5 MB</div>
        </div> -->
                  </div>
                  <input type="file" @change="onChange" />
                </div>
              </div>
              <div v-else class="dropZone-uploaded">
                <div
                  class="dropZone-uploaded-info"
                  v-if="!attendenceItem.awsKey"
                >
                  <div v-for="(fi, i) in file" :key="i">
                    <div class="file-description">
                      <span>
                        {{ fi.name }}
                      </span>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary removeFile"
                      @click="removeFile(i)"
                    >
                      Remover arquivo
                    </button>
                  </div>
                </div>
                <div class="dropZone-uploaded-info" v-else>
                  <div>
                    <div
                      @click="downloadXML"
                      class="file-description"
                      v-if="attendenceItem.awsKey"
                    >
                      <span> Foto </span>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary removeFile"
                      @click="attendenceItem.awsKey = null"
                    >
                      Remover arquivo
                    </button>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-if="userHasPermission('delete_item')" @click="deleteItem"
          ><v-icon>la-trash-alt</v-icon>Excluir item</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text dark color="#177DFF" @click="$emit('closeNotes')"
          >Cancelar</v-btn
        >
        <v-btn class="rounded-lg" color="#177DFF" depressed dark>
          <div class="px-4" @click="createItem">Salvar</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapState: mapStateAttendences,
  mapGetters: mapGettersAttendences,
  mapActions: mapActionsAttendences,
} = createNamespacedHelpers('attendences');

export default {
  props: { value: Boolean, attendence: Object, choosenItem: Object },
  data() {
    return {
      dragging: false,
      loading: false,
      attendenceItem: {
        price: this.priceWithMargin,
      },
      file: [],
      notParsed: null,
      totalPrice: 0,
    };
  },
  watch: {
    totalPrice() {
      if (this.attendenceItem.environmentId) {
        this.calculateItemPrice();
      }
    },
    'attendenceItem.environmentId'() {
      if (this.totalPrice) {
        this.calculateItemPrice();
      }
    },
  },
  methods: {
    ...mapActionsAttendences(['downloadFile']),
    deleteItem() {
      this.loading = true;
      this.$emit('deleteItem');
    },
    calculateItemPrice() {
      this.attendenceItem.price =
        Math.round(
          this.totalPrice *
            (1 +
              this.getEnvironment(this.attendenceItem.environmentId).margin /
                100) *
            100
        ) / 100;
    },
    async downloadXML() {
      const url = await this.downloadFile({ key: this.attendenceItem.awsKey });
      let link = document.createElement('a');
      link.href = url;
      link.download = url + '.xml';
      link.click();
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files);
    },
    createFile(files) {
      files = Array.from(files);
      files.forEach((fi) => {
        if (!fi.type.match('xml.*')) {
          this.dragging = false;
          return;
        }
        if (fi.size > 100000000) {
          this.$notify({
            group: 'error',
            title: 'Tamanho de arquivo maior que 100MB.',
            type: 'warn',
            // text: 'Hello user! This is a notification!',
          });
          this.dragging = false;
          return;
        }
        this.file = Array.from(files);
        this.handleFileSelection(this.file[0]);
      });
      // this.file.forEach()
      this.dragging = false;
    },
    removeFile(i) {
      this.file.splice(i, 1);
      if (this.attendenceItem) {
        this.attendenceItem.awsKey = null;
        // delete this.choosenItem.awsKey;
      }
      this.totalPrice = null;
    },
    parseTextAsXml(text) {
      const parser = new DOMParser();
      const xmlDom = parser.parseFromString(text, 'text/xml');

      console.log('xmlDom');
      console.log(xmlDom);
      this.totalPrice = xmlDom
        .querySelector('TOTALPRICES')
        .getAttribute('TABLE');
    },

    waitForTextReadComplete(reader) {
      reader.onloadend = (event) => {
        this.notParsed = event.target.result;
        this.parseTextAsXml(this.notParsed);
      };
    },

    handleFileSelection(e) {
      console.log(e);
      const file = e;
      const reader = new FileReader();

      this.waitForTextReadComplete(reader);
      reader.readAsText(file);
    },
    async createItem() {
      const isAlreadyLoading = this.loading === true ? true : false;
      this.loading = true;
      if (this.$refs.observer._data.flags.valid && !isAlreadyLoading) {
        let realPrice = null;
        if (this.totalPrice) {
          realPrice = this.totalPrice;
        } else {
          realPrice = this.attendenceItem.price;
        }
        const obj = {
          ...this.attendenceItem,
          price:
            Math.round(
              realPrice *
                (1 +
                  this.getEnvironment(this.attendenceItem.environmentId)
                    .margin /
                    100) *
                100
            ) / 100,
          file: this.file,
        };
        obj.quantity = 1;
        if (!this.choosenItem) {
          this.$emit('createItem', obj);
        } else {
          this.$emit('editItem', obj);
        }
      } else {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapStateAttendences(['environments']),
    ...mapGettersAttendences(['getEnvironment']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.attendenceItem = {
      attendenceId: this.attendence.id,
      name: null,
      status: null,
      denyReason: null,
      price: null,
      quantity: null,
      discount: null,
      IPI: null,
      installments: null,
      notes: null,
    };
  },
  mounted() {
    if (this.choosenItem) {
      this.attendenceItem = { ...this.choosenItem };
      /*this.totalPrice =
        this.attendenceItem.price /
        (1 +
          this.getEnvironment(this.attendenceItem.environmentId).margin / 100);
      this.totalPrice = Math.round(this.totalPrice * 100) / 100;*/
    }
  },
};
</script>

<style>
.create-item .dropZone {
  width: 100%;
  height: 100px;
  position: relative;
  border: 2px dashed #b7c3da;
  border-radius: 12px;
}

.create-item .dropZone:hover {
  border: 2px solid #2e94c4;
}

.create-item .dropZone:hover .dropZone-title {
  color: #1975a0;
}

.create-item .dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.create-item .dropZone-title {
  color: #000000;
}

.create-item .dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.create-item .dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.create-item .dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.create-item .dropZone-uploaded {
  width: 100%;
  height: 100px;
  border: 2px dashed #b7c3da;
  border-radius: 12px;
}

.create-item .dropZone-uploaded-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #a8a8a8;
  height: 100%;
}

.create-item .dropZone-uploaded-info > div {
  padding-right: 30px;
  padding-left: 30px;
}

.create-item .file-description {
  display: flex;
  justify-content: center;
}

.create-item .removeFile {
  width: fit-content;
}
</style>
