<template>
  <v-dialog
    width="80%"
    style="height: 580px"
    persistent
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <v-card class="dialogCardBudget custom-scroll" :loading="loading">
      <div class="d-flex px-6 pt-5 pb-3">
        <v-card-title class="pa-0 dialogTitle black--text"
          >Adicionar item</v-card-title
        >
        <dolar-icon class="dolar-green-icon" width="30" height="30" />
        <div
          class="d-flex ml-12 double-button-card"
          @click="openDialogStatusModal"
          style="align-self: center"
        >
          <v-btn 
          @click="setAllStatus('R')" 
          depressed 
          class="px-3 single-button">Reprovado</v-btn>
          <div class="verticalLine"></div>
          <v-btn 
          @click="setAllStatus('A')" 
          depressed 
          class="px-3 single-button">Aprovado</v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn fab small icon @click="$emit('close')">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="4" class="d-flex upper-items">
            <div class="upper-items-div">
              <div class="pa-2">
                <div style="color: #177dff">
                  Cliente: {{ attendence.client.name }}
                </div>
                <div style="font-size: 11px">
                  Contato: {{ attendence.client.contact }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="pa-2">
                <div style="color: #177dff; text-align: end">
                  N° {{ attendence.id }}
                </div>
                <div style="font-size: 11px">
                  Registro:
                  {{ datetimeBr(attendence.registerDate).split(' ')[0] }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex upper-items">
            <div class="d-flex upper-items-div">
              <div>
                <span class="black--text"> Responsável </span>
              </div>
              <!--3261-->
              <v-select
                hide-details
                dense
                outlined
                :items="users"
                return-object
                v-model="responsible"
                item-text="name"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="2" class="d-flex upper-items">
            <div class="upper-items-div">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="generateBudgetPDF"
                    depressed
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <dolar-icon
                      style="filter: saturate(100%) brightness(0)"
                      width="20"
                      height="20"
                    />
                  </v-btn>
                </template>
                <span>Gerar orçamento</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="generatePDFClick"
                    depressed
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <file-icon />
                  </v-btn>
                </template>
                <span>Gerar contrato</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <div class="table-div pt-0">
          <v-data-table
            :items="budgetArray"
            no-data-text="Nenhum resultado encontrado"
            :headers="header"
            hide-default-footer
            calculate-widths
            disable-pagination
            id="budgetTable"
            @click:row="changeItem"
          >
            <template v-slot:[`item.name`]="{ item, index }">
              <div class="custom-col">
                <div class="col-index">
                  {{ ('0' + (index + 1)).slice(-2) }}
                </div>
                <div v-if="item.status !== 'R'">
                  {{ item.name }}
                </div>
                <div v-else style="text-decoration: line-through">
                  {{ item.name }}
                </div>
              </div>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <div class="d-flex quantity-col">
                <div>
                  <span>{{ item.quantity }}</span>
                </div>
                <v-spacer></v-spacer>
                <div style="display: grid">
                  <v-btn
                    class="number-input-button"
                    fab
                    icon
                    @click.stop="increaseQuantity(item.id)"
                  >
                    <v-icon> la-angle-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="number-input-button"
                    fab
                    icon
                    @click.stop="decreaseQuantity(item.id)"
                  >
                    <v-icon> la-angle-down </v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-slot:[`item.totalPrice`]="{ item }">
              <div class="col-center">
                {{ currencyBr(getAttendenceItemPrice(item)) }}
              </div>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <div class="col-center">
                {{ currencyBr(item.price) }}
              </div>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
              <div class="col-center" @click="chatClick(item.id)">
                <v-badge
                  bordered
                  overlap
                  dot
                  color="#31cfff"
                  :value="isBadgeAppearing(item.notes)"
                >
                  <div>
                    <message-circle width="25" height="25" />
                  </div>
                </v-badge>
              </div>
            </template>
            <template v-slot:[`item.discount`]="{ item }">
              <div class="col-center">
                {{ item.discount }}
              </div>
            </template>
            <template v-slot:[`item.IPI`]="{ item }">
              <div class="col-center">
                {{ item.IPI }}
              </div>
            </template>
          </v-data-table>
        </div>
        <div class="d-flex pt-3">
          <v-btn
            class="rounded-lg"
            @click="openDialogCreate"
            color="#177DFF"
            depressed
            dark
          >
            <div class="px-4">Adicionar item</div>
          </v-btn>
          <v-spacer></v-spacer>
          <span>
            <div class="black--text" style="font-weight: 600">
              Total
              {{ currencyBr(totalPrice) }}
            </div>
          </span>
        </div>
        <template v-if="budgetArray.length > 0">
          <div class="pt-5 pb-1">
            <span class="black--text" style="font-size: 16px; font-weight: 500">
              Pagamento
            </span>
          </div>
          <div class="payment-div">
            <v-row class="pa-3">
              <v-col cols="3">
                <div>Forma de Pagamento</div>
                <v-select
                  dense
                  outlined
                  hide-details
                  :items="paymentMethods"
                  ref="methodRef"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <div>Desconto/Acréscimo</div>
                <v-text-field
                  type="number"
                  outlined
                  dense
                  hide-details
                  suffix="%"
                  ref="discountRef"
                  v-model="totalDiscount"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <div>Parcelas</div>
                <v-select
                  dense
                  outlined
                  hide-details
                  :items="numberArray"
                  v-model="portionsArray.length"
                  ref="portionsRef"
                ></v-select>
                <v-checkbox
                  class="pt-1"
                  label="Entrada"
                  hide-details="auto"
                  v-model="hasEntry"
                  style="lineheight: normal; margin-top: 0px; padding: 0"
                ></v-checkbox>
              </v-col>
              <v-col cols="2">
                <div>1° Vencimento</div>

                <v-menu
                  v-model="choosenExpireDateMenu"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  left
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="choosenExpireDateFormatted"
                      v-bind="attrs"
                      @blur="
                        choosenExpireDate = parseDate(
                          choosenExpireDateFormatted,
                          'choosenExpireDate'
                        )
                      "
                      v-on="on"
                      outlined
                      hide-details
                      readonly
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @change="changechoosenExpireDate"
                    no-title
                    @input="choosenExpireDateMenu = false"
                    ref="expireRef"
                    locale="pt-br"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <div>&#8205;</div>
                <v-btn
                  @click="updatePayment"
                  class="rounded-lg"
                  height="40"
                  color="#0CD5A6"
                  depressed
                  darkf
                  ><v-icon class="pr-1" style="line-height: 1px"
                    >mdi-check</v-icon
                  >
                  <span v-if="!$vuetify.breakpoint.mdAndDown"> Gerar </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              v-if="portionsArray.length > 0"
              class="portions-table"
              hide-default-footer
              calculate-widths
              no-data-text="Nenhum resultado encontrado"
              disable-pagination
              :headers="portionsHeader"
              :items="portionsArray"
              @click:row="rowClicked"
              item-key="id"
              @current-items="calculatePrice"
              v-click-outside-custom="cancelChange"
              :custom-sort="customSort"
              must-sort
              ref="portionDataTable"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:[`item.expiringDate`]="{ item }">
                <!-- <template v-if="item.id == editingRow.id">
                <v-date-picker>

                </v-date-picker>
              </template> -->
                <template v-if="item.isEntry"><div>Entrada</div></template>
                <template v-else>
                  <v-text-field
                    type="date"
                    class="portion-field"
                    :value="formatDateRow(item.expiringDate)"
                    @change="item.expiringDate = createNewDate($event)"
                    hide-details
                  ></v-text-field>
                  <!-- <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="height: 100%; display: flex; align-items: center"
                      >
                        <span>
                          {{ datetimeBr(item.expiringDate).split(' ')[0] }}
                        </span>
                      </div>
                    </template>
                    <v-date-picker
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="changeItemExpiringDate($event, item)"
                    ></v-date-picker>
                  </v-menu> -->
                </template>
              </template>
              <template v-slot:[`item.price`]="{ item }">
                <div
                  class="d-flex"
                  :key="item.id"
                  v-if="
                    (editingRow.id && item.id == editingRow.id) ||
                    (editingRow.id == 0 && item.id == 0)
                  "
                >
                  <v-text-field
                    dense
                    hide-details
                    v-model.number="editingRow.price"
                    class="shrink"
                    style="width: 40px"
                    @change="changeTotalPortion($event, item)"
                    @keyup.enter="confirmChange"
                    @keyup.esc="cancelChange"
                    type="number"
                  ></v-text-field>
                </div>
                <template v-else>
                  <div :key="item.id">
                    {{ currencyBr(item.price) }}
                  </div>
                </template>
              </template>
              <template v-slot:[`item.paymentMethod`]="{ item }">
                <div class="d-flex" style="justify-content: center">
                  <v-select
                    dense
                    outlined
                    hide-details
                    :items="paymentMethods"
                    :value="getPaymentMethod(item)"
                    @input="changePaymentMethod($event, item)"
                  ></v-select>
                </div>
              </template>
            </v-data-table>
            <div class="d-flex px-7" v-if="remainingPrice > 0">
              <v-spacer></v-spacer>
              <div
                class="portions-error"
                :style="[saveClicked ? { color: 'red' } : { color: 'black' }]"
                v-if="portionsArray.length > 0"
              >
                Falta(m) {{ currencyBr(remainingPrice) }}
              </div>
            </div>
            <div class="d-flex px-7" v-if="remainingPrice < 0">
              <v-spacer></v-spacer>
              <div
                class="portions-error"
                :style="[saveClicked ? { color: 'red' } : { color: 'black' }]"
                v-if="portionsArray.length > 0"
              >
                Sobra(m) {{ currencyBr(remainingPrice) }}
              </div>
            </div>
            <!-- <div
            class="pt-0 pb-4"
            v-if="portionsAmount > 1"
            style="display: flex; justify-content: center"
          >
            <span class="black--text" style="font-weight: 600"
              >Total
              {{
                new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(totalPrice)
              }}</span
            >
          </div> -->
          </div>
          <v-row>
            <v-col cols="3">
              <div>Data de entrega</div>

              <v-menu
                v-model="choosenDeliverDateMenu"
                :close-on-content-click="false"
                transition="slide-y-transition"
                left
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mb-2"
                    v-model="choosenDeliverDateFormatted"
                    v-bind="attrs"
                    @blur="
                      choosenDeliverDate = parseDate(
                        choosenDeliverDateFormatted,
                        'choosenDeliverDate'
                      )
                    "
                    v-on="on"
                    outlined
                    hide-details
                    readonly
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  @change="changechoosenDeliverDate"
                  no-title
                  @input="choosenDeliverDateMenu = false"
                  ref="expireRef"
                  locale="pt-br"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="blue" @click="closeDialog">Cancelar</v-btn>
        <v-btn
          dark
          depressed
          color="blue"
          class="saveButton"
          @click="saveBudget(false)"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
    <ItemNotes
      v-if="dialogNotes"
      @closeNotes="closeNotesDialog"
      @changeNote="changeNote"
      :attendence="attendence"
      :editable="true"
      :itemId="choosenItem"
      v-model="dialogNotes"
    ></ItemNotes>
    <StatusAttendence
      v-if="dialogStatus"
      :attendence="attendence"
      v-model="dialogStatus"
      @save="saveStatus"
      @close="dialogStatus = false"
    >
    </StatusAttendence>
    <CreateItem
      v-if="dialogCreate"
      @closeNotes="closeCreateDialog"
      v-model="dialogCreate"
      @createItem="createItem"
      @editItem="editItem"
      @deleteItem="deleteItem"
      :attendence="attendence"
      :choosenItem="editingItem"
    ></CreateItem>
    <PDFGenerator
      :info="generalInfo"
      :generate="PDFToGenerate"
      @downloadFinished="pdfFinished"
      v-if="generatePDF"
    />
  </v-dialog>
</template>

<script>
import ItemNotes from './ItemNotes';
import StatusAttendence from './StatusAttendence';
import CreateItem from './CreateItem';
import PDFGenerator from '../utils/PDFGenerator.vue';

import { createNamespacedHelpers } from 'vuex';

const {
  mapState: mapStateAttendences,
  mapGetters: mapGettersAttendences,
  mapActions: mapActionsAttendences,
} = createNamespacedHelpers('attendences');

const {
  mapState: mapStateConfig,
  mapGetters: mapGettersConfig,
  mapActions: mapActionsConfig,
} = createNamespacedHelpers('config');

export default {
  components: {
    ItemNotes,
    StatusAttendence,
    CreateItem,
    PDFGenerator,
  },
  props: { value: Boolean, attendence: Object },
  data() {
    return {
      attendenceCopyObject: null,
      choosenDeliverDate: null,
      choosenDeliverDateMenu: false,
      sortBy: 'expiringDate',
      sortDesc: true,
      totalDiscount: null,
      loading: false,
      editingItem: null,
      hasEntry: false,
      responsible: { ...this.attendence.user },
      userId: { ...this.attendence.user.id },
      PDFToGenerate: null,
      generalInfo: {},
      generatePDF: false,
      saveClicked: false,
      dialogCreate: null,
      choosenItem: null,
      dialogNotes: false,
      dialogStatus: false,
      menu: false,
      mainPaymentMethod: '',
      paymentMethods: [
        'PIX',
        'Débito',
        'Crédito',
        'Dinheiro',
        'Boleto',
        'Cheque',
        'Transferência Bancária',
        'Depósito Bancário',
        'Crédito Loja',
      ],
      choosenExpireDate: null,
      choosenExpireDateMenu: false,
      portionsAmount: 0,
      hasGeneratedPortions: false,
      portionsArray: [],
      portionsHeader: [
        {
          text: 'Vencimento',
          value: 'expiringDate',
          sort: (a, b) => new Date(a) > new Date(b),
        },
        { text: 'Valor', value: 'price', sortable: false },
        { text: 'Forma de pagamento', value: 'paymentMethod', sortable: false },
      ],
      header: [
        { text: 'Item', value: 'name', sortable: false, width: 200 },
        { text: 'Observação', value: 'notes', sortable: false, width: 1 },
        // {
        //   text: 'Quantidade',
        //   value: 'quantity',
        //   sortable: false,
        //   width: '10px',
        // },
        // { text: 'Preço unitário', value: 'price', sortable: false, width: 1 },
        // { text: 'Desc.(%)', value: 'discount', sortable: false, width: 1 },
        // { text: 'IPI(%)', value: 'IPI', sortable: false, width: 1 },
        { text: 'Preço Total', value: 'totalPrice', sortable: false, width: 1 },
      ],
      editingRow: {},
    };
  },
  methods: {
    ...mapActionsAttendences([
      'createAttendenceItem',
      'editAttendence',
      'deleteAttendencePayments',
      'deleteAttendenceItem',
      'editAttendenceItem',
      'createAttendenceHistoric',
    ]),
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == 'expiringDate') {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    async setAllStatus(status){
      this.attendence.item.forEach( el => {
        el.status = status;
      });
    },
    isCredsSuitableForBudget() {
      if (
        this.attendence.client.CPF &&
        this.attendence.client.name &&
        this.attendence.client.contact &&
        this.attendence.client.clientAddress.CEP &&
        this.attendence.client.clientAddress.street &&
        this.attendence.client.clientAddress.number &&
        this.attendence.client.clientAddress.city &&
        this.attendence.client.clientAddress.state
      ) {
        return true;
      } else {
        return false;
      }
    },
    async openDialogStatusModal() {
      if (this.portionsArray.length > 0) {
        this.loading = true;
        
        //await this.saveBudget(true);
        this.loading = false;
        this.dialogStatus = true;
      } else {
        this.$notify({
          group: 'error',
          title: 'O pagamento deve ser gerado para acessar "Status"',
          type: 'warn',
          // text: 'Hello user! This is a notification!',
        });
      }
    },
    async deleteItem() {
      await this.deleteAttendenceItem({
        itemId: this.editingItem.id,
        attendenceId: this.attendence.id,
      });
      this.dialogCreate = false;
    },
    openDialogCreate() {
      this.dialogCreate = true;
      this.editingItem = null;
    },
    pdfFinished() {
      this.generatePDF = false;
      this.loading = false;
    },
    async editItem(event) {
      event.file = event.file[0];
      let dataForm = new FormData();
      for (const key in event) {
        if (!(event[key] == null) && !(event[key] == undefined)) {
          dataForm.append(key, event[key]);
        }
      }
      await this.editAttendenceItem({ item: dataForm });
      this.dialogCreate = false;
    },
    changeItem(event) {
      if (!this.dialogNotes) {
        this.editingItem = event;
        this.dialogCreate = true;
      }
    },
    createNewDate(event) {
      let date = new Date(event);
      date.setDate(date.getDate() + 1);
      return date;
    },
    async generatePDFClick() {
      let areCredsApproved = this.isCredsSuitableForBudget();
      if (this.areAllApproved && areCredsApproved && this.remainingPrice == 0) {
        this.loading = true;

        await this.saveBudget(true);
        this.attendence.deliverDate = this.choosenDeliverDate;
        this.generalInfo = {
          portions: this.portionsArray,
          attendence: this.attendence,
          items: this.budgetArray,
        };
        this.PDFToGenerate = 'content';
        this.generatePDF = true;
      } else {
        if (!this.areAllApproved) {
          this.$notify({
            group: 'error',
            title: 'O orçamento deve ser aprovado para gerar o contrato',
            type: 'warn',
            // text: 'Hello user! This is a notification!',
          });
        } else if (!areCredsApproved) {
          this.$notify({
            group: 'error',
            title: 'Preencha as informações do cliente',
            type: 'warn',
            text: 'Endereço, CPF, Cidade...',
          });
        } else if (this.remainingPrice != 0) {
          this.$notify({
            group: 'error',
            title: 'Verifique seus valores e parcelas!',
            type: 'warn',
            // text: 'Hello user! This is a notification!',
          });
        }
      }
    },
    async generateBudgetPDF() {
      if (this.remainingPrice == 0) {
        this.loading = true;
        await this.saveBudget(true);
        this.generalInfo = {
          portions: this.portionsArray,
          attendence: this.attendence,
          items: this.budgetArray,
        };
        // console.log(this.generalInfo)
        this.PDFToGenerate = 'budget_content';

        this.generatePDF = true;
      } else {
        this.$notify({
          group: 'error',
          title: 'Verifique seus valores e parcelas!',
          type: 'warn',
          // text: 'Hello user! This is a notification!',
        });
      }
      // console.log('BudgetPdf', this.getAttendence(this.attendence.id).item);
    },
    changeItemExpiringDate(date, item) {
      const i = this.portionsArray.findIndex((el) => el.id == item.id);
      this.portionsArray[i].expiringDate = date;
      this.$refs.menu.save(date);
    },
    calculatePrice(event) {
      //TODO
    },
    rowClicked(event, item) {
      this.editingRow = JSON.parse(JSON.stringify(event));
    },
    confirmChange() {
      const i = this.portionsArray.findIndex(
        (el) => el.id == this.editingRow.id
      );
      this.portionsArray.splice(i, 1, this.editingRow);
      this.editingRow = {};
    },
    changeTotalPortion(event, item) {
      let pendingValue = this.totalPrice - event;
      let portionsLeft = this.portionsArray.length - 1;
      pendingValue = pendingValue / portionsLeft;
      this.portionsArray.forEach((el) => {
        if (el.id == item.id) {
          el.price = event;
        } else {
          el.price = pendingValue;
        }
      });
    },
    cancelChange() {
      this.editingRow = {};
    },
    closeCreateDialog() {
      this.dialogCreate = false;
      this.editingItem = {};
    },
    turnBudgetIntoArray() {
      const atte = this.attendences.find((el) => el.id == this.attendence.id);
      return Object.values(atte.item);
    },
    getPaymentMethod(item) {
      if (this.mainPaymentMethod) {
        return this.mainPaymentMethod;
      } else {
        return item.paymentMethod;
      }
    },
    async saveStatus(event) {
      this.$emit('saveStatus', event);
      this.dialogStatus = false;
    },
    async saveBudget(dontClose) {
      this.loading = true;
      this.saveClicked = true;
      if (this.choosenDeliverDate) {
        let deliverDate = new Date(this.choosenDeliverDate);
        deliverDate = deliverDate.setDate(deliverDate.getDate() + 1);
        let attendence = {
          ...this.attendence,
          deliverDate,
        };
        await this.editAttendence({ attendence });
      }
      if (this.remainingPrice === 0 || this.portionsArray.length == 0) {
        await this.deleteAttendencePayments({
          attendenceId: this.attendence.id,
        });
        this.portionsArray = this.portionsArray.map((el) => {
          return { ...el, discount: this.totalDiscount.toFixed(2) };
        });
        const content = {
          responsible: this.responsible,
          portionsArray: this.portionsArray,
        };
        this.$emit('save', content);
        if (!dontClose) {
          this.$emit('close');
          this.$notify({
            group: 'error',
            title: 'Dados alterados!',
            type: 'info',
            // text: 'Hello user! This is a notification!',
          });
        }
      } else {
        this.$notify({
          group: 'error',
          title: 'Verifique seus valores e parcelas!',
          type: 'warn',
          // text: 'Hello user! This is a notification!',
        });
        this.loading = false;
      }
    },
    async createItem(item) {
      const userId = window.localStorage.getItem('userId');
      item.file = item.file[0];
      let dataForm = new FormData();
      for (const key in item) {
        if (!(item[key] == null) && !(item[key] == undefined)) {
          dataForm.append(key, item[key]);
        }
      }
      let res = await this.createAttendenceItem({ item: dataForm });
      // const result = await this.createAttendenceItem({ item: item });

      const historic = {
        attendenceId: this.attendence.id,
        statusId: this.attendence.statusId,
        description: `Item '${item.name}' foi adicinado`,
        userId,
      };
      const loggedUser = this.getUser(userId);
      await this.createAttendenceHistoric({ historic: historic, loggedUser });
      this.dialogCreate = false;
    },
    isBadgeAppearing(note) {
      if (note != '' && note != undefined) {
        return true;
      }
      return false;
    },
    chatClick(id) {
      this.dialogNotes = true;
      this.choosenItem = id;
    },
    async changeNote(event) {
      const item = this.budgetArray.find((el) => el.id == this.choosenItem);
      item.notes = event;
      await this.editAttendenceItem({ item: item });
      this.dialogNotes = false;
    },
    closeNotesDialog() {
      this.dialogNotes = false;
    },
    changePaymentMethod(evt, item) {
      this.portionsArray.find((el) => item.id == el.id).paymentMethod = evt;
    },
    closeDialog() {
      if (!this.hasGeneratedPortions || this.remainingPrice == 0) {
        this.$emit('close', false);
      }
    },
    increaseQuantity(i) {
      var object = this.budgetArray.find((el) => el.id == i);
      object.quantity++;
      this.editAttendenceItem({ item: object });
    },
    decreaseQuantity(i) {
      var object = this.budgetArray.find((el) => el.id == i);
      if (object.quantity != 0) {
        object.quantity--;
        this.editAttendenceItem({ item: object });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    formatDateRow(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    parseDate(date, type) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      const format = `${year}-${month.padStart(2, '0')}-${day.padStart(
        2,
        '0'
      )}`;
      return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}` !=
        this[type]
        ? format
        : this[type];
    },
    changechoosenExpireDate(evt) {
      //console.log(evt);
      this.choosenExpireDate = evt;
    },
    changechoosenDeliverDate(evt) {
      //console.log(evt);
      this.choosenDeliverDate = evt;
    },
    updatePayment() {
      this.hasGeneratedPortions = true;
      this.portionsAmount = this.$refs.portionsRef.lazyValue;
      this.mainPaymentMethod = this.$refs.methodRef.lazyValue;
      this.totalDiscount = this.totalDiscount
        ? parseFloat(this.$refs.discountRef.lazyValue)
        : 0;
      var arr = [];
      if (this.portionsAmount && this.choosenExpireDate) {
        for (let i = 0; i < this.portionsAmount; i++) {
          let date = new Date(this.choosenExpireDate + 'T10:00:00');
          if (this.hasEntry) {
            date.setMonth(date.getMonth() + i - 1);
          } else {
            date.setMonth(date.getMonth() + i);
          }
          let d = {
            expiringDate: date,
            price: this.priceWithDiscount / this.portionsAmount,
            paymentMethod: this.mainPaymentMethod,
            id: i,
            attendenceId: this.attendence.id,
          };
          if (i == 0 && this.hasEntry) {
            d = { ...d, isEntry: true };
          }
          //console.log(d);
          arr.push(d);
        }
      } else {
        this.$notify({
          group: 'error',
          title: 'Informe as parcelas e a data de vencimento',
          type: 'warn',
          // text: 'Hello user! This is a notification!',
        });
      }
      this.portionsArray = arr;
    },
  },
  watch: {
    getAttendences() {
      // console.log(this.getAttendences)
      // console.log(this.attendence.id)
      this.getAttendences.map((a) => {
        if (a.id == this.attendence.id) {
          //console.log(a.item);
          //console.log(a.statusId)
          this.attendence.statusId = a.statusId;
        }
      });
    },
  },
  computed: {
    ...mapGettersConfig(['getUser']),
    ...mapStateAttendences(['attendences']),
    ...mapGettersAttendences([
      'getAttendence',
      'getStatusByName',
      'getAttendenceStatus',
      'getAttendences',
    ]),
    ...mapStateConfig(['users']),
    areAllApproved() {
      return this.attendence.item.every((el) => el.status === 'A');
    },
    priceWithDiscount() {
      if (this.totalDiscount) {
        if (this.totalDiscount >= 0) {
          return this.sumItemsPrices * (1 - this.totalDiscount / 100);
        } else {
          return this.sumItemsPrices * (1 + -this.totalDiscount / 100);
        }
      } else {
        return this.sumItemsPrices;
      }
    },
    budgetArray() {
      return this.getAttendence(this.attendence.id).item;
    },
    remainingPrice() {
      let paidPrice = 0;
      this.portionsArray.forEach((el) => {
        if (el.price >= 0) {
          paidPrice += +el.price;
        }
      });
      return Math.round((this.priceWithDiscount - paidPrice) * 100) / 100;
    },
    getFirstExpiringDate() {
      const date = Object.values(this.portionsArray)[
        this.portionsArray.length - 1
      ].expiringDate;
      // const formatted = new Date(date)
      // this.changechoosenExpireDate()
      return date;
    },
    sumItemsPrices() {
      let sum = 0;
      this.budgetArray.forEach((el) => {
        if (el.status != 'R') {
          sum += this.getAttendenceItemPrice(el);
        }
      });
      return sum;
    },
    totalPrice() {
      let total = 0;
      this.budgetArray.forEach((el) => {
        if (el.status != 'R') {
          total += this.getAttendenceItemPrice(el);
        }
      });
      return total;
    },
    choosenExpireDateFormatted() {
      return this.formatDate(this.choosenExpireDate);
    },
    choosenDeliverDateFormatted() {
      return this.formatDate(this.choosenDeliverDate);
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    numberArray() {
      return Array(8)
        .fill()
        .map((element, index) => index + 1);
    },
  },
  mounted() {
    this.attendenceCopyObject = { ...this.attendence };
    const atte = this.attendences.find((el) => el.id == this.attendence.id);
    if (atte.payment.length > 0) {
      this.portionsArray = Object.assign(atte.payment);
      this.hasGeneratedPortions = true;

      let d = new Date(this.portionsArray[0].expiringDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      this.totalDiscount = this.portionsArray[0].discount;
      this.choosenExpireDate = [year, month, day].join('-');
      // this.choosenExpireDate = this.portionsArray[this.portionsArray.length - 1].expiringDate YYYY/MM/DD
      // alert(this.choosenExpireDate)
    }
    if (this.attendence.deliverDate) {
      let d = new Date(this.attendence.deliverDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      this.choosenDeliverDate = [year, month, day].join('-');
    } else {
      let d = new Date();
      d.setDate(d.getDate() + 60),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      this.choosenDeliverDate = [year, month, day].join('-');
    }
  },
};
</script>

<style>
.dialogCardBudget .portion-field .v-input__slot {
  max-width: 100%;
}

.dialogCardBudget .dolar-green-icon {
  filter: saturate(100%) brightness(0);
  margin-top: 4px;
  padding-left: 6px;
}

.dialogCardBudget .upper-items .v-input__control > .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  height: 30px;
  width: 350px;
}

.dialogCardBudget .upper-items .v-input {
  flex: none;
}

.dialogCardBudget .upper-items .v-input__control {
  flex-grow: 0;
  width: auto;
}

.dialogCardBudget .upper-items td > .v-select {
  width: fit-content;
}

.dialogCardBudget
  .payment-div
  .v-data-table
  .v-input__control
  > .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  height: 30px;
  width: 300px;
}

.dialogCardBudget .payment-div .v-data-table .v-input {
  flex: none;
}

.dialogCardBudget .payment-div .v-data-table .v-input__control {
  flex-grow: 0;
  width: auto;
}

.dialogCardBudget .payment-div .v-data-table td > .v-select {
  width: fit-content;
}

.dialogCardBudget .portions-table {
  border: 1px solid #b7c3da;
  border-radius: 8px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 10px;
}

.upper-items {
  padding-left: 0px;
}

.upper-items:first-child {
  padding-left: 0px;
}

.upper-items:last-child {
  padding-right: 0px;
}

.dialogCardBudget .upper-items-div {
  display: flex;
  width: 100%;
  border: 1px solid #b7c3da;
  height: 100%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

.dialogCardBudget .upper-items:nth-child(3) > .upper-items-div {
  justify-content: space-around;
}

.dialogCardBudget .mdi-checkbox-marked::before {
  font-weight: 900;
  content: url('https://api.iconify.design/mdi-checkbox-marked-circle.svg?color=%23007aff&height=24');
  padding-top: 3px;
}
.dialogCardBudget .mdi-checkbox-blank-outline::before {
  font-weight: 900;
  padding-top: 3px;
  content: url('https://api.iconify.design/mdi-checkbox-blank-circle-outline.svg?color=rgba(0%2C0%2C0%2C0.6)&height=24');
}
.dialogCardBudget .col-center {
  display: flex;
  justify-content: center;
}

.dialogCardBudget .quantity-col > div:first-child {
  align-self: center;
  width: stretch;
  display: flex !important;
  justify-content: center;
}

.dialogCardBudget .number-input-button {
  width: 14px;
  height: 14px;
}

.dialogCardBudget .number-input-button i {
  font-size: 15px !important;
}

.dialogCardBudget .table-div td:first-child {
  padding: 0px;
}

.dialogCardBudget .custom-col {
  display: flex;
  height: 100%;
  align-items: center;
}

.dialogCardBudget .custom-col div:nth-child(2) {
  padding-left: 7px;
  padding-right: 7px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialogCardBudget .col-index {
  border-right: 1px solid #dddddd;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 30px;
  justify-content: center;
}

.dialogCardBudget table th + th {
  border-left: 1px solid #dddddd;
}

.dialogCardBudget table th > span {
  color: black;
}

.dialogCardBudget table td + td {
  border-left: 1px solid #dddddd;
}

/* .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
} */

.dialogCardBudget .v-card__actions {
  padding-bottom: 0px !important;
}

.dialogCardBudget .payment-div {
  border: 1px solid #b7c3da;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
}

.dialogCardBudget .payment-div div {
  color: black;
}

.dialogCardBudget .payment-div .row {
  height: fit-content;
}

.dialogCardBudget .payment-div .col {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dialogCardBudget .row {
  margin: 0px;
  box-sizing: border-box;
}

.dialogCardBudget .table-div .v-data-table {
  border: 1px solid #b7c3da;
  border-radius: 8px;
}

.dialogCardBudget .upper-items {
  min-height: 100%;
  border-radius: 8px;
}

.dialogCardBudget .upper-items:nth-child(2) > div {
  align-items: center;
}
.dialogCardBudget .upper-items:nth-child(2) > div > div {
  height: fit-content;
  padding-right: 6px;
  padding-left: 6px;
}

.dialogCardBudget .upper-items:nth-child(3) {
  justify-content: space-around;
  align-items: center;
}
.dialogCardBudget .upper-items:nth-child(3) .v-btn {
  background: white;
  border: 1px solid #e8e8e8;
}

.dialogCardBudget .dialogTitle {
  font-size: 20px;
  font-weight: 500;
  color: #575962;
}

.dialogCardBudget .v-card__text {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.v-dialog.v-dialog--active {
  border-radius: 22px !important;
}
.dialogCardBudget {
  border-radius: 22px !important;
}

.dialogCardBudget .double-button-card {
  border: 1px solid #e4e8f0;
  justify-content: center;
  width: max-content;
  justify-self: center;
  background: white !important;
  margin-left: 16px;
  border-radius: 8px;
  height: 30px;
}

.dialogCardBudget .double-button-card .verticalLine {
  border-left: 1px solid #b7c3da;
  height: 60%;
  left: 50%;
  align-self: center;
}

.dialogCardBudget .double-button-card .single-button {
  border: none;
  background-color: white;
  height: 100%;
}

.dialogCardBudget .double-button-card .single-button:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0px;
}
.dialogCardBudget .double-button-card .single-button:nth-child(3) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dialogCardBudget .double-button-card .single-button:nth-child(1) > span {
  color: #ffa290;
}

.dialogCardBudget .double-button-card .single-button:nth-child(3) > span {
  color: #5addbe;
}

.dialogCardBudget .double-button-card .single-button > span {
  font-size: 13px;
}
</style>