<template>
  <v-dialog max-width="1000" v-model="show" scrollable>
    <v-card class="dialogCardStatus" :loading="loading">
      <v-card-title
        >{{ attendence.name }}
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <v-btn
            depressed
            color="white"
            class="filterButtons positive my-5"
            @click="approveAll($event)"
            ><v-icon dense>mdi-thumb-up-outline</v-icon
            ><span class="pl-2">Aprovar todos os ambientes</span></v-btn
          >
          <v-btn
            depressed
            color="white"
            class="filterButtons negative ml-5 my-5"
            @click="disapproveAll()"
            ><v-icon dense>mdi-thumb-down-outline</v-icon
            ><span class="pl-2">Reprovar todos os ambientes</span></v-btn
          >
          <v-select
            dense
            v-if="isDisapproveAll"
            outlined
            hide-details
            class="ml-5 my-5 generalReasonSelect"
            :items="reasonsArray"
            :value="generalReason"
            @change="changeGeneralReason"
          ></v-select>
        </div>
        <div class="data-table-div">
          <v-data-table
            :items="itemArray"
            :headers="statusHeader"
            hide-default-footer
            no-data-text="Nenhum resultado encontrado"
            disable-sort
          >
            <template
              v-for="head in statusHeader"
              v-slot:[`header.${head.value}`]="{ header }"
            >
              <div
                class="black--text d-flex"
                :key="head.value"
                style="justify-content: center"
              >
                {{ header.text }}
              </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div class="d-flex" style="justify-content: center">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <div class="d-flex" style="justify-content: center">
                {{
                  new Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(item.price)
                }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item, index }">
              <div class="d-flex" style="justify-content: center">
                <v-btn
                  depressed
                  color="white"
                  class="filterButtons positive"
                  @click="approveStatus(item.id, index)"
                  ><v-icon dense color="#5ADDBE">mdi-thumb-up-outline</v-icon
                  ><span class="pl-2" style="color: #5addbe"
                    >Aprovados</span
                  ></v-btn
                >
                <v-btn
                  depressed
                  color="white"
                  class="filterButtons negative ml-5"
                  @click="disapproveStatus(item.id, index)"
                  ><v-icon dense color="red" class="disapprovedIcon"
                    >mdi-thumb-down-outline</v-icon
                  ><span class="pl-2" style="color: #ff9391"
                    >Reprovados</span
                  ></v-btn
                >
              </div>
            </template>
            <template v-slot:[`item.denyReason`]="{ item }">
              <v-select
                v-if="item.status == 'R'"
                class="denySelect"
                dense
                outlined
                hide-details
                @change="changeItemStatus($event, item)"
                :items="reasonsArray"
                :value="item.denyReason || generalReason"
              ></v-select>
              <div
                style="font-size: 13px; text-align: center"
                v-if="item.status == null || item.status == ''"
              >
                Selecione um status
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="
            !attendenceHasSchedule(attendence.id, getStatusIdByName('Montagem'))
          "
          text
          @click="scheduleDate"
        >
          <calendar-icon class="mr-1" width="16" />
          <div>Agendar montagem</div></v-btn
        >
        <div v-else>
          Montagem:
          {{
            datetimeBr(
              attendenceHasSchedule(
                attendence.id,
                getStatusIdByName('Montagem')
              )
            ).split(' ')[0]
          }}
        </div>
        <v-spacer></v-spacer>
        <v-btn text dark color="#177DFF" @click="$emit('close')"
          >Cancelar</v-btn
        >
        <v-btn
          @click="saveStatus"
          class="rounded-lg"
          color="#177DFF"
          depressed
          dark
        >
          <div class="px-4">Salvar</div>
        </v-btn>
      </v-card-actions>
    </v-card>
    <CreateEditEvent
      v-if="scheduleModal"
      v-model="scheduleModal"
      @close="scheduleModal = false"
      @createEvent="editEventFunction"
      @editEvent="editEventFunction"
      :userTypeToSelect="'Montador'"
      :selectedSchedule="attendence"
    ></CreateEditEvent>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('attendences');

const { mapActions: mapActionsClients } = createNamespacedHelpers('clients');

const {
  mapState: mapStateConfig,
  mapGetters: mapGettersConfig,
  mapActions: mapActionsConfig,
} = createNamespacedHelpers('config');

import CreateEditEvent from './../../calendar/modals/CreateEditEvent';

export default {
  components: { CreateEditEvent },
  props: { value: Boolean, attendence: Object },
  data() {
    return {
      loading: false,
      generalReason: null,
      reasonsArray: [
        'Falta de informações',
        'Desistência',
        'Atendimento ruim',
        'Preço alto',
        'Prazo de entrega',
      ],
      statusHeader: [
        { text: 'Item', value: 'name', width: '20%' },
        { text: 'Valor', value: 'price', width: '20%' },
        { text: 'Status', value: 'status', width: '40%' },
        { text: 'Motivo', value: 'denyReason', width: '20%' },
      ],
      itemArray: [],
      isDisapproveAll: false,
      scheduleModal: null,
    };
  },
  methods: {
    ...mapActionsClients(['createClient', 'createClientAddress']),
    ...mapActions([
      'createAttendence',
      'editAttendence',
      'editAttendenceStatus',
      'createAttendenceItem',
      'editAttendenceItem',
      'createAttendencePayment',
      'createAttendenceHistoric',
      'deleteAttendencePayments',
      'addAttendenceResponsible',
      'editAttendenceSchedule',
      'createAttendenceSchedule',
    ]),
    async editEventFunction(event) {
      const scheduleArray = this.getAttendence(event.id).schedules;
      const existingSchedule = scheduleArray.find(
        (el) => el.statusId == event.statusId
      );
      let schedule = {};
      if (existingSchedule) {
        schedule = await this.editAttendenceSchedule({
          schedule: {
            id: existingSchedule.id,
            attendenceId: event.id,
            statusId: event.statusId,
            startTime: event.startTime,
            endTime: event.endTime,
            description: event.description,
            userId: event.user.id,
          },
        });
      } else {
        schedule = await this.createAttendenceSchedule({
          schedule: {
            attendenceId: event.id,
            statusId: event.statusId,
            startTime: event.startTime,
            endTime: event.endTime,
            description: event.description,
            userId: event.user.id,
          },
        });
      }
      const choosenAttendence = this.getAttendence(event.id);
      choosenAttendence.oldDescription = this.statuses.find(
        (el) =>
          el.id ==
          this.attendences.find((el) => el.id == choosenAttendence.id).statusId
      ).name;
      const newDescription = this.statuses.find(
        (el) => el.id == choosenAttendence.statusId
      ).name;
      const contactStatus = this.getStatusByName('Contato');
      if (
        choosenAttendence.statusId == contactStatus.id &&
        schedule.statusId == this.getStatusIdByName('Medição')
      ) {
        choosenAttendence.statusId = this.getStatusByPlace(
          contactStatus.place + 1
        ).id;
      } else if (
        choosenAttendence.statusId == this.getStatusIdByName('Aprovado') &&
        schedule.statusId == this.getStatusIdByName('Conferência de medidas')
      ) {
        choosenAttendence.statusId = this.getStatusByPlace(
          this.getStatusByName('Aprovado').place + 1
        ).id;
      }
      await this.editAttendence({ attendence: choosenAttendence });
      await this.createAttendenceHistoric({
        historic: {
          statusId: choosenAttendence.statusId,
          attendenceId: choosenAttendence.id,
          description: `Atendimento foi de ${choosenAttendence.oldDescription} para ${newDescription}`,
          isActive: true,
          userId: window.localStorage.getItem('userId'),
        },
        loggedUser: this.getUser(window.localStorage.getItem('userId')),
      });
      if (
        !choosenAttendence.responsibles.find((el) => el.id == event.user.id)
      ) {
        await this.addAttendenceResponsible({
          responsible: { attendenceId: event.id, userId: event.user.id },
        });
      }
      this.scheduleModal = false;
    },
    scheduleDate() {
      this.scheduleModal = true;
    },
    changeGeneralReason(event) {
      this.generalReason = event;
      this.itemArray.forEach((element) => {
        element.denyReason = event;
      });
    },
    changeItemStatus(event, item) {
      item.denyReason = event;
    },
    saveStatus() {
      //console.log('StatusAttendence ' ,this.itemArray)
      this.loading = true;
      this.$emit('save', this.itemArray);
    },
    approveAll(event) {
      this.isDisapproveAll = false;
      const tbody = document.querySelector('.data-table-div tbody');
      for (let i = 0; i < this.itemArray.length; i++) {
        const btnPositive = tbody.children[i].querySelector(
          '.filterButtons.positive'
        );
        const btnNegative = tbody.children[i].querySelector(
          '.filterButtons.negative'
        );
        btnPositive.classList.remove('disabled');
        btnNegative.classList.add('disabled');
        let obj = this.itemArray[i];
        obj.denyReason = '';
        obj.status = 'A';
        this.itemArray.splice(i, 1, obj);
      }
    },
    disapproveAll(event) {
      this.isDisapproveAll = true;
      const tbody = document.querySelector('.data-table-div tbody');
      for (let i = 0; i < this.itemArray.length; i++) {
        //console.log(tbody.children[i]);
        const btnPositive = tbody.children[i].querySelector(
          '.filterButtons.positive'
        );
        const btnNegative = tbody.children[i].querySelector(
          '.filterButtons.negative'
        );
        btnNegative.classList.remove('disabled');
        btnPositive.classList.add('disabled');
        let obj = this.itemArray[i];
        obj.status = 'R';
        obj.denyReason = '';
        this.itemArray.splice(i, 1, obj);
      }
    },
    disapproveStatus(i, index) {
      let obj = this.itemArray.find((el) => el.id == i);
      obj.denyReason = '';

      const tbody = document.querySelector('.data-table-div tbody');
      const btnPositive = tbody.children[index].querySelector(
        '.filterButtons.positive'
      );
      if (btnPositive.classList.contains('disabled')) {
        btnPositive.classList.remove('disabled');
        obj.status = null;
      } else {
        const btnNegative = tbody.children[index].querySelector(
          '.filterButtons.negative'
        );
        btnNegative.classList.remove('disabled');
        btnPositive.classList.add('disabled');
        obj.status = 'R';
      }
      this.itemArray.splice(index, 1, obj);
    },
    approveStatus(i, index) {
      let obj = this.itemArray.find((el) => el.id == i);
      obj.denyReason = '';
      const tbody = document.querySelector('.data-table-div tbody');
      const btnNegative = tbody.children[index].querySelector(
        '.filterButtons.negative'
      );

      if (btnNegative.classList.contains('disabled')) {
        btnNegative.classList.remove('disabled');
        obj.status = null;
      } else {
        obj.status = 'A';
        btnNegative.classList.add('disabled');
        const btnPositive = tbody.children[index].querySelector(
          '.filterButtons.positive'
        );

        btnPositive.classList.remove('disabled');
      }
      this.itemArray.splice(index, 1, obj);
    },
  },
  computed: {
    ...mapState(['attendences', 'statuses']),
    ...mapStateConfig(['userRoles', 'users', 'loggedUser']),
    ...mapGettersConfig(['getUser']),
    ...mapGetters([
      'getAttendence',
      'getAttendenceItem',
      'getAttendenceStatus',
      'getStatusIdByName',
      'getStatusByName',
      'getStatusByPlace',
      'attendenceHasSchedule',
    ]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.itemArray = Object.values(this.attendence.item);
  },
  mounted() {
    //console.log(this.itemArray);
    for (let i = 0; i < this.itemArray.length; i++) {
      if (this.itemArray[i].status == 'A') {
        const tbody = document.querySelector('.data-table-div tbody');
        const btnNegative = tbody.children[i].querySelector(
          '.filterButtons.negative'
        );
        btnNegative.classList.add('disabled');
      } else if (this.itemArray[i].status == 'R') {
        const tbody = document.querySelector('.data-table-div tbody');
        const btnPositive = tbody.children[i].querySelector(
          '.filterButtons.positive'
        );
        btnPositive.classList.add('disabled');
      }
    }
  },
};
</script>

<style>
.dialogCardStatus .generalReasonSelect,
.dialogCardStatus .denySelect {
  display: flex !important;
  flex: 1 !important;
}

.dialogCardStatus .generalReasonSelect > div,
.dialogCardStatus .denySelect > div {
  width: 100%;
}

.dialogCardStatus .generalReasonSelect > div > .v-input__slot,
.dialogCardStatus .denySelect > div > .v-input__slot {
  width: 100%;
}

.dialogCardStatus .data-table-div tr {
  height: 60px;
}

.dialogCardStatus .data-table-div td {
  color: black;
}

.dialogCardStatus .v-input {
  flex: none;
}

.dialogCardStatus .v-input__control {
  flex-grow: 0;
  width: auto;
}

.dialogCardStatus .v-select {
  width: fit-content;
}

.dialogCardStatus .v-data-table .v-input__control > .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  height: 35px;
}

.dialogCardStatus .v-data-table .v-input {
  flex: none;
}

.dialogCardStatus .v-data-table .v-input__control {
  flex-grow: 0;
  width: auto;
}

.dialogCardStatus .v-data-table td > .v-select {
  width: fit-content;
}

.dialogCardStatus .data-table-div table th + th {
  border-left: 1px solid #dddddd;
}

.dialogCardStatus .data-table-div .v-data-table {
  border: 1px solid #e9ecef;
  border-radius: 8px;
}

.dialogCardStatus .data-table-div table th > span {
  color: black;
}

.dialogCardStatus .data-table-div table td + td {
  border-left: 1px solid #dddddd;
}

.dialogCardStatus .filterButtons.disabled > span > i,
.dialogCardStatus .filterButtons.disabled > span > span {
  color: #cbcbcb !important;
  transition: none !important;
}

.dialogCardStatus .filterButtons {
  border: 1px solid #e8e8e8 !important;
  border-radius: 6px;
  height: 40px !important;
}

.dialogCardStatus .v-input__control > .v-input__slot {
  height: 36px;
  width: 100px;
}
</style>
