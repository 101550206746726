<template>
  <v-dialog max-width="950" v-model="show" @click:outside="closeDialog" scrollable>
    <v-card class="dialogCardInfo" :loading="loading">
      <v-card-title
        >{{ attendence.client.name }}
        <v-spacer></v-spacer>
        <v-btn fab small icon @click="$emit('close')">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="custom-scroll">
        <v-tabs align-with-title v-model="tab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="item in tabItems" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItems" :key="item">
            <div v-if="item == 'Cliente'" class="pt-5">
              <ValidationObserver ref="observer">
                <v-row>
                  <v-col cols="7">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >Nome</v-card-subtitle
                    >
                    <ValidationProvider rules="isNotBlank" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        v-model="attendence.client.name"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="5">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >CPF</v-card-subtitle
                    >
                    <ValidationProvider rules="isCPFValid" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        v-model="attendence.client.CPF"
                        v-mask="'###.###.###-##'"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >E-mail</v-card-subtitle
                    >
                    <ValidationProvider rules="isEmailValid" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        v-model="attendence.client.email"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="5">
                    <div class="d-flex">
                      <v-card-subtitle class="pl-0 pb-2 pt-0"
                        >Contato</v-card-subtitle
                      >
                      <v-spacer></v-spacer>
                      <v-checkbox
                        label="Whatsapp"
                        hide-details="auto"
                        v-model="attendence.client.hasWhats"
                        style="lineheight: normal; margin-top: 0px; padding: 0"
                      ></v-checkbox>
                    </div>
                    <ValidationProvider
                      rules="isNotBlank|isContactValid"
                      v-slot="v"
                    >
                      <v-text-field
                        outlined
                        dense
                        hide-details="auto"
                        no-data-text="Nenhuma informação disponível"
                        lazy-rules
                        v-model="clientContact"
                        v-mask="phoneMask"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7">
                    <v-row>
                      <v-col cols="4">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >CEP</v-card-subtitle
                        >
                        <ValidationProvider rules="isCEPValid" v-slot="v">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            lazy-rules
                            v-model="attendence.client.clientAddress.CEP"
                            v-mask="'##.###-###'"
                            @blur="autocompleteAddress(v.errors)"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Rua</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            lazy-rules
                            outlined
                            hide-details="auto"
                            v-model="attendence.client.clientAddress.street"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5">
                    <v-row>
                      <v-col cols="4">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >N°</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            lazy-rules
                            outlined
                            hide-details="auto"
                            v-model="attendence.client.clientAddress.number"
                            id="numberField"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Complemento</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            lazy-rules
                            dense
                            outlined
                            hide-details="auto"
                            v-model="attendence.client.clientAddress.complement"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7">
                    <v-row>
                      <v-col cols="4">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Bairro</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            lazy-rules
                            v-model="
                              attendence.client.clientAddress.neighbourhood
                            "
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Cidade</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            lazy-rules
                            v-model="attendence.client.clientAddress.city"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >Estado</v-card-subtitle
                    >
                    <ValidationProvider rules="" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        v-model="attendence.client.clientAddress.state"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </div>
            <div v-if="item == 'Comentários'" class="pt-5">
              <v-textarea
                auto-grow
                outlined
                dense
                hide-details
                placeholder="Digite o seu comentário sobre o atendimento aqui..."
                height="70"
                style="border-radius: 8px"
                v-model="writtenComment"
              ></v-textarea>
              <div class="d-flex">
                <v-spacer></v-spacer
                ><v-btn
                  class="rounded-lg mt-3"
                  color="#177DFF"
                  depressed
                  dark
                  @click="saveComment"
                >
                  <div class="px-4">Enviar Comentário</div>
                </v-btn>
              </div>
              <div
                class="black--text"
                style="font-size: 17px; padding-top: 12px; padding-bottom: 8px"
              >
                Comentários
              </div>
              <div class="comment-box">
                <div
                  class="comment"
                  v-for="(comment, i) in commentsArray"
                  :key="i"
                >
                  <div class="pa-3">
                    <div class="comment-title d-flex">
                      <div class="pr-1" style="font-weight: 600">
                        {{ comment.user.name }}
                      </div>
                      <div style="width: 15%">
                        ({{ datetimeBr(comment.createdAt) }}) disse:
                      </div>
                    </div>
                    <div class="comment-text">{{ comment.content }}</div>
                  </div>
                </div>
              </div>
            </div>
            
            <div v-if="item == 'Histórico'" class="pt-5 data-table-div">
              <v-data-table
                hide-default-footer
                no-data-text="Nenhum resultado encontrado"
                disable-pagination
                :headers="historicHeader"
                :items="historicArray"
                disable-sort
              >
                <template
                  v-for="head in historicHeader"
                  v-slot:[`header.${head.value}`]="{ header }"
                >
                  <div
                    class="black--text d-flex"
                    :key="head.value"
                    style="justify-content: center;"
                  >
                    {{ header.text }}
                  </div>
                </template> 
                <!-- <template v-slot:[`item.client`]="{ item }">
                  <div
                    class="black--text d-flex"
                    style="justify-content: center"
                  >
                    {{ item.client }}
                  </div>
                </template> -->
                <template v-slot:[`item.createdAt`]="{ item }" >
                  <div
                    class="black--text d-flex"
                    style="justify-content: center;"
                  >
                    {{ datetimeBr(item.createdAt) }}
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <div
                    class="black--text d-flex"
                    style="justify-content: left;
                          white-space: break-spaces;
                          padding: 12px;"
                  >
                    {{ item.description }}
                  </div>
                </template>
                <template v-slot:[`item.user.name`]="{ item }">
                  <div
                    class="black--text d-flex"
                    style="justify-content: center"
                  >
                    {{ item.user.name }}
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          v-if="userHasPermission('delete_attendence')"
          @click="modalDelete = true"
          ><v-icon>la-trash-alt</v-icon>Excluir atendimento</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn dark color="#177DFF" @click="updateClient">Salvar</v-btn>
        <v-btn text dark color="#177DFF" @click="$emit('close')">Sair</v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      v-if="modalDelete"
      v-model="modalDelete"
      message="Você quer mesmo deletar este atendimento?"
      @close="modalDelete = false"
      @confirmed="excludeAttendence"
    />
  </v-dialog>
</template>

<script>
import CLIENT_MASKS_MIXIN from '@/utils/mixins/masks.js';

import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('attendences');
const { mapGetters: mapGettersConfig } = createNamespacedHelpers('config');
const {
  mapActions: mapActionsClients,
} = createNamespacedHelpers('clients');

import ConfirmationModal from '../../../components/utils/ConfirmationModal.vue';

export default {
  components: {
    ConfirmationModal,
  },
  mixins: [CLIENT_MASKS_MIXIN],
  props: { value: Boolean, choosenAttendence: Object },
  data() {
    return {
      clientContact: '',
      loading: false,
      modalDelete: null,
      attendence: {
        startTime: '',
        endTime: '',
        client: {
          CPF: '',
          clientAddress: {
            CEP: '',
            city: '',
            clientId: null,
            complement: '',
            createdAt: '',
            id: null,
            neighbourhood: '',
            number: null,
            state: '',
            street: '',
            updatedAt: '',
          },
          contact: '',
          createdAt: '',
          email: '',
          hasWhats: '',
          id: '',
          name: '',
          updatedAt: '',
        },
        clientId: '',
        createdAt: '',
        id: '',
        registerDate: '',
        user: '',
        userId: '',
        statusId: '',
        updatedAt: '',
      },
      tab: null,
      tabItems: ['Cliente', 'Comentários', 'Histórico'],
      commentsArray: [],
      historicHeader: [
        { text: 'Ação', value: 'description', width: '40%' },
        { text: 'Usuário', value: 'user.name', width: '30%' },
        { text: 'Data e horário', value: 'createdAt', width: '30%' },
      ],
      historicArray: [],
      writtenComment: '',
    };
  },
  methods: {
    ...mapActions([
      'createAttendenceCommentary',
      'deleteAttendence',
    ]),
    ...mapActionsClients(['editClientAddress', 'editClient']),
    async updateClient() {
      if (this.userHasPermission('change_client')) {
        const isValid = await this.$refs.observer[0].validate();
        if (this.$refs.observer[0]._data.flags.valid) {
          this.loading = true
          const newAddress = await this.editClientAddress({
            clientAddress: this.attendence.client.clientAddress,
          });
          this.attendence.client.clientAddress = newAddress;
          const newClient = await this.editClient({
            client: this.attendence.client,
          });
          this.$emit('close');
        }
      }
    },
    async autocompleteAddress(errors) {
      if (errors.length == 0) {
        const address = await this.findAddressByZipCode(
          this.attendence.client.clientAddress.CEP
        );
        this.attendence.client.clientAddress.city = address.city;
        this.attendence.client.clientAddress.neighbourhood =
          address.neighborhood;
        this.attendence.client.clientAddress.state = address.state;
        this.attendence.client.clientAddress.street = address.street;
        document.querySelector('#numberField').focus();
      }
    },
    async saveComment() {
      if (this.writtenComment && this.writtenComment != "") {
        
        const userId = window.localStorage.getItem('userId');
        let comm = {
          content: this.writtenComment,
          attendenceId: this.attendence.id,
          userId,
        };
        const loggedUser = this.getUser(userId);
        this.createAttendenceCommentary({ commentary: comm, loggedUser });
        this.writtenComment = '';
      }
    },
    formatDate(date) {
      date.setMonth(date.getMonth() + 1);
      return (
        ('0' + date.getDate()).slice(-2) +
        '/' +
        ('0' + date.getMonth()).slice(-2) +
        '/' +
        date.getFullYear()
      );
    },
    closeDialog() {
      this.$emit('close');
    },
    excludeAttendence() {
      this.deleteAttendence({ attendenceId: this.choosenAttendence.id });
      this.closeDialog();
      this.$notify({
            group: 'error',
            title: 'Atendimento deletado.',
            type: 'info',
            // text: 'Hello user! This is a notification!',
          });
    },
  },
  computed: {
    ...mapGettersConfig(['getUser']),
    ...mapState(['attendences']),
    tabItemsWithPermissions() {
      return this.userHasPermission('view_client')
        ? ['Cliente', 'Comentários', 'Histórico']
        : ['Comentários', 'Histórico'];
    },
    phoneMask() {
      return this.getPhoneMask(this.attendence.client.contact);
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    this.attendence = Object.assign({}, this.choosenAttendence);
    this.historicArray = Object.values(this.attendence.historics);
    this.historicArray = this.historicArray.reverse();
    this.commentsArray = this.attendence.comments;
    this.clientContact = this.choosenAttendence.client.contact;
  },
};
</script>

<style>

.dialogCardInfo .mdi-checkbox-marked::before {
  font-weight: 900;
  content: url('https://api.iconify.design/mdi-checkbox-marked-circle.svg?color=%23007aff&height=24');
  padding-top: 3px;
}
.dialogCardInfo .mdi-checkbox-blank-outline::before {
  font-weight: 900;
  padding-top: 3px;
  content: url('https://api.iconify.design/mdi-checkbox-blank-circle-outline.svg?color=rgba(0%2C0%2C0%2C0.6)&height=24');
}
.dialogCardInfo .v-card__text > .v-tabs {
  margin-left: -42px;
}

.dialogCardInfo .comment-box {
  display: flex !important;
  justify-content: flex-start !important;
  border: 1px solid #b7c3da;
  display: -webkit-box;
  display: -ms-flexbox;
  flex-flow: column;
  align-items: center;
  border-radius: 8px;
  height: 100px;
  min-height: 350px;
  flex: 1;
  overflow: auto;
}

.dialogCardInfo .comment {
  width: 93%;
  border: 1px solid #b7c3da;
  margin-bottom: 10px;
  border-radius: 8px;
}

.dialogCardInfo .comment:first-child {
  margin-top: 10px;
}

.dialogCardInfo .comment-title {
  color: #177dff;
  font-size: 14px;
  font-weight: 500;
}
.dialogCardInfo .comment-text {
  color: #575962;
}
.dialogCardInfo .data-table-div table th + th {
  border-left: 1px solid #dddddd;
}

.dialogCardInfo .data-table-div .v-data-table {
  border: 1px solid #e9ecef;
  border-radius: 8px;
}

.dialogCardInfo .data-table-div table th > span {
  color: black;
}

.dialogCardInfo .data-table-div table td + td {
  border-left: 1px solid #dddddd;
}
</style>